const facetAttributes = [
  'f.product.attribute.Tipo',
  'f.product.brandName',
  'f.variant.custom.Tamaño_de_la_pantalla',
  'f.product.attribute.Capacidad_de_carga',
  'f.product.attribute.Tamaño_del_respaldo',
  'f.product.attribute.Material_del_tapiz',
  'f.product.attribute.Material_de_la_estructura',
  'f.product.attribute.Resolución',
  'f.product.attribute.Procesador',
  'f.product.attribute.Número_de_puestos',
  'f.product.attribute.Aro',
  'f.product.attribute.Número_de_hilos',
  'f.product.attribute.Número_de_puertas',
  'f.product.attribute.Número_de_quemadores',
  'f.product.attribute.Memoria_RAM',
  'f.product.attribute.Tipo_de_piel',
];

const checkPLPSeoFilters = (appliedFilters) => {
  const filters =
    appliedFilters &&
    appliedFilters.reduce((filtersFacets, filterFacet) => {
      const countFilter = filterFacet.facetType in filtersFacets ? filtersFacets[filterFacet.facetType].count + 1 : 1;
      const filter = facetAttributes.includes(filterFacet.facetType)
        ? {
            [filterFacet.facetType]: {
              count: countFilter,
              value: filterFacet.title,
            },
          }
        : {};
      return { ...filtersFacets, ...filter };
    }, {});
  let labelComplement = '';
  const filtersKeys = Object.keys(filters);
  const manyFilters = filtersKeys.find((filter) => filters[filter].count > 1);
  if (manyFilters) {
    return '';
  }
  filtersKeys.forEach((filter) => {
    if (filters[filter].count === 1) {
      labelComplement += ` - ${filters[filter].value}`;
    }
  });

  return labelComplement;
};

export default checkPLPSeoFilters;
