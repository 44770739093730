import css from 'styled-jsx/css';
import { svgToDataUri } from '../../../../../utils/styles/svgMixins';
import { icons, colors } from '../../../../../config/styles';

const ShippingBoxStyles = css`
  .shipping-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 48px;
  }

  .heading-section {
    display: flex;
    align-items: center;
  }

  .icon {
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    &.home_delivery {
      width: 35px;
      height: 35px;
      background-image: url('${svgToDataUri(icons.hdIcon)}');
    }
    &.withdraw_at_store {
      width: 40px;
      height: 40px;
      background-image: url('${svgToDataUri(icons.ccIcon)}');
    }
  }

  .divider-line {
    border: 1px solid ${colors.gray.shade14};
    margin-top: 4px;
    &.mobile {
      margin-bottom: 18px;
      margin-top: 14px;
    }
  }

  .heading {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    color: #333;
    flex: 1;
  }

  .heading > p {
    font-weight: 700;
  }

  .switch-container {
    width: 44px;
    position: relative;
    margin-top: 2px;
  }

  .label-section {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #4a4a4a;
    word-wrap: break-word;
  }

  .bold {
    font-weight: bold;
  }
  /* stylelint-disable length-zero-no-unit */
  .shipping-buttons {
    align-items: flex-end;
    display: flex;
    gap: 10px;
    height: 32px;
    justify-content: center;
    left: 16px;
    width: fit-content;
  }
`;

export { ShippingBoxStyles };
