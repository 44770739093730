import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';

const facetsDesktopStyles = css`
  .facetsDesktop {
    padding: 0 10px 26px 30px;
    background-color: ${colors.white.shade1};
    &.shipping {
      padding-bottom: 23px;
      margin-top: 8px;
    }
    &.availability {
      border: 1px solid ${colors.green.shade1};
      border-radius: 5px;
      margin: 10px 18px 29px;
      padding: 0;
      overflow: hidden;
      .facetsDesktop-body {
        padding: 0 0 0 12px;
        margin-right: -3px;
      }
    }
  }
`;

export { facetsDesktopStyles };
