import React from 'react';
import PropTypes from 'prop-types';
import ShippingDetailsStyles from './ShippingDetails.style';
import BodyCopy from '../ui/BodyCopy/BodyCopy';
import constants from '../../config/constants';
import { withApplicationContext } from '../../utils/ApplicationContext';

const { LAYOUT_1_GRID, LAYOUT_LIST } = constants;

const ShippingDetails = ({ availability, labels, layout }) => {
  const { primeShipping, internationalShipping, pickUpFromStoreShipping, homeDeliveryShipping } = availability;
  const getRebrandingSuccessIcon = () => {
    if (layout === LAYOUT_LIST) {
      return 'rebranding-sucess-icon-list';
    }
    if (layout === LAYOUT_1_GRID) {
      return 'rebranding-sucess-icon-grid';
    }
    return 'rebranding-sucess-icon';
  };
  return (
    <ul className="shipping-details">
      {primeShipping && (
        <li>
          <i className={`icon boomIcon icon-${layout}`}>
            <span className="sr-only">{labels.POD_BOOM_SHIPPING_LABEL}</span>
          </i>
        </li>
      )}
      {internationalShipping && (
        <li className="international-shipping-wrapper">
          <span className="aeroplane-icon" />
          <BodyCopy size="copy4">{(cl) => <span className={`${cl}`}>{internationalShipping}</span>}</BodyCopy>
        </li>
      )}
      {pickUpFromStoreShipping && (
        <li>
          <i className={`icon ${getRebrandingSuccessIcon()}`} />
          <BodyCopy size="copy4">
            {(cl) => <span className={`${cl} shipping-text `}>{pickUpFromStoreShipping}</span>}
          </BodyCopy>
        </li>
      )}
      {homeDeliveryShipping && (
        <li>
          <i className={`icon ${getRebrandingSuccessIcon()}`} />
          <BodyCopy size="copy4">
            {(cl) => <span className={`${cl} shipping-text `}>{homeDeliveryShipping}</span>}
          </BodyCopy>
        </li>
      )}
      <style jsx>{ShippingDetailsStyles}</style>
    </ul>
  );
};

ShippingDetails.defaultProps = {
  layout: '4_GRID',
};

ShippingDetails.propTypes = {
  availability: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  layout: PropTypes.string,
};

export { ShippingDetails };
export default withApplicationContext(ShippingDetails);
