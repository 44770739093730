import css from 'styled-jsx/css';
import colors from '../../config/styles/colors';
import typography from '../../config/styles/typography';

const FacetsLinkStyle = css`
  .facets-link {
    display: inline-box;
    color: ${colors.black.shade1};
    font-family: inherit;
    font-size: ${typography.sm};
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    letter-spacing: 0.05rem;
    &:hover {
      text-decoration: underline;
    }
    span {
      margin-left: 5px;
    }
  }
  .icon {
    font-size: ${typography.xxs};
    margin-left: 5px;
    line-height: 35px;
  }
`;

export { FacetsLinkStyle };
