import css from 'styled-jsx/css';
import { colors, icons } from '../../config/styles';
import { svgToDataUri } from '../../utils/styles/svgMixins';

const warningStyle = css`
  .forPDP {
    margin-top: 10px;
  }
  .warning-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 13px;
    color: ${colors.black.shade1};
    @mixin tabletToDesktop {
      margin-bottom: 0;
    }

    .warning-label {
      font-weight: 400;
      font-size: 1.2rem;
      color: #333;
      margin-top: 2px;
      line-height: 1.1;
    }

    .csicon-warning-alert {
      color: ${colors.orange.shade1};
      height: inherit;
    }

    .warning-icon-alert {
      margin-right: 10px;
      align-self: flex-start;
    }

    span {
      height: inherit;
    }
  }
  .secondary {
    background-color: ${colors.yellow.shade4};
    max-width: 286px;
    padding: 4.75px 9.25px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    border-radius: 4px;
    .warning-icon-alert {
      margin: 0;
      align-self: flex-start;
    }
    @mixin tabletToDesktop {
      align-items: flex-start;
    }
    .secondary-warning-icon {
      width: 18.5px;
      height: 18.5px;
      background-image: url('${svgToDataUri(icons.floorCalcWarningIcon)}');
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 9.25px;
    }
  }
`;

export default warningStyle;
