import css from 'styled-jsx/css';
import { typography, colors, icons } from '../../../config/styles';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

const InputBoxStyles = css`
  .inputBox {
    width: 100%;
    font-family: inherit;
    line-height: inherit;
    font-size: ${typography.lg1};
    background: transparent;
    border: 1px solid ${colors.gray.shade2};
    background-color: ${colors.white.shade1};
    border-radius: 3px;
    box-sizing: border-box;
    padding: 0 10px;
    height: 56px;
    @mixin tabletToDesktop {
      height: 40px;
    }
    @mixin mobileToTablet {
      height: 35px;
    }
    &:focus {
      outline: none;
    }
  }
  .cancelButton {
    content: url(${svgToDataUri(icons.cross)});
    color: #333;
    position: absolute;
    right: 20px;
    bottom: 8px;
  }
`;

export { InputBoxStyles };
