import React from 'react';
import PropTypes from 'prop-types';
import styles from './FacetWithMiniPods.style';
import noop from '../../../utils/noop';
import { truncateText, ucFirst } from '../../../utils/textHelper';
import constants from '../../../config/constants';

const FacetWithMiniPods = ({ item, onFacetChange }) => {
  const renderMiniPods = () => {
    return item.values.map((value) => {
      const { title, url } = value;
      return (
        <div className="pods">
          <button
            type="button"
            className="facet-with-mini-pods"
            id={`testId-facetWithMiniPods-${title}`}
            onClick={() => {
              onFacetChange(url, value);
            }}
          >
            {ucFirst(truncateText(title, constants.FACETS.VALUE_TEXT_CHARCOUNT, true))}
          </button>
          <style jsx>{styles}</style>
        </div>
      );
    });
  };
  return (
    <div className="pods-container">
      {renderMiniPods()} <style jsx>{styles}</style>
    </div>
  );
};

FacetWithMiniPods.defaultProps = {
  onFacetChange: noop,
};

FacetWithMiniPods.propTypes = {
  item: PropTypes.object.isRequired,
  onFacetChange: PropTypes.func,
};

export default FacetWithMiniPods;
