import css from 'styled-jsx/css';
import { colors, typography } from '../../config/styles';

const SelectedFiltersStyles = css`
  .selected-filters {
    padding: 0 19px 44px 25px;
  }
  .filter-container {
    overflow-y: auto;
    max-height: 100px;
    padding: 16px 0 16px 0;
  }
  .filter-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .chips {
    padding: 15px 0 0;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.black.shade1};
    font-size: ${typography.sm};
    cursor: pointer;
    outline: none;
  }
  i {
    font-size: 14px;
  }
  .divider-line {
    border: 1px solid #f0f0f0;
  }
  /* stylelint-disable length-zero-no-unit */
  .divider-line-container {
    padding: 0px 19px 0 25px;
  }
`;

export default SelectedFiltersStyles;
