import css from 'styled-jsx/css';
import { colors, typography } from '../../config/styles';

const promotedFiltersStyle = css`
  .PromotedFilter-container {
    min-height: 90px;
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
    border-radius: var(--border-radius);

    @mixin tabletToDesktop {
      min-height: 127px;
      margin-left: 10px;
    }
    &-header {
      margin-top: 13px;
      display: flex;
      justify-content: start;
      align-items: center;
      font-size: ${typography.base};
      padding-left: 17px;
      @mixin tabletToDesktop {
        padding-left: 17px;
        margin-top: 30px;
        justify-content: center;
        font-size: ${typography.xl};
      }
      b {
        font-weight: bold;
      }
    }
    &-mkp-header {
      margin-top: 13px;
      display: flex;
      justify-content: start;
      align-items: center;
      font-size: ${typography.base};
      padding-left: 17px;
      color: ${colors.black.shade1};
      @mixin mobileToTablet {
        font-weight: 700;
      }
      @mixin tabletToDesktop {
        padding-left: 17px;
        margin-top: 30px;
        justify-content: center;
        font-size: ${typography.xl};
      }
      b {
        font-weight: 700;
        @mixin mobileToTablet {
          font-weight: 800;
        }
      }
    }
    &-filters {
      margin-top: 14px;
      width: 99%;
      transition: all 0.5s;
      display: flex;
      padding: 0 12px;
      &-filter {
        height: 32px;
        width: auto;
        margin: 0 5px;
        border-radius: 6px;
        border: 1px solid #f0f0f0;
        display: inline-flex;
        padding: 0 25px;
        flex-shrink: 0;
        text-transform: uppercase;
        justify-content: center;
        align-items: center;
        color: ${colors.black.shade8};
        font-size: ${typography.sm};
        font-weight: 500;
        text-align: center;
        cursor: pointer;
        outline: none;
        @mixin tabletToDesktop {
          justify-content: center;
          font-size: ${typography.base};
          height: 40px;
        }
      }
      &-mkp-filter {
        height: 40px;
        width: auto;
        margin: 0 5px;
        border-radius: 5px;
        border: 2px solid ${colors.gray.shade2};
        display: inline-flex;
        padding: 0 25px;
        flex-shrink: 0;
        text-transform: uppercase;
        justify-content: center;
        align-items: center;
        color: ${colors.black.shade1};
        font-size: ${typography.base};
        font-weight: 400;
        text-align: center;
        cursor: pointer;
        outline: none;
        line-height: 17px;
        @mixin mobileToTablet {
          border-radius: 4.275px;
          border: 2px solid #e0e0e0;
          font-size: ${typography.xs2};
          font-weight: 400;
          height: 32px;
        }
        @mixin tabletToDesktop {
          justify-content: center;
          font-size: ${typography.base};
          height: 40px;
        }
      }

      &-prev {
        height: 50px;
        width: 30px;
        align-items: center;
        display: flex;
        justify-content: center;
        position: absolute;
        left: 0;
        bottom: 8px;
        box-shadow: 3px 0 3px ${colors.gray.shade1};
        background-color: white;
        cursor: pointer;
        @mixin tabletToDesktop {
          bottom: 18px;
          height: 58px;
        }
      }
      &-mkp-prev {
        height: 58px;
        width: 31px;
        align-items: center;
        display: flex;
        justify-content: center;
        position: absolute;
        left: 0;
        bottom: 8px;
        background-color: white;
        cursor: pointer;
        color: ${colors.black.shade1};
        box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.3);
        border-radius: 0 3.6px 3.6px 0;
        .csicon-arrow_left {
          color: ${colors.black.shade1};
        }
        @mixin tabletToDesktop {
          bottom: 18px;
          height: 58px;
        }
        @mixin mobileToTablet {
          height: 50px;
        }
      }
      &-next {
        height: 50px;
        width: 30px;
        align-items: center;
        display: flex;
        justify-content: center;
        position: absolute;
        right: 0;
        bottom: 8px;
        box-shadow: -3px 0 3px ${colors.gray.shade1};
        background-color: white;
        cursor: pointer;
        @mixin tabletToDesktop {
          height: 58px;
          bottom: 18px;
        }
      }
      &-mkp-next {
        height: 58px;
        width: 31px;
        align-items: center;
        display: flex;
        justify-content: center;
        position: absolute;
        right: 0;
        bottom: 8px;
        background-color: white;
        cursor: pointer;
        color: ${colors.black.shade1};
        box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.3);
        border-radius: 0 3.6px 3.6px 0;
        transform: rotate(180deg);
        .csicon-arrow_right {
          color: ${colors.black.shade1};
          transform: rotate(-180deg);
        }
        @mixin tabletToDesktop {
          bottom: 18px;
          height: 58px;
        }
        @mixin mobileToTablet {
          height: 50px;
        }
      }
    }
  }
  .selected {
    border: 1px solid ${colors.green.shade1};
    background-color: ${colors.green.shade7};
  }
  .mkp-selected {
    border: 2px solid var(--primary-color);
    background-color: ${colors.white.shade1};
    text-shadow: 0.6px 0 ${colors.black.shade1};
    border-radius: 5px;
    color: ${colors.black.shade1};
    font-size: 14px;
    @mixin mobileToTablet {
      border-radius: 4.275px;
      font-size: ${typography.xs2};
      text-shadow: none;
      font-weight: 700;
      border: 2px solid var(--primary-color);
    }
  }
  .PromotedFilter-container-filters-filter:hover {
    border: 1px solid ${colors.green.shade1};
    background-color: ${colors.green.shade7};
  }
  .PromotedFilter-container-filters-mkp-filter:hover {
    border: 2px solid var(--primary-color);
    background-color: ${colors.white.shade1};
    border-radius: 5px;
    font-size: 14px;
    text-shadow: 0.6px 0 ${colors.black.shade1};
    @mixin mobileToTablet {
      border-radius: 4.275px;
      font-size: ${typography.xs2};
    }
  }
`;

export { promotedFiltersStyle };
