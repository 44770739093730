import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const WithOutsideClick = ({ children, onOutsideClick, className }) => {
  const node = useRef();

  const handleClick = (e) => {
    if (!(node.current && node.current.contains(e.target))) {
      onOutsideClick(e);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, []);

  return (
    <div
      className={className || 'doc-click-overlay'}
      ref={(r) => {
        node.current = r;
      }}
    >
      {children}
    </div>
  );
};

WithOutsideClick.defaultProps = {
  className: '',
};

WithOutsideClick.propTypes = {
  children: PropTypes.any.isRequired,
  onOutsideClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default WithOutsideClick;
