import css from 'styled-jsx/css';
import { colors } from '../../config/styles';

const ActionBarStyles = css`
  .action-bar {
    padding: 20px 30px;
    display: flex;
    background-color: ${colors.white.shade1};
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    border-radius: var(--border-radius);
  }
`;

export default ActionBarStyles;
