import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { WarrantySelectStyles } from './WarrantySelect.style';
import noop from '../../../utils/noop';
import Warning from '../../Warning/Warning';

const WarrantySelect = ({
  defaultSelectedIndex,
  deviceType,
  errorMessage,
  handleWarrantyOptionsSelected,
  options,
  inPdp,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(defaultSelectedIndex);
  const setSelectedOptionClass = (index) => index === selectedIndex;
  const marketplaceClass = 'mkp';
  const warranties = options
    .filter((option) => option.offeringId || option.value)
    .map((warranty) => {
      const { label, price } = warranty;
      let textPrice = price;
      let textLabel = label;

      if (label.includes('S/')) {
        textLabel = `${label.split('S/')[0].trim()}`;
        textPrice = `S/ ${label.split('S/')[1].trim()}`;
      }

      if (label.includes('$')) {
        textLabel = `${label.split('$')[0].trim()}`;
        textPrice = `$ ${label.split('$')[1].trim()}`;
      }

      if (textLabel.split(' ').length <= 2) {
        textLabel = `${textLabel} de Garantía Extendida`;
      }

      return {
        ...warranty,
        textPrice,
        label: textLabel,
      };
    });

  const inPageText = inPdp ? 'inPDP' : 'inXLP';
  const centerOptions = warranties.length < 3 ? 'center-options' : '';
  const isMobile = deviceType === 'mobile';
  const widthClass = warranties.length < 3 ? {} : { width: '100%' };
  const handleOptionChange = (index) => {
    if (selectedIndex !== index) {
      handleWarrantyOptionsSelected(options[index]);
      setSelectedIndex(index);
    }
  };

  let bottomStyle = warranties.length < 2 ? { marginRight: '132px' } : {};
  bottomStyle = warranties.length === 2 ? { marginRight: '72px' } : bottomStyle;
  bottomStyle = isMobile ? {} : bottomStyle;
  const noWarranty = options.filter((option) => !option.offeringId);
  let firstOptionLabel = 'No, gracias';

  if (noWarranty.length === 1) {
    firstOptionLabel = noWarranty[0].label;
  }

  const warrantiesLength = options.length;
  let cartServicesStyle =
    warrantiesLength > 4 ? { width: '780px', minWidth: '660px' } : { width: '100%', minWidth: '660px' };
  cartServicesStyle =
    isMobile && warrantiesLength === 2 ? { ...cartServicesStyle, minWidth: '400px' } : cartServicesStyle;

  cartServicesStyle =
    isMobile && warrantiesLength === 3 ? { ...cartServicesStyle, minWidth: '526px' } : cartServicesStyle;

  return (
    <div
      className={`warrantyOptions ${centerOptions} ${isMobile ? 'warrantyOptions-mobile' : ''}`}
      style={cartServicesStyle}
    >
      <table style={widthClass}>
        <thead>
          <tr>
            <th className="warrantyOptions__header">
              <i className="csicon-shield" />
            </th>
            <th className={`warrantyOptions__right-border ${marketplaceClass}`}>
              <p>Sin Garantía Extendida</p>
              <span>(Solo garantía de fabricante )</span>
            </th>
            {warranties.map((warranty, index) => (
              <th
                className={`${index === 0 ? 'warrantyOptions__recommended' : ''} ${marketplaceClass}`}
                key={warranty.offeringId}
              >
                {index === 0 && (
                  <p className={`warrantyOptions__recommended-text ${marketplaceClass}`}>¡Recomendado!</p>
                )}
                <p className="warrantyOptions__header-text">{warranty.label}</p>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          <tr>
            <td className="warrantyOptions__header">Valor Garantía</td>
            <td className={`warrantyOptions__body warrantyOptions__right-border ${marketplaceClass}`}>0</td>
            {warranties.map((warranty, index) => (
              <td
                className={`warrantyOptions__body ${
                  index === 0 ? 'warrantyOptions__recommended' : ''
                } warrantyOptions__prices ${marketplaceClass}`}
                key={warranty.offeringId}
              >
                {warranty.textPrice}
              </td>
            ))}
          </tr>
          <tr>
            <td className="warrantyOptions__header">Reparaciones con repuestos originales</td>
            <td className={`warrantyOptions__body warrantyOptions__right-border ${marketplaceClass}`}>
              <div className={`warrantyOptions__check ${marketplaceClass}`} />
            </td>
            {warranties.map((warranty, index) => (
              <td
                className={`warrantyOptions__body ${
                  index === 0 ? 'warrantyOptions__recommended' : ''
                } ${marketplaceClass}`}
                key={warranty.offeringId}
              >
                <div className={`warrantyOptions__check ${marketplaceClass}`} />
              </td>
            ))}
          </tr>

          <tr>
            <td className="warrantyOptions__header">Producto de reemplazo durante reparación.*</td>
            <td className={`warrantyOptions__body warrantyOptions__right-border ${marketplaceClass}`}>
              <div className="warrantyOptions__cross" />
            </td>
            {warranties.map((warranty, index) => (
              <td
                className={`warrantyOptions__body ${
                  index === 0 ? 'warrantyOptions__recommended' : ''
                } ${marketplaceClass}`}
                key={warranty.offeringId}
              >
                <div className={`warrantyOptions__check ${marketplaceClass}`} />
              </td>
            ))}
          </tr>

          <tr>
            <td className="warrantyOptions__header">Reparación dentro de 12 a 18 días</td>
            <td className={`warrantyOptions__body warrantyOptions__right-border ${marketplaceClass}`}>
              <div className="warrantyOptions__cross" />
            </td>
            {warranties.map((warranty, index) => (
              <td
                className={`warrantyOptions__body ${
                  index === 0 ? 'warrantyOptions__recommended' : ''
                } ${marketplaceClass}`}
                key={warranty.offeringId}
              >
                <div className={`warrantyOptions__check ${marketplaceClass}`} />
              </td>
            ))}
          </tr>

          <tr>
            <td className="warrantyOptions__header">Cobertura en caso de variación de voltaje</td>
            <td className={`warrantyOptions__body warrantyOptions__right-border ${marketplaceClass}`}>
              <div className="warrantyOptions__cross" />
            </td>
            {warranties.map((warranty, index) => (
              <td
                className={`warrantyOptions__body ${
                  index === 0 ? 'warrantyOptions__recommended' : ''
                } ${marketplaceClass}`}
                key={warranty.offeringId}
              >
                <div className={`warrantyOptions__check ${marketplaceClass}`} />
              </td>
            ))}
          </tr>

          <tr>
            <td className="warrantyOptions__header">Garantía transferible si se vende o regala el producto </td>
            <td className={`warrantyOptions__body warrantyOptions__right-border ${marketplaceClass}`}>
              <div className="warrantyOptions__cross" />
            </td>
            {warranties.map((warranty, index) => (
              <td
                className={`warrantyOptions__body ${
                  index === 0 ? 'warrantyOptions__recommended-last' : ''
                } ${marketplaceClass}`}
                key={warranty.offeringId}
              >
                <div className={`warrantyOptions__check ${marketplaceClass}`} />
              </td>
            ))}
          </tr>
        </tbody>
      </table>

      <div
        className="warrantyOptions__bottom"
        style={{ ...widthClass, ...bottomStyle }}
        id={`test-id-InCart-${inPageText}-WarrantyOption`}
      >
        <div
          className={`warrantyOptions__option ${
            setSelectedOptionClass(warranties.length) ? 'warrantyOptions__option--selected' : ''
          } ${marketplaceClass}`}
          onClick={() => handleOptionChange(warranties.length)}
          id={`testId-InCart-${inPageText}-WarrantyOption-btn-0`}
          aria-hidden="true"
        >
          {firstOptionLabel}
        </div>
        {warranties.map((warranty, index) => (
          <div
            className={`warrantyOptions__option ${
              setSelectedOptionClass(index) ? 'warrantyOptions__option--selected' : ''
            } ${marketplaceClass}`}
            onClick={() => handleOptionChange(index)}
            aria-hidden="true"
            id={`testId-InCart-${inPageText}-WarrantyOption-btn-${index + 1}`}
            key={warranty.offeringId}
          >
            <span>
              Agregar <br />
              {warranty.label}
            </span>
          </div>
        ))}
      </div>
      <div className="warrantyOptions_bottomLabel">
        *Aplica a categorías: lavadoras, secadoras, cocinas y refrigeradores
      </div>

      {errorMessage && <Warning label={errorMessage} iconSize="copy2" labelSize="copy5" />}

      <style jsx>{WarrantySelectStyles}</style>
    </div>
  );
};

WarrantySelect.defaultProps = {
  defaultSelectedIndex: -1,
  deviceType: 'desktop',
  errorMessage: null,
  handleWarrantyOptionsSelected: noop,
  options: [],
  inPdp: false,
};

WarrantySelect.propTypes = {
  defaultSelectedIndex: PropTypes.number,
  deviceType: PropTypes.string,
  errorMessage: PropTypes.string,
  handleWarrantyOptionsSelected: PropTypes.func,
  options: PropTypes.array,
  inPdp: PropTypes.bool,
};

export default WarrantySelect;
