import React from 'react';
import PropTypes from 'prop-types';
import Chip from '../ui/Chip/Chip';
import { withApplicationContext } from '../../utils/ApplicationContext';
import SelectedFiltersStyles from './SelectedFiltersMobile.style';
import { ucFirst } from '../../utils/textHelper';
import { setDigitalData } from '../SelectedFilters/helpers';

const SelectedFilters = ({ filters, selectedFiltersLabel, makeFacetCall, facets }) => {
  const getSelectedFacetsLabels = () => {
    const facetLabelMap = {};
    facets.forEach((facet) => {
      const { facetType } = facet && facet.values && facet.values[0] ? facet.values[0] : '';
      facetLabelMap[`${facetType}`] = facet && facet.name ? facet.name : '';
    });
    return facetLabelMap;
  };
  const handleChipClick = (url, clickedType, clickedValue) => {
    const urls = [];
    if (url) {
      const facetURL = url.split('&').filter((u) => !u.match(/facetSelected/g));
      if (facetURL.length > 0) {
        urls.push(facetURL.join('&'));
      }
    }
    makeFacetCall(urls.join('&'), false);
    const facetLabels = getSelectedFacetsLabels();
    const facetLabel = facetLabels[`${clickedType}`];
    setDigitalData(facetLabel, clickedValue);
  };

  const getValue = (label, attributes) => {
    if (attributes && attributes.unit && label.split('-').length > 1) {
      const { unit } = attributes;
      return `${unit}${label.split('-').join(` - ${unit}`)}`;
    }
    return ucFirst(label);
  };

  return (
    <div className="selected-filters">
      <div className="filter-container" id="testId-SelectedFilters-container">
        <div className="filter-title">{selectedFiltersLabel}</div>
        <div className="chips">
          {filters.map((val) => (
            <Chip
              value={getValue(val.title, val.attributes)}
              key={`${val.title}-${val.facetValue}`}
              onChipClick={handleChipClick}
              url={val.url}
              facetType={val.facetType}
              facetValue={val.facetValue}
              type="mkp"
            />
          ))}
        </div>
      </div>

      <style jsx>{SelectedFiltersStyles}</style>
    </div>
  );
};

SelectedFilters.defaultProps = {
  selectedFiltersLabel: 'Filtros:',
};

SelectedFilters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedFiltersLabel: PropTypes.string,
  makeFacetCall: PropTypes.string.isRequired,
  facets: PropTypes.array.isRequired,
};

export { SelectedFilters };
export default withApplicationContext(SelectedFilters);
