import PropTypes from 'prop-types';
import React from 'react';
import { withLabels } from '../../../../../utils/LabelsContext';
import { SizeEquilancyStyles } from './EquilancyTable.style';

const CountrySize = ({ sizelable, size }) => {
  return size ? (
    <>
      <span className="country-size">
        <b>{sizelable}:</b> {`${size}`}
      </span>
      <style jsx>{SizeEquilancyStyles}</style>
    </>
  ) : (
    ''
  );
};
const EquilancyTable = ({ isMobile, sizeMap, tenantValue, labels, tenantSize }) => {
  const { CMSIZE, EURSIZE, USSIZE, UKSIZE } = labels;

  return (
    <div className="size-equilancy-wrapper rebranded">
      <div className={`${isMobile ? 'size-equilancy-table-mobile' : 'size-equilancy-table-desktop'}`}>
        <p className="align-center z-top">
          <span className="tenant-size">{`${tenantSize} ${tenantValue} =`}</span>
          <CountrySize sizelable={CMSIZE} size={sizeMap.CM} />
          <CountrySize sizelable={USSIZE} size={sizeMap.US} />
          <CountrySize sizelable={EURSIZE} size={sizeMap.EUR} />
          <CountrySize sizelable={UKSIZE} size={sizeMap.UK} />
        </p>
      </div>
      <style jsx>{SizeEquilancyStyles}</style>
    </div>
  );
};

EquilancyTable.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  sizeMap: PropTypes.object.isRequired,
  tenantValue: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  tenantSize: PropTypes.string.isRequired,
};

CountrySize.propTypes = {
  size: PropTypes.string.isRequired,
  sizelable: PropTypes.string.isRequired,
};

export default withLabels(EquilancyTable);
export { EquilancyTable };
