import React from 'react';
import PropTypes from 'prop-types';
import { SizeButtonStyles } from './SizeButton.style';

const SizeButton = ({ value, available, index, selectedSize, onSizeChange }) => {
  const onClickHandler = () => {
    onSizeChange(value, index);
  };

  const isSelectedSize = (val) => selectedSize && val.toUpperCase() === selectedSize.toUpperCase();

  return (
    <button
      id={`testId-sizeButton-${value}`}
      type="button"
      key={value}
      className={`size-button rebranded ${isSelectedSize(value) ? 'size-button-selected' : ''} ${
        !available ? 'size-button-unavailable' : ''
      } `}
      onClick={onClickHandler}
      disabled={!available}
    >
      {value}
      <style jsx>{SizeButtonStyles}</style>
    </button>
  );
};

SizeButton.propTypes = {
  value: PropTypes.string.isRequired,
  available: PropTypes.bool.isRequired,
  index: PropTypes.string.isRequired,
  selectedSize: PropTypes.string.isRequired,
  onSizeChange: PropTypes.func.isRequired,
};

export default SizeButton;
