import PropTypes from 'prop-types';
import React from 'react';
import noop from '../../utils/noop';
import SizeButtons from './SizeButtons/SizeButtons';
import SizeDropdown from './SizeDropdown/SizeDropdown';

const Size = ({ sizes, selectedSize, onSizeChange, title, type, variant, isSizeChartHidden }) => {
  return type === 'dropdown' ? (
    <SizeDropdown
      sizes={sizes}
      selectedSize={selectedSize}
      onSizeChange={onSizeChange}
      title={title}
      variant={variant === 'cart' && 'medium'}
    />
  ) : (
    <SizeButtons
      sizes={sizes}
      selectedSize={selectedSize}
      onSizeChange={onSizeChange}
      title={title}
      isSizeChartHidden={isSizeChartHidden}
    />
  );
};

Size.defaultProps = {
  sizes: [],
  onSizeChange: noop,
  title: 'Selection Talla',
  type: '',
  selectedSize: undefined,
  variant: '',
  isSizeChartHidden: false,
};

Size.propTypes = {
  sizes: PropTypes.arrayOf(PropTypes.object),
  onSizeChange: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
  selectedSize: PropTypes.string,
  variant: PropTypes.string,
  isSizeChartHidden: PropTypes.bool,
};

export default Size;
