import PropTypes from 'prop-types';
import React from 'react';
import { storeMap } from '../../config/storeMap';
import { withApplicationContext } from '../../utils/ApplicationContext';
import checkPLPSeoFilters from '../../utils/CheckPLPSeoFilters';
import BodyCopy from '../ui/BodyCopy/BodyCopy';
import CategoryTitleStyles from './CategoryTitle.style';

const CategotyTitle = ({
  level1Category,
  level2Category = {},
  appCtx,
  appliedFacets,
  resultsLabel,
  paginationCount,
}) => {
  let level1CategoryURL = '#';
  const { label = '' } = level2Category;
  let path = appCtx.tenant;
  if (appCtx.store && appCtx.store !== 'tienda') {
    path = `${storeMap[appCtx.store]}-${appCtx.regionCode}`;
  }
  if (level1Category && !level1Category.isRoot) {
    level1CategoryURL = `/${path}${level1Category.link}`;
  }

  const labelComplement = checkPLPSeoFilters(appliedFacets);
  const l2CatgeoryLabel = `${label} ${labelComplement}`;
  const isMobile = appCtx.deviceType === 'mobile';

  return (
    <div className={`categoty-title-container ${isMobile ? 'mobile' : ''}`} id="testId-CategoryTitle-container">
      {appCtx.deviceType === 'desktop' && (
        <a className="l1category" href={level1CategoryURL}>
          <h2>{level1Category ? level1Category.label : ''}</h2>
        </a>
      )}
      <BodyCopy size="copy10" type="primary" isHighlighted={!isMobile} light={isMobile}>
        <h1 className="l2category" title={l2CatgeoryLabel}>
          {l2CatgeoryLabel}
        </h1>
      </BodyCopy>
      {isMobile && (
        <BodyCopy size="copy5" id="testId-ProductLandingContainer-totalResults" type="secondary">
          {resultsLabel} ({paginationCount})
        </BodyCopy>
      )}
      <style jsx>{CategoryTitleStyles}</style>
    </div>
  );
};

CategotyTitle.defaultProps = {
  appliedFacets: [],
  resultsLabel: '',
};

CategotyTitle.propTypes = {
  level1Category: PropTypes.object.isRequired,
  level2Category: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  appliedFacets: PropTypes.array,
  resultsLabel: PropTypes.string,
  paginationCount: PropTypes.string.isRequired,
};

export { CategotyTitle };
export default withApplicationContext(CategotyTitle);
