import PropTypes from 'prop-types';
import React from 'react';
import noop from '../../utils/noop';
import { FacetsLinkStyle } from './FacetsLink.style';

const FacetsLink = ({ title, onFacetChange, count, isFacetCountHidden }) => {
  return (
    <>
      <button type="button" className="facets-link" id={`testId-facetsLink-${title}`} onClick={onFacetChange}>
        {`${title} ${!isFacetCountHidden ? `(${count})` : ''}`}
        <i className="icon csicon-arrow" />
      </button>
      <style jsx>{FacetsLinkStyle}</style>
    </>
  );
};

FacetsLink.defaultProps = {
  onFacetChange: noop,
  isFacetCountHidden: false,
};

FacetsLink.propTypes = {
  title: PropTypes.string.isRequired,
  onFacetChange: PropTypes.func,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isFacetCountHidden: PropTypes.bool,
};

export default FacetsLink;
