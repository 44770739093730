import css from 'styled-jsx/css';
import { colors, typography } from '../../../../config/styles';

const IncrementStyles = css`
  .increment {
    position: absolute;
    z-index: 5;
    font-size: ${typography.lg1};
    font-weight: 500;
    bottom: 10px;
    color: ${colors.gray.shade15};
    background-color: ${colors.gray.shade6};
    border-radius: 2px;
    right: -2px;
    top: -2px;
    width: 30px;

    &-mobile {
      height: 28px;
    }

    &-desktop {
      height: 30px;
    }

    &:disabled {
      color: ${colors.gray.shade5};
    }
  }
`;

export { IncrementStyles };
