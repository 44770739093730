import css from 'styled-jsx/css';
import { colors } from '../../config/styles';

const surveyBarStyles = css`
  .survey-wrapper {
    border-bottom: 2px solid ${colors.green.shade19};
    @mixin tabletToDesktop {
      display: flex;
      flex-direction: row;
    }
  }

  .with-border {
    border-top: 5px solid ${colors.gray.shade6};
    border-bottom: 5px solid ${colors.gray.shade6};
  }
`;
export default surveyBarStyles;
