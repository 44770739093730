import React, { useEffect, useState } from 'react';

const WithTimeout = (WrappedComponent, milliseconds) => {
  const CMP = (props) => {
    const [toggle, setToggle] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => setToggle(false), milliseconds);
      return () => {
        clearTimeout(timer);
      };
    }, [toggle]);

    return <WrappedComponent {...props} timer={{ toggle, setToggle }} />;
  };

  return (props) => {
    return <CMP {...props} />;
  };
};

export default WithTimeout;
