import PropTypes from 'prop-types';
import React from 'react';
import ChipStyles from './Chip.style';

const Chip = ({ value, url, onChipClick, type, iconClass, facetType, facetValue }) => {
  const handleClick = (clickedType, clickedValue) => {
    onChipClick(url, clickedType, clickedValue);
  };
  return (
    <button
      type="button"
      onClick={() => {
        handleClick(facetType, facetValue);
      }}
      className={`chip ${type}`}
    >
      {value}
      <span className={iconClass} />
      <i className="csicon-danger">
        <span className="sr-only">remove</span>
      </i>
      <style jsx>{ChipStyles}</style>
    </button>
  );
};

Chip.propTypes = {
  value: PropTypes.any.isRequired,
  url: PropTypes.string.isRequired,
  onChipClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  iconClass: PropTypes.string,
  facetType: PropTypes.string,
  facetValue: PropTypes.string,
};

Chip.defaultProps = {
  type: '',
  iconClass: '',
  facetType: '',
  facetValue: '',
};

export default Chip;
