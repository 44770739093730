import React from 'react';
import PropTypes from 'prop-types';
import badgesStyles from './DiscountBadge.style';
import BodyCopy from '../../ui/BodyCopy/BodyCopy';

const DiscountBadge = ({ badge }) => {
  const getBadgeSize = () => {
    return 'copy5';
  };
  return (
    <div className="discount-badge">
      <BodyCopy size={getBadgeSize()} key={badge.label} isHighlighted>
        {(classes) => (
          <span
            id={`testId-Pod-badges-${badge.label}`}
            className={`${classes} discount-badge-item`}
            style={{
              backgroundColor: `${badge.styles.backgroundColor}`,
              color: `${badge.styles.textColor}`,
            }}
          >
            {badge.label}
          </span>
        )}
      </BodyCopy>
      <style jsx>{badgesStyles}</style>
    </div>
  );
};

DiscountBadge.propTypes = {
  badge: PropTypes.object.isRequired,
};

export default DiscountBadge;
