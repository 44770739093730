/* eslint-disable global-require, import/no-dynamic-require */
import React, { useState, useEffect, useContext } from 'react';
import createContainer from 'constate';
import { nameHOC } from './hocutils';
import { changeProductRoute } from './RouteHandler';

const changeVariant = (productData, setProductData) => (site, variantId, store, regionCode, force) => {
  const { currentVariant, id, slug, variants } = productData;
  if (!force && (variantId === currentVariant || !variants.some((v) => v.id === variantId))) {
    return;
  }
  const updatedProductData = JSON.parse(JSON.stringify(productData));
  updatedProductData.currentVariant = variantId;

  setProductData(updatedProductData);

  changeProductRoute({
    site,
    id,
    slug,
    variant: variantId,
    store,
    regionCode,
  });
};

const changeLinkedProductVariant = (productData, setProductData) => (productId, variantId) => {
  const updatedProductData = JSON.parse(JSON.stringify(productData));

  const { linkedProducts } = updatedProductData;
  if (!linkedProducts || !linkedProducts[productId]) {
    return;
  }
  updatedProductData.linkedProducts[productId].currentVariant = variantId;

  setProductData(updatedProductData);
};

function useProductContext({ data: initialProductData }) {
  const [productData, setProductData] = useState(initialProductData);
  useEffect(() => {
    setProductData(initialProductData);
  }, [initialProductData]);
  return {
    recommendedProducts: [],
    ...productData,
    changeVariant: changeVariant(productData, setProductData),
    changeLinkedProductVariant: changeLinkedProductVariant(productData, setProductData),
    setProductData,
  };
}

const ProductContext = createContainer(useProductContext);

const withProduct = (WrappedComponent) => {
  const { getInitialProps } = WrappedComponent;
  const ComponentWithProductData = (props) => {
    const productDataWithState = useContext(ProductContext.Context);
    return <WrappedComponent {...props} product={productDataWithState} />;
  };
  if (typeof getInitialProps === 'function') {
    ComponentWithProductData.getInitialProps = getInitialProps;
  }
  ComponentWithProductData.originalName = nameHOC(WrappedComponent);
  ComponentWithProductData.displayName = nameHOC(WrappedComponent, 'WithProduct');
  return ComponentWithProductData;
};

export default ProductContext;
export { withProduct };
