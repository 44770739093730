import fetch from 'isomorphic-unfetch';
import apiConfig from '../config/api/apiConfig';
import constants from '../config/constants';
import WebStorage from './WebStorage';

export const formatSearchTerm = (referrerUrl = '') => {
  let term = '';
  if (referrerUrl) {
    const ntt = referrerUrl.split('/search?Ntt=')[1];
    if (ntt && typeof ntt === 'string') {
      try {
        term = encodeURIComponent(ntt.split('&')[0].toLowerCase().replace(/\+/gi, ' '));
      } catch (e) {
        term = '';
      }
    }
  }
  return term;
};

export const decodeAndFormatSearchTerm = (referrerUrl = '') => {
  return formatSearchTerm(decodeURIComponent(referrerUrl));
};

export const getPageType = () => {
  const url = window.location.href.toLocaleLowerCase();

  if (url.includes('category') || url.includes('/lista/')) {
    return 'category';
  }

  return 'search';
};

export const getSignal = (categoryName) => {
  if (categoryName) return categoryName;

  const currentUrl = new URL(window.location.href);
  const urlQueryParams = new URLSearchParams(currentUrl.search);
  const search = urlQueryParams.get('Ntt');

  return search;
};

export const getStore = (store = '') => {
  const { STORE_NAMES_FOR_VAS } = constants;
  return STORE_NAMES_FOR_VAS[`${store}`.toUpperCase()] || null;
};

export const getHeadersForSponsors = (platform, tenant) => {
  const { WEB, MOBILE, TABLET } = constants.X_CHANNEL_IDS_HEADERS_VALUES;
  const { DEVICE_DESKTOP, DEVICE_MOBILE, DEVICE_TABLET } = constants;
  const { FALABELLA_CL, FALABELLA_CO, FALABELLA_PE, SODIMAC_CL, SODIMAC_PE, SODIMAC_CO } = constants.TENANT_TYPES;
  const { CHILE, PERU, COLOMBIA } = constants.COUNTRIES_ABBREVIATIONS;

  const platformTypes = {
    [DEVICE_DESKTOP]: WEB,
    [DEVICE_MOBILE]: MOBILE,
    [DEVICE_TABLET]: TABLET,
  };

  const tenantTypes = {
    [FALABELLA_CL]: CHILE,
    [FALABELLA_PE]: PERU,
    [FALABELLA_CO]: COLOMBIA,
    [SODIMAC_CL]: CHILE,
    [SODIMAC_PE]: PERU,
    [SODIMAC_CO]: COLOMBIA,
  };

  const headers = {
    'Content-Type': 'application/json',
    'x-channel-id': platformTypes[platform],
    'x-tenant-id': tenantTypes[tenant],
  };

  return headers;
};

export const makeRequest = async (url, data, platform, tenant) => {
  const headers = getHeadersForSponsors(platform, tenant);

  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  };

  await fetch(url, options);
};

const getStoreHash = (fullToken, regionCode) => {
  let storeHash = '';
  const MABAYA_TOKEN = fullToken;
  if (MABAYA_TOKEN && typeof MABAYA_TOKEN === 'string') {
    try {
      // eslint-disable-next-line prefer-destructuring
      storeHash = `${MABAYA_TOKEN}`
        .split('||')
        .filter((e) => e.startsWith(regionCode))[0]
        .split('=')[1];
    } catch (e) {
      storeHash = '';
    }
  }

  return storeHash;
};

const sponsoredEvents = {
  visit: ({ mabayaToken, referrerUrl = '', regionCode, sku, enabled, id, isPDP = false, referrerType = 'search' }) => {
    if (`${enabled}` === 'false') {
      return;
    }
    const token = getStoreHash(mabayaToken, regionCode);
    if (!token) {
      return;
    }
    const isSLP = !!referrerUrl.split('Ntt=')[1];
    const referrerSignal = isSLP ? formatSearchTerm(referrerUrl) : id;
    if (!isPDP && !referrerSignal) {
      return;
    }
    const cookie = (isPDP && WebStorage.getItem({ name: 'mabayaSession', fromPersistence: true })) || null;
    const visitUrl = apiConfig.mabayaVisitUrl({
      storeHash: token,
      sku,
      referrerSignal,
      cookie,
      referrerURL: referrerUrl,
      referrerType,
    });
    // eslint-disable-next-line
    fetch(visitUrl).catch((e) => console.log('Error calling', visitUrl));
  },
  click: ({ mabayaToken, platform, regionCode, adInfo, mPos, enabled }) => {
    if (`${enabled}` === 'false') {
      return;
    }
    const token = getStoreHash(mabayaToken, regionCode);
    if (!token) {
      return;
    }
    const cookie = WebStorage.getItem({ name: 'mabayaSession', fromPersistence: true }) || '';
    const clickUrl = apiConfig.mabayaClickUrl({
      storeHash: token,
      platform,
      adInfo,
      mPos,
      cookie,
    });
    fetch(clickUrl);
  },
  impression: ({ mabayaToken, platform, regionCode, adInfo, mPos, enabled }) => {
    if (`${enabled}` === 'false') {
      return;
    }
    const token = getStoreHash(mabayaToken, regionCode);
    if (!token) {
      return;
    }
    const impressionUrl = apiConfig.mabayaImpressionUrl({
      storeHash: token,
      platform,
      adInfo,
      mPos,
    });

    fetch(impressionUrl);
  },
};

export default sponsoredEvents;
