const getPodStyles = (deviceType, layout) => {
  const styles = {
    LIST: {
      desktop: {
        imageDimension: 170,
        title: 'title1',
        subTitle: 'copy2',
        colorSwatch: 'small',
        ratingVisual: 'normal',
        imageGroup: 'list-view',
      },
      mobile: {
        imageDimension: 90,
        title: 'title1',
        subTitle: 'copy2',
        colorSwatch: 'small',
        ratingVisual: 'normal',
        imageGroup: 'list-view',
      },
    },
    '1_GRID': {
      desktop: {
        imageDimension: 249,
        title: 'title1',
        subTitle: 'copy2',
        colorSwatch: 'small',
        ratingVisual: 'normal',
        imageGroup: 'grid-view',
      },
      mobile: {
        imageDimension: 378,
        title: 'title1',
        subTitle: 'copy13',
        colorSwatch: 'medium',
        ratingVisual: 'normal',
        imageGroup: '1_GRID',
      },
    },
    '2_GRID': {
      desktop: {
        imageDimension: 498,
        title: 'title1',
        subTitle: 'copy13',
        colorSwatch: 'medium',
        ratingVisual: 'normal',
        imageGroup: '2_GRID',
      },
      mobile: {
        imageDimension: 170,
        title: 'title5',
        subTitle: 'copy5',
        colorSwatch: 'small',
        ratingVisual: 'normal',
        imageGroup: 'grid-view',
      },
    },
    '4_GRID': {
      desktop: {
        imageDimension: 240,
        title: 'title1',
        subTitle: 'copy2',
        colorSwatch: 'small',
        ratingVisual: 'normal',
        imageGroup: 'grid-view',
      },
      mobile: {
        imageDimension: 171,
        title: 'title1',
        subTitle: 'copy2',
        colorSwatch: 'small',
        ratingVisual: 'normal',
        imageGroup: 'grid-view',
      },
    },
    '3_GRID': {
      desktop: {
        imageDimension: 324,
        title: 'title1',
        subTitle: 'copy2',
        colorSwatch: 'small',
        ratingVisual: 'normal',
        imageGroup: 'grid-view',
      },
      mobile: {
        imageDimension: 170,
        title: 'title1',
        subTitle: 'copy2',
        colorSwatch: 'small',
        ratingVisual: 'normal',
        imageGroup: 'grid-view',
      },
    },
  };
  return styles[layout][deviceType === 'tablet' ? 'mobile' : deviceType];
};

export { getPodStyles };
