export const digitalData = (type, isViewMore) => {
  let eventToDigitalData = new CustomEvent('DDXLPFacetSearch', {
    bubbles: true,
    detail: { payload: type },
  });
  if (isViewMore) {
    eventToDigitalData = new CustomEvent('DDXLPVerMasButtonClick', {
      bubbles: true,
      detail: { payload: type },
    });
  }
  window.dispatchEvent(eventToDigitalData);
};

export const resetDigitalData = () => {
  const deleteEvents = [
    'DDXLPMobileTabInteractionUnset',
    'DDXLPFacetSearchUnset',
    'DDXLPFacetModalUnset',
    'DDXLPUnselectionFiltersUnset',
  ];
  deleteEvents.forEach((deleteEvent) => {
    const event = new CustomEvent(deleteEvent, {});
    window.dispatchEvent(event);
  });
  const eventToDigitalData = new CustomEvent('DDXLPVerMasButtonClick', {
    bubbles: true,
    detail: { payload: '0' },
  });
  window.dispatchEvent(eventToDigitalData);
};
