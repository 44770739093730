import React from 'react';
import PropTypes from 'prop-types';
import warningStyle from './Warning.style';
import BodyCopy from '../ui/BodyCopy/BodyCopy';

const Warning = ({ label, iconSize, labelSize, forPDP, type }) => {
  return (
    <div className={`warning-container ${forPDP ? 'forPDP' : ''} ${type}`}>
      <span className="warning-icon-alert">
        <BodyCopy size={iconSize}>
          {type === 'primary' ? (
            <i className="csicon-warning-alert">
              <span className="sr-only">{label}</span>
            </i>
          ) : (
            <div className="secondary-warning-icon" />
          )}
        </BodyCopy>
      </span>
      <span className="warning-label">
        <BodyCopy size={labelSize}>{label} </BodyCopy>
      </span>
      <style jsx>{warningStyle}</style>
    </div>
  );
};

Warning.defaultProps = {
  label: '',
  iconSize: 'copy3',
  labelSize: 'copy3',
  forPDP: false,
  type: 'primary',
};

Warning.propTypes = {
  label: PropTypes.string,
  iconSize: PropTypes.string,
  labelSize: PropTypes.string,
  type: PropTypes.string,
  forPDP: PropTypes.bool,
};

export default Warning;
