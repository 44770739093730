import PropTypes from 'prop-types';
import React from 'react';
import noop from '../../../utils/noop';
import surveyBoxStyles from './SurveyBox.style';

const InnerContent = ({ content, icon }) => (
  <>
    <i className={`icon-medium ${icon}`} />
    {content}
    <i className="icon-small csicon-arrow" />
    <style jsx>{surveyBoxStyles}</style>
  </>
);
InnerContent.propTypes = {
  icon: PropTypes.string.isRequired,
  content: PropTypes.func.isRequired,
};

const Button = ({ onClickHandler, icon, content }) => (
  <button type="button" className="survey-box" onClick={onClickHandler}>
    <InnerContent icon={icon} content={content} />
    <style jsx>{surveyBoxStyles}</style>
  </button>
);
Button.propTypes = {
  icon: PropTypes.string.isRequired,
  content: PropTypes.func.isRequired,
  onClickHandler: PropTypes.func.isRequired,
};

const Anchor = ({ url, icon, content }) => (
  <a className="survey-box" href={url} target="_blank" rel="noopener noreferrer">
    <InnerContent icon={icon} content={content} />
    <style jsx>{surveyBoxStyles}</style>
  </a>
);
Anchor.propTypes = {
  icon: PropTypes.string.isRequired,
  content: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

const SurveyBox = ({ icon, children, onClickHandler, type, url }) => {
  return type === 'link' ? (
    <Anchor url={url} icon={icon} content={children} />
  ) : (
    <Button onClickHandler={onClickHandler} icon={icon} content={children} />
  );
};

SurveyBox.defaultProps = {
  url: '',
  type: 'button',
  onClickHandler: noop,
};

SurveyBox.propTypes = {
  icon: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClickHandler: PropTypes.func,
  type: PropTypes.oneOf(['button', 'link']),
  url: PropTypes.string,
};

export default SurveyBox;
