import React from 'react';
import PropTypes from 'prop-types';
import { singleSelectStyle } from './SingleSelect.style';
import FacetsLink from '../../FacetsLink/FacetsLink';
import noop from '../../../utils/noop';
import _ from '../../../utils/LodashImports';

const SingleSelect = ({ item, onFacetChange, isFacetCountHidden }) => {
  let filterName = '';
  item.values.forEach((value) => {
    const filters = _.get(value, 'filters', []);
    filterName = _.get(
      filters.find((f) => /_category_paths/.test(f.name)),
      'name',
      ''
    );
  });
  return (
    <ul data-facet-type={filterName} className="singleselect">
      {item.values.map((value, index) => {
        const { title, url, count } = value;
        return (
          <li key={JSON.stringify(`${title}-${index}`)}>
            <FacetsLink
              title={title}
              onFacetChange={() => {
                onFacetChange(url, value);
              }}
              count={count}
              isFacetCountHidden={isFacetCountHidden}
            />
          </li>
        );
      })}
      <style jsx>{singleSelectStyle}</style>
    </ul>
  );
};

SingleSelect.defaultProps = {
  onFacetChange: noop,
  isFacetCountHidden: false,
};

SingleSelect.propTypes = {
  item: PropTypes.object.isRequired,
  onFacetChange: PropTypes.func,
  isFacetCountHidden: PropTypes.bool,
};

export default SingleSelect;
