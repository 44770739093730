import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';

const { className, styles } = css.resolve`
  .copy1 {
    font-size: ${typography.lg1};
  }
  .copy2 {
    font-size: ${typography.lg};
  }
  .copy3 {
    font-size: ${typography.base};
  }
  .copy4 {
    font-size: ${typography.sm1};
  }
  .copy5 {
    font-size: ${typography.sm};
  }
  .copy6 {
    font-size: ${typography.xs};
  }
  .copy7 {
    font-size: ${typography.xs1};
  }
  .copy8 {
    font-size: ${typography.sm2};
  }
  .copy9 {
    font-size: ${typography.xxs};
  }
  .copy10 {
    font-size: ${typography.xl};
  }
  .copy11 {
    font-size: ${typography.xl2c};
  }
  .copy12 {
    font-size: ${typography.xl3};
  }
  .copy13 {
    font-size: ${typography.xl2};
  }
  .copy14 {
    font-size: ${typography.lg1};
  }
  .copy15 {
    font-size: ${typography.xla};
  }
  .copy16 {
    font-size: ${typography.xl2b};
  }
  .primary {
    color: ${colors.gray.shade15};
  }
  .secondary {
    color: ${colors.gray.shade19};
  }
  .tertiary {
    color: ${colors.gray.shade5};
  }
  .quaternary {
    color: ${colors.gray.shade10};
  }
  .quinary {
    color: ${colors.black.shade7};
  }
  .senary {
    color: ${colors.gray.shade40};
  }
  .septenary {
    color: ${colors.gray.shade52};
  }
  .high {
    color: ${colors.red.shade1};
  }
  .medium {
    color: ${colors.gray.shade52};
  }
  .low {
    color: ${colors.gray.shade44};
  }

  .title {
    font-size: ${typography.base};
    letter-spacing: 0.05rem;
    font-weight: 700;
    display: block;
  }

  .title1 {
    font-size: ${typography.sm};
    letter-spacing: 0.05rem;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
  }

  .title2 {
    font-size: ${typography.lg1};
    line-height: 20px;
    display: block;
  }

  .title3 {
    font-size: ${typography.sm1};
    letter-spacing: 0.85px;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
  }

  .title4 {
    font-size: ${typography.lg};
    line-height: 20px;
    display: block;
  }

  .title5 {
    font-size: ${typography.xxs};
    letter-spacing: 0.62px;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
  }

  .title6 {
    font-size: ${typography.sm2};
    letter-spacing: 0.85px;
    font-weight: 700;
    display: block;
  }

  .title7 {
    font-size: ${typography.xs2};
    letter-spacing: 0.55px;
    font-weight: 700;
    display: block;
  }

  .title8 {
    font-size: ${typography.lg1};
    letter-spacing: 0.05rem;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
    margin-top: 10px;
  }

  .title9 {
    font-size: ${typography.xl2c};
  }

  .title10 {
    font-size: ${typography.xl};
    margin-top: 10px;
  }

  .title11 {
    font-size: ${typography.xl2c};
    margin-bottom: 10px;
  }

  .title12 {
    font-size: ${typography.xs};
    letter-spacing: 0.55px;
    font-weight: 700;
    display: block;
  }

  .title13 {
    font-size: ${typography.xl2};
  }

  .title14 {
    font-size: ${typography.xl};
    display: flex;
    margin-bottom: 27px;
  }
  .title15 {
    font-size: ${typography.xs2};
    display: flex;
    padding: 5px 0px;
    text-transform: uppercase;
  }
  .title16 {
    display: block;
    font-size: ${typography.xs};
  }
  .title17 {
    display: block;
    font-size: ${typography.xs};
    font-weight: 700;
    letter-spacing: 0.73px;
    line-height: 12px;
  }
  .title18 {
    display: block;
    font-size: ${typography.sm1};
    font-weight: 700;
    letter-spacing: 0.95px;
    line-height: 16px;
  }
  .title19 {
    color: ${colors.black.shade1};
    font-size: ${typography.sm};
    line-height: 15px;
    display: block;
  }
  .title20 {
    display: block;
    color: ${colors.black.shade1};
    font-size: ${typography.lg1};
    line-height: 19px;
  }
  .title21 {
    color: ${colors.black.shade7};
    font-size: ${typography.sm};
    letter-spacing: 0.73px;
    line-height: 14px;
    display: block;
  }
  .title22 {
    color: ${colors.black.shade10};
    font-size: ${typography.sm};
    letter-spacing: 0.14px;
    line-height: 14px;
    display: block;
  }
  .title23 {
    font-size: ${typography.sm};
    font-weight: 700;
    display: block;
  }
  .title24 {
    font-size: ${typography.base};
    letter-spacing: 0.05rem;
    font-weight: 700;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  b.title24 {
    font-size: ${typography.lg1};
    font-weight: 700;
    display: block;
    text-transform: none;
  }
  .bold {
    font-weight: 700;
  }

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .line-clamp {
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden;
  }

  .line-clamp-2 {
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }

  .line-clamp-3 {
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }

  .normal {
    font-weight: 400;
  }

  .centered {
    text-align: center;
  }

  .crossed {
    text-decoration: line-through;
  }

  .width-100 {
    width: 100%;
  }

  .line-height-29 {
    line-height: 29px;
  }
  .line-height-22 {
    line-height: 22px;
  }
  .line-height-24 {
    line-height: 24px;
  }
  .line-height-19 {
    line-height: 19px;
  }
  .line-height-17 {
    line-height: 17px;
  }
  .light {
    font-weight: 300;
  }
`;

export { className as BodyCopyClassName, styles as BodyCopyStyles };
