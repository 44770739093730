import React from 'react';
import PropTypes from 'prop-types';
import { CountInputStyles } from './CountInput.style';
import { useProductCounterContext } from '../ProductCounterContext';

const CountInput = ({ className, handleInputBlurKey, handleOnChange, maximum, minimum, style, type, id }) => {
  const { count } = useProductCounterContext();

  return (
    <div className={`product-count-value value-${type} ${className}`} style={style}>
      <input
        className="product-count-value-input"
        id={id}
        max={maximum}
        autoComplete="off"
        min={minimum}
        onBlur={handleInputBlurKey}
        onInput={handleOnChange}
        step={1}
        onPaste={(e) => {
          e.preventDefault();
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleInputBlurKey('Enter');
          }
        }}
        type="text"
        value={count}
      />

      <style jsx>{CountInputStyles}</style>
    </div>
  );
};

CountInput.defaultProps = {
  className: '',
  style: {},
  type: 'primary',
  id: 'testId-stepper-input',
};

CountInput.propTypes = {
  className: PropTypes.string,
  handleInputBlurKey: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  maximum: PropTypes.number.isRequired,
  minimum: PropTypes.number.isRequired,
  style: PropTypes.object,
  type: PropTypes.oneOf(['primary', 'secondary']),
  id: PropTypes.string,
};

export { CountInput };
