import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';

const VerMasFacetStyle = css`
  .ver-mas-container {
    @mixin tabletToDesktop {
      max-height: 250px;
    }
    @mixin mobileToTablet {
      overflow-y: auto;
      max-height: 434px;
      background-color: ${colors.gray.shade12};
    }
  }
  .scroll {
    @mixin tabletToDesktop {
      overflow-y: scroll;
    }
  }
  .searchBox {
    padding: 0 10px;
    margin: 10px 0 15px 0;
    @mixin tabletToDesktop {
      margin-left: -17px;
    }
    @mixin mobileToTablet {
      padding-top: 14px;
      padding-left: 15px;
      padding-right: 16px;
      background-color: ${colors.gray.shade12};
      margin: unset;
      display: flex;
      position: relative;
    }
  }
  .sidebar-facet-item {
    margin: 10px 20px;
    @mixin tabletToDesktop {
      margin: 0 0 8px 0;
    }
    @mixin mobileToTablet {
      border-bottom: 1px solid ${colors.gray.shade36};
      padding: 6px 16px;
      min-height: 40px;
      margin: unset;
    }
  }
  .loader {
    display: flex;
    justify-content: center;
    @mixin mobileToTablet {
      top: 50%;
      right: 50%;
      position: absolute;
    }
  }
`;

export default VerMasFacetStyle;
