import PropTypes from 'prop-types';
import React from 'react';
import { withApplicationContext } from '../../../../utils/ApplicationContext';
import _ from '../../../../utils/LodashImports';
import noop from '../../../../utils/noop';
import { ShippingBox } from './ShippingBox/ShippingBox';
import { shippingListStyle } from './ShippingList.style';

const ShippingList = (props) => {
  const { item, onFacetChange, appCtx } = props;
  const { deviceType } = appCtx;
  const SAME_DAY_DELIVERY_LABEL = _.get(
    appCtx,
    'siteConfig.textDictionary.SAME_DAY_DELIVERY_LABEL',
    'Si compras hasta las'
  );
  const SAME_DAY_DELIVERY_LABEL_AMOUNT = _.get(
    appCtx,
    'siteConfig.textDictionary.SAME_DAY_DELIVERY_LABEL_AMOUNT',
    '16:00 horas'
  );
  const SAME_DAY_DELIVERY_TIMELINE = _.get(appCtx, 'siteConfig.textDictionary.SAME_DAY_DELIVERY_TIMELINE', 'llega hoy');
  const HOME_SHIPPING = _.get(appCtx, 'siteConfig.textDictionary.HOME_SHIPPING', 'Envio Domiclio');
  const WITHDRAWAL_AT_STORE = _.get(appCtx, 'siteConfig.textDictionary.WITHDRAWAL_AT_STORE', 'Retiro en un punto');
  const WITHDRAW_AT_STORE_LABEL_AMOUNT = _.get(
    appCtx,
    'siteConfig.textDictionary.WITHDRAW_AT_STORE_LABEL_AMOUNT',
    '16:00 horas'
  );
  const WITHDRAWAL_AT_STORE_TIMELINE = _.get(
    appCtx,
    'siteConfig.textDictionary.WITHDRAWAL_AT_STORE_TIMELINE',
    'retira hoy'
  );
  const WITHDRAWAL_AT_STORE_LABEL = _.get(
    appCtx,
    'siteConfig.textDictionary.WITHDRAWAL_AT_STORE_LABEL',
    'Si compras hasta las'
  );

  const shippingMap = {
    hd: [],
    cc: [],
  };
  item.values.forEach((v) => {
    if (/.hd_/.test(v.facetType)) {
      shippingMap.hd.push(v);
    } else {
      shippingMap.cc.push(v);
    }
  });

  const showLabel = (shippingMapType) => {
    return shippingMapType.some((mapType) => mapType?.facetType?.includes('same_day'));
  };
  const showHDLabelDetails = showLabel(shippingMap.hd);
  const showCCLabelDetails = showLabel(shippingMap.cc);

  const renderShippingFacets = () => {
    return (
      <div>
        {!!shippingMap.hd.length && (
          <ShippingBox
            label={HOME_SHIPPING}
            labelDetails={SAME_DAY_DELIVERY_LABEL}
            labelAmount={SAME_DAY_DELIVERY_LABEL_AMOUNT}
            showLabel={showHDLabelDetails}
            shippingMap={shippingMap.hd}
            onFacetChange={onFacetChange}
            type="home_delivery"
            timeline={SAME_DAY_DELIVERY_TIMELINE}
          />
        )}
        {!!shippingMap.hd.length && !!shippingMap.cc.length && deviceType === 'desktop' && (
          <div className="divider-line" />
        )}
        {!!shippingMap.cc.length && (
          <ShippingBox
            label={WITHDRAWAL_AT_STORE}
            labelDetails={WITHDRAWAL_AT_STORE_LABEL}
            labelAmount={WITHDRAW_AT_STORE_LABEL_AMOUNT}
            showLabel={showCCLabelDetails}
            shippingMap={shippingMap.cc}
            onFacetChange={onFacetChange}
            type="withdraw_at_store"
            timeline={WITHDRAWAL_AT_STORE_TIMELINE}
          />
        )}
        <style jsx>{shippingListStyle}</style>
      </div>
    );
  };
  return renderShippingFacets();
};

ShippingList.defaultProps = {
  item: { values: [] },
  onFacetChange: noop,
  appCtx: {},
};

ShippingList.propTypes = {
  item: PropTypes.object,
  onFacetChange: PropTypes.func,
  appCtx: PropTypes.object,
};

export default withApplicationContext(ShippingList);
export { ShippingList };
