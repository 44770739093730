import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import noop from '../../../utils/noop';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withProduct } from '../../../utils/ProductContext';
import Drawer from '../../ui/Drawer/Drawer';
import { withLabels } from '../../../utils/LabelsContext';
import { SizeButtonStyles } from './SizeButtons.style';
import SizeButton from './SizeButton/SizeButton';
import SizeEquivalencies from './SizeEquivalencies/SizeEquivalencies';
import _ from '../../../utils/LodashImports';
import { withSizeInfo } from '../../../utils/SizeInfoContext';
import { withDigitalData } from '../../../utils/DigitalDataContext';
import DrawerTab from '../../ui/DrawerTab/DrawerTab';

const SizeButtons = ({
  product,
  appCtx,
  labels,
  sizes,
  selectedSize,
  onSizeChange,
  title,
  isSizeChartHidden,
  sizeInfo,
  digitalData,
}) => {
  const [isDrawerActive, activateDrawer] = useState(false);
  const [isSizeChartLoading, setSizeChartLoaderVisibility] = useState(true);
  const displaySizeChartBtnRef = useRef();
  const { sizeChart } = product.attributes;
  const { sizeChartName, sizeChartUrl } = sizeChart || {};
  const { regionCode } = appCtx;

  const guideSymbol = _.get(product, 'guideSymbol', false);
  const sizeEquivalencieToggle = _.get(appCtx, 'siteConfig.toggles.isSizeEquivalencyTableVisible', false);

  useEffect(() => {
    if (!displaySizeChartBtnRef.current) {
      return;
    }
    const sizeChartBtnInnerText = (displaySizeChartBtnRef.current.innerText || '').toLowerCase();
    if (sizeChartBtnInnerText.includes(labels.SIZE_CHART.toLowerCase())) {
      digitalData.pdp.setProductSizeTools('1');
    } else if (sizeChartBtnInnerText.includes('encuentra tu talla')) {
      digitalData.pdp.setProductSizeTools('2');
    }
  }, [displaySizeChartBtnRef]);

  const SizeEquivalenciesTable = () =>
    sizes.map(({ value, available }, index) => (
      <SizeEquivalencies
        value={value}
        sizeMap={sizeInfo && sizeInfo[value]}
        available={available}
        index={index}
        onSizeChange={onSizeChange}
        selectedSize={selectedSize}
        regionCode={regionCode}
      />
    ));

  const SizeButtonsTable = () =>
    sizes
      .sort((a, b) => {
        const valueA = a.value && a.value.split(' ');
        const valueB = b.value && b.value.split(' ');
        if (valueA && valueB && valueA.length > 1 && valueB.length > 1) {
          return a.value.split(' ')[0] - b.value.split(' ')[0];
        }
        return 0;
      })
      .map(({ value, available }, index) => (
        <SizeButton
          value={value}
          available={available}
          index={index}
          selectedSize={selectedSize}
          onSizeChange={onSizeChange}
        />
      ));

  const isActiveTabAssistentePersonal = _.get(appCtx, 'siteConfig.toggles.isActiveTabAssistentePersonal', false);

  const sizeDrawerTitle = _.get(labels, 'FIND_YOUR_SIZE', 'ENCUENTRA TU TALLAS');

  const tabHeadings = {
    0: _.get(labels, 'PERSONAL_ASSISTANT', 'Asistente personal'),
    1: _.get(labels, 'SIZE_GUIDE', 'Guía de tallas'),
  };

  return (
    <div className="size-container fa--sizes rebranded">
      <div className="title-container">
        <span className="size-title">{title}</span>
        {sizeChartName && !isSizeChartHidden && (
          <span
            id="testId-sizeChart-table-btn"
            className="sizechart-link fa--size-chart-link"
            onKeyDown={noop}
            role="button"
            tabIndex={0}
            onClick={() => {
              activateDrawer(true);
            }}
            ref={displaySizeChartBtnRef}
          >
            <i className={`${guideSymbol ? 'talla-pie' : 'csicon-scale'}`} />
            <span>{labels.SIZE_CHART}</span>
            <i className="csicon-arrow_right" />
          </span>
        )}
      </div>
      <div className="size-options">
        {sizeInfo && sizeEquivalencieToggle ? <SizeEquivalenciesTable /> : <SizeButtonsTable />}
      </div>
      {sizeChartName && sizeChartUrl && (
        <Drawer
          toggleState={[isDrawerActive, activateDrawer]}
          heading={sizeDrawerTitle}
          isLoading={isSizeChartLoading}
          onOpen={() => {
            if (isActiveTabAssistentePersonal) {
              setSizeChartLoaderVisibility(false);
            } else {
              setSizeChartLoaderVisibility(true);
            }
          }}
        >
          <DrawerTab
            onOpenSizeChart={() => setSizeChartLoaderVisibility(true)}
            onLoad={() => {
              setSizeChartLoaderVisibility(false);
            }}
            sizeChartName={sizeChartName}
            sizeChartUrl={sizeChartUrl}
            regionCode={regionCode}
            isActiveTabAssistentePersonal={isActiveTabAssistentePersonal}
            tabHeadings={tabHeadings}
          />
        </Drawer>
      )}
      <style jsx>{SizeButtonStyles}</style>
    </div>
  );
};

SizeButtons.defaultProps = {
  sizes: [],
  onSizeChange: noop,
  title: 'Selection Talla',
  selectedSize: undefined,
  isSizeChartHidden: false,
  sizeInfo: undefined,
};

SizeButtons.propTypes = {
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  sizes: PropTypes.arrayOf(PropTypes.object),
  onSizeChange: PropTypes.func,
  title: PropTypes.string,
  selectedSize: PropTypes.string,
  isSizeChartHidden: PropTypes.bool,
  sizeInfo: PropTypes.object,
  digitalData: PropTypes.object.isRequired,
};

export default withSizeInfo(withProduct(withLabels(withApplicationContext(withDigitalData(SizeButtons)))));
export { SizeButtons };
