import React from 'react';
import PropTypes from 'prop-types';
import VerMasButtonStyle from './VerMasButton.style';
import Button from '../../../ui/Button/Button';

const VerMasButton = ({ onClick, hidden, label }) => {
  if (hidden) return null;

  return (
    <div className="verMas-button-container">
      <Button type="secondary-link-mkp" isHighlighted onClick={onClick}>
        {label}
      </Button>
      <style jsx>{VerMasButtonStyle}</style>
    </div>
  );
};

VerMasButton.propTypes = {
  hidden: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

VerMasButton.defaultProps = {
  hidden: false,
};

export { VerMasButton };
export default VerMasButton;
