import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';

const TypeCProductMobileStyles = css`
  .typeCProduct {
    margin-top: 26px;
    border-bottom: 1px solid ${colors.gray.shade3};
    display: flex;
    padding-bottom: 30px;

    &-variants {
      :global(.availability-options) {
        width: 100px;
        margin-right: 16px;
        margin-top: 4px;

        @mixin smallMobileOnly {
          margin-right: 31px;
        }
      }
      &-select {
        padding-left: 30px;
        &.mkp {
          @mixin smallerMobile {
            padding-left: 7px;
          }
        }
      }
    }

    .addToCart-button {
      margin-top: 25px;
      &.mkp {
        width: 199px;
      }
      &-text {
        font-size: ${typography.sm1};
        letter-spacing: 1.08px;
        line-height: 16px;
        text-transform: uppercase;
        font-weight: bold;
        padding: 0 1.1em;
        width: 100%;
        display: block;
      }
      &.mkp span {
        font-size: ${typography.lg1};
        font-weight: 400;
        letter-spacing: 0.5px;
        line-height: 19px;
        text-transform: inherit;
        padding: 0;
      }
    }
  }
  .colorName {
    font-size: ${typography.lg1};
    color: ${colors.black.shade1};
    margin-bottom: 8px;
    .colorLabel {
      font-weight: 700;
    }
  }
`;

const TypeCMobileCartStyles = css`
  .typeCProduct.cart {
    display: flex;
    margin-top: 30px;
    border-bottom: 1px solid ${colors.gray.shade50};
    padding-bottom: 30px;

    .variant-image {
      min-width: 90px;
      width: 90px;
      margin-right: 20px;
    }

    .variant-details {
      .brandName {
        display: block;
        height: 12px;
        color: ${colors.black.shade7};
        font-weight: 700;
        letter-spacing: 0.73px;
        line-height: 12px;
        margin-bottom: 3px;
      }

      .variantName {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-height: 19.2px;
      }

      .color-swatch-container {
        margin-top: 18px;
      }

      .addToCart-button {
        width: 182px;

        span {
          font-weight: bold;
          letter-spacing: 0.92px;
          line-height: 14px;
          text-align: center;
        }
        &.mkp span {
          font-weight: 400;
          letter-spacing: 0.5px;
          line-height: 19px;
        }
      }

      .dropdown {
        width: 200px;
        margin-bottom: 30px;
        :global(.menu-button) {
          font-weight: 700;
        }
      }
    }
  }
`;

export { TypeCProductMobileStyles, TypeCMobileCartStyles };
