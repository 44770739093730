import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { getImages } from '../../../utils/GetImages';
import _ from '../../../utils/LodashImports';
import httpService from '../../../utils/httpService';
import { imageUrl as imageURLMaker } from '../../../utils/staticUrls';
import ImageStyles from './Image.style';

const Image = ({
  id,
  width,
  height,
  alt,
  lazyLoad,
  appCtx,
  quality,
  useBasePath, // TOBE Deprecated
  useSrcSet,
  crop,
  hideLazyLoadPlaceHolder,
  inlineDimensions,
  imageUrl,
}) => {
  const { regionCode, store } = appCtx;

  const variantId = id.includes('/') ? id.split('/')[1] : id;

  const isImageDeliveryOverrideEnabled = _.get(appCtx, 'siteConfig.toggles.isImageDeliveryOverrideEnabled', false);

  const imageSrc = getImages({
    variantId,
    regionCode,
    useBasePath,
    attr: {
      width,
      height,
      crop,
      quality,
    },
    url: imageUrl,
    isImageDeliveryOverrideEnabled,
    store,
  });

  const imageSrcSet = useSrcSet
    ? `${getImages({
        variantId,
        regionCode,
        useBasePath,
        attr: {
          width: width * 2,
          height: height * 2,
          crop,
          quality,
        },
        url: imageUrl,
        isImageDeliveryOverrideEnabled,
        store,
      })} 2x`
    : '';

  const [src, setSrc] = useState(imageSrc);
  const [srcSet, setSrcSet] = useState(imageSrcSet);
  const [imageHasError, setImageHasError] = useState(false);
  const defaultImage = getImages({
    variantId: 'default',
    regionCode,
    useBasePath,
    attr: {
      width,
      height,
      crop,
      quality,
    },
    url: '',
    isImageDeliveryOverrideEnabled,
    store,
  });

  useEffect(() => {
    setSrc(imageSrc);
    setSrcSet(imageSrcSet);
  }, [variantId, imageUrl]);

  useEffect(() => {
    if (/(localhost|local.falabella)/g.test(window.location.origin) || src.includes('rackcdn.com')) {
      return;
    }
    httpService(appCtx)
      .getImage(src)
      .then((res) => {
        if (res && res.statusCode !== 200) throw new Error();
        setImageHasError(false);
      })
      .catch(() => {
        setImageHasError(true);
      });
  }, []);
  const addDefaultSrc = () => {
    setSrcSet('');
    if (imageHasError) {
      setSrc(imageURLMaker('defaultImage.jpg'));
      return;
    }
    setSrc(defaultImage);
  };

  const getImage = () => {
    return (
      <>
        <img
          src={!imageHasError ? src : imageURLMaker('defaultImage.jpg')}
          srcSet={srcSet}
          alt={alt}
          id={`testId-pod-image-${id}`}
          onError={addDefaultSrc}
          width={inlineDimensions ? width : undefined}
          height={inlineDimensions ? height : undefined}
        />
        <style jsx>{ImageStyles}</style>
      </>
    );
  };

  return lazyLoad ? (
    <LazyLoad
      placeholder={
        !hideLazyLoadPlaceHolder && (
          <div
            className="place-holder"
            style={{
              height,
            }}
          />
        )
      }
    >
      {getImage()}
    </LazyLoad>
  ) : (
    getImage()
  );
};

Image.propTypes = {
  id: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  alt: PropTypes.string,
  lazyLoad: PropTypes.bool,
  appCtx: PropTypes.object.isRequired,
  quality: PropTypes.number,
  useBasePath: PropTypes.bool,
  useSrcSet: PropTypes.bool,
  crop: PropTypes.object,
  hideLazyLoadPlaceHolder: PropTypes.bool,
  inlineDimensions: PropTypes.bool,
  imageUrl: PropTypes.string,
};

Image.defaultProps = {
  alt: '',
  id: '',
  lazyLoad: false,
  quality: 70,
  useBasePath: false,
  useSrcSet: true,
  crop: undefined,
  hideLazyLoadPlaceHolder: false,
  inlineDimensions: true,
  imageUrl: '',
};

export default withApplicationContext(Image);
export { Image };
