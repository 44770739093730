import _ from '../../LodashImports';
import { CURRENCY_MAPPER, formatCommercePrize, formatNumberToPrice } from '../../PriceHelpers';

const getCartLineId = (serviceOption, childProducts = []) => {
  const service = childProducts.find((item) => item.variantId === serviceOption.variantId);
  return service && service.cartLineId;
};

const mapWarrantyOptions = (warranty, childProducts) => {
  const options = _.get(warranty, 'fieldOptions', []);
  const extendedWarrantyLink = _.get(warranty, 'extendedWarrantyLink', '');
  if (options.length === 0) {
    return undefined;
  }

  let cartLineId = '';
  const mappedFieldOptions = options.map((option) => {
    const id = getCartLineId(option, childProducts);
    if (id) {
      cartLineId = id;
    }
    return {
      ...option,
      label: option.label
        ? `${option.label} ${CURRENCY_MAPPER[option.price.currency] || ''} ${formatNumberToPrice(
            option.price.centAmount / option.price.fraction
          )}`
        : 'No, gracias',
      selected: option.selected,
    };
  });

  return {
    defaultText: 'No, gracias',
    fieldName: 'Warranty',
    fieldType: 3,
    label: 'Garantía Extendida',
    fieldOptions: mappedFieldOptions,
    extendedWarrantyLink,
    cartLineId,
  };
};

const mapAssemblyOptions = (serviceOptions, childProducts) => {
  const options = _.get(serviceOptions, 'fieldOptions', []);

  if (options.length === 0) {
    return undefined;
  }

  const mappedFieldOptions = options
    .filter((option) => option.label)
    .map((option) => {
      let cartLineId = '';
      const id = getCartLineId(option, childProducts);
      if (id) {
        cartLineId = id;
      }
      const mappedOption = {
        ...option,
        label: `${option.label} ${CURRENCY_MAPPER[option.price.currency] || ''} ${formatNumberToPrice(
          option.price.centAmount / option.price.fraction
        )}`,
      };
      if (option.label) {
        mappedOption.cartLineId = cartLineId;
      }
      return mappedOption;
    });

  return {
    defaultText: 'No, gracias',
    fieldName: '2',
    label: 'Selecciona servicios adicionales',
    fieldOptions: mappedFieldOptions,
  };
};

const mapProduct = (item, tenant, textDictionary, toggles) => {
  const {
    brandName,
    imageUrl,
    name,
    prices,
    productId,
    variantId,
    quantity: qty,
    pdpUrl: link,
    warrantyOptions: warranty,
    serviceOptions,
    cartLineId,
    childProducts,
    offeringId,
    qtyLimits,
  } = item;

  return {
    brandName,
    image: imageUrl,
    name,
    price: formatCommercePrize(prices, tenant, textDictionary, toggles),
    productId,
    skuId: variantId,
    quantity: Number(qty.quantityNumber),
    url: link,
    warrantyOptions: mapWarrantyOptions(warranty, childProducts),
    assemblyOptions: mapAssemblyOptions(serviceOptions, childProducts),
    totalQuantity: Number(qty.quantityNumber),
    commerceItemId: '',
    cartLineId,
    childProducts,
    offeringId,
    qtyLimits,
  };
};

export default mapProduct;
