import { isTottusStandalone } from '@digital-retail/store-manager';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withApplicationContext } from '../../utils/ApplicationContext';
import _ from '../../utils/LodashImports';
import { withProduct } from '../../utils/ProductContext';
import { CartSection } from '../Cart/Cart';
import AddToCartError from '../ui/AddToCartError/AddToCartError';
import { Modal } from '../ui/Modal/Modal';
import ProductVariations from './ProductVariations/ProductVariations';

const ProductOverlay = ({
  onModalCloseHandler,
  labels,
  product,
  appCtx,
  isAddToCartInProgress,
  addProductToCart,
  cart,
  hasAddToCartError,
  isAddToCartSuccessful,
  show401Error,
  addToCartErrorCode,
}) => {
  let fullScreen = appCtx.deviceType !== 'desktop';
  if (hasAddToCartError && appCtx.deviceType !== 'desktop') {
    fullScreen = false;
  }
  const [hasCrossSelling, setCrossSelling] = useState();
  useEffect(() => {
    if (!cart.showCart) {
      onModalCloseHandler();
    }
  }, [cart.showCart]);

  const { totalProductsCount } = cart;

  useEffect(() => {
    if (typeof totalProductsCount !== 'undefined') {
      appCtx.changeCartCount(totalProductsCount);
    }
  }, [totalProductsCount]);

  const getDesktopOptions = () => {
    let width;
    if (hasAddToCartError) {
      width = 500;
    } else {
      width = 720;
    }
    return {
      width,
      height: 'auto',
      overflow: 'hidden',
      disableOutsideClick: true,
      minHeight: hasAddToCartError ? 251 : 300,
    };
  };

  const getMobileOptions = () => {
    let width;
    let height;
    if (hasAddToCartError) {
      height = 264;
      width = 335;
    } else {
      height = '100vh';
      width = '100vw';
    }
    return {
      width,
      height,
      overflow: 'hidden',
      disableOutsideClick: true,
    };
  };
  const hasSizes =
    _.get(product, 'variants[1].options', []).length || _.get(product, 'variants[0].options[0].sizes', []).length;

  const content = (actions) => {
    if (hasAddToCartError) {
      return (
        <AddToCartError
          labels={labels}
          onModalCloseHandler={onModalCloseHandler}
          show401Error={show401Error}
          setShowLoginForm={cart.setShowLoginForm}
          addToCartErrorCode={addToCartErrorCode}
        />
      );
    }
    if (isAddToCartSuccessful) {
      return (
        <CartSection
          setBusy={actions.setBusy}
          cart={cart}
          hasCrossSelling={hasCrossSelling}
          setCrossSelling={setCrossSelling}
          productOverlayContext={product}
        />
      );
    }
    if (hasSizes) {
      return (
        <ProductVariations
          labels={labels}
          appCtx={appCtx}
          productOverlayContext={product}
          onModalCloseHandler={onModalCloseHandler}
          isAddToCartInProgress={isAddToCartInProgress}
          addProductToCart={addProductToCart}
        />
      );
    }
    return null;
  };
  if (isTottusStandalone(appCtx.store) && !hasAddToCartError) {
    return null;
  }
  if (hasAddToCartError || isAddToCartSuccessful || hasSizes)
    return (
      <Modal
        className={appCtx.deviceType === 'mobile' && hasAddToCartError ? 'error-modal' : null}
        withPadding={false}
        showCloseButton={false}
        content={content}
        toggleState={{ setVisible: cart.setShowCart, visible: cart.showCart }}
        fullScreen={fullScreen}
        options={{
          ...(appCtx.deviceType === 'desktop' ? getDesktopOptions() : getMobileOptions()),
        }}
        deviceType={appCtx.deviceType}
        stopScroll
      />
    );

  return null;
};

ProductOverlay.defaultProps = {
  onModalCloseHandler: () => {},
  labels: {},
  show401Error: false,
  addToCartErrorCode: '',
};

ProductOverlay.propTypes = {
  onModalCloseHandler: PropTypes.func,
  labels: PropTypes.object,
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  isAddToCartSuccessful: PropTypes.bool.isRequired,
  isAddToCartInProgress: PropTypes.bool.isRequired,
  addProductToCart: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired,
  hasAddToCartError: PropTypes.bool.isRequired,
  show401Error: PropTypes.bool,
  addToCartErrorCode: PropTypes.string,
};

export default withApplicationContext(withProduct(ProductOverlay));
