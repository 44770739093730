import css from 'styled-jsx/css';
import colors from '../../../config/styles/colors';

const styles = css`
  /* stylelint-disable declaration-no-important, length-zero-no-unit */
  .pods {
    align-items: center;
    background-color: #e9edf1;
    border: 1px solid;
    border-color: #bfc4c9;
    border-radius: 4px;
    display: inline-flex;
    gap: 4px;
    justify-content: center;
    left: 0;
    padding: 8px;
    position: relative;
    top: 0;
    margin: 3px;
    min-height: 27px;
    @mixin mobileToTablet {
      background-color: #fff;
    }
  }
  /* stylelint-disable font-family-no-missing-generic-family-keyword */
  .facet-with-mini-pods {
    color: #333;
    font-family: 'Lato';
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.4px;
    line-height: 15px;
    margin-bottom: -1px;
    margin-top: -3px;
    position: relative;
    white-space: inline;
    width: fit-content;
  }
  .pods-container {
    max-height: 244px;
    overflow-y: auto;
    margin: -4px 0 0 -3px;
    padding-bottom: 5px;
    @mixin mobileToTablet {
      margin: unset;
      max-height: 385px;
      padding-top: 12px;
      padding-left: 10px;
      background-color: ${colors.gray.shade12};
    }
  }
`;

export default styles;
