import css from 'styled-jsx/css';
import { colors, icons, typography } from '../../../config/styles';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

const accordionStyle = css`
  .accordion {
    background-color: ${colors.white.shade1};
    max-width: 100vw;
    @mixin mobileToTablet {
      margin-bottom: 4rem;
      position: relative;
    }
  }
`;

const accordionItemStyle = css`
  li {
    padding: 6px 0;
    list-style-type: none;
    .csicon-shield {
      font-size: 24px;
    }
    @mixin mobileToTablet {
      border-bottom: 1px solid ${colors.gray.shade36};
      border-radius: 3px;
      padding: 0;
    }
    &.withBorder {
      border-top: 1px solid ${colors.gray.shade36};
      border-bottom: 0;
      border-radius: 3px;
      background-color: ${colors.gray.shade12};
      padding-top: 8px;
      padding-bottom: 0;
      &.bottomPadding {
        padding-bottom: 8px;
      }
      @mixin tabletToDesktop {
        border-top: 1.1px solid ${colors.gray.shade36};
        border-bottom: 1.1px solid ${colors.gray.shade36};
      }
      &.isCart {
        border: none;
      }
    }
    &.withBorderBottom {
      position: relative;
      &::after {
        content: '';
        display: flex;
        position: absolute;
        bottom: 0;
        left: -14px;
        width: calc(100% + 14px);
        border-bottom: 1px solid ${colors.green.shade1};
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 8px 13px 8px 0;
      @mixin mobileToTablet {
        padding: 10px 13px 10px 15px;
      }
      .container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .logo {
          color: ${colors.green.shade1};
          text-align: center;
          font-size: ${typography.xl2b};
          &.rebranded {
            color: ${colors.gray.shade45};
            .csicon-disponibilidad {
              @mixin mobileToTablet {
                font-size: 2.7rem;
              }
            }
            .csicon-preferences {
              @mixin mobileToTablet {
                background-image: url('${svgToDataUri(icons.servicesIconRebranded)}');
                width: 27px;
                height: 26px;
                display: inline-block;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                position: relative;
                top: 3px;
                left: 1px;
                &::before {
                  content: '';
                }
              }
            }
            .csicon-shield {
              @mixin mobileToTablet {
                font-size: 2.8rem;
              }
            }
            .csicon-calculator {
              @mixin mobileToTablet {
                font-size: 2.5rem;
              }
            }
          }

          &.withPadding {
            padding-right: 14px;
            padding-left: 22px;
          }
          &.withFixedWidth {
            min-width: 80px;
            width: 80px;
          }
        }
        .headingContainer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          > * {
            text-align: left;
          }
          &.flexRow {
            flex-direction: row;
            align-items: center;
          }
        }
      }
    }
    .shipping {
      padding-bottom: 4px;
    }
    .icon {
      font-size: ${typography.xxs};
      display: flex;
    }
    div[hidden] {
      display: hidden;
    }
    /* stylelint-disable font-family-no-missing-generic-family-keyword, declaration-block-no-duplicate-properties  */
    .mkp-count {
      background: ${colors.gray.shade56};
      border-radius: 15px;
      color: ${colors.black.shade3};
      width: 20px;
      height: 20px;
      text-align: center;
      padding: 1px 0;
      margin-right: 5px;
      margin-left: 5px;
      font-size: 1.2rem;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
`;

export { accordionStyle, accordionItemStyle };
