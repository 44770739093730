import { useEffect } from 'react';

const useOnScroll = ({ handler, effectListener }) => {
  useEffect(() => {
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, [effectListener]);
};

export { useOnScroll };
