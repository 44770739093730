import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import apiConfig from '../../../config/api/apiConfig';
import constants from '../../../config/constants';
import { RouterContext } from '../../../containers/Layout/RouterContext';
import {
  addToCart as DDAddToCart,
  removeFromCart as DDRemoveFromCart,
} from '../../../external_dependencies/digitalData/cart';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withCart } from '../../../utils/CartContext';
import { withLabels } from '../../../utils/LabelsContext';
import _ from '../../../utils/LodashImports';
import { withProduct } from '../../../utils/ProductContext';
import { getErrorMessage } from '../../../utils/errorHandler';
import { allowOnlyNumbers } from '../../../utils/jsUtils';
import noop from '../../../utils/noop';
import Prices from '../../Prices/Prices';
import Warning from '../../Warning/Warning';
import BodyCopy from '../../ui/BodyCopy/BodyCopy';
import Button from '../../ui/Button/Button';
import { ProductCounter } from '../../ui/ProductCounter/ProductCounter';
import SingleChipletSelect from '../../ui/SingleChipletSelect/SingleChipletSelect';
import WarrantySelect from '../../ui/WarrantySelect/WarrantySelect';
import { CartItemStyles } from './CartItem.style';

const getDefaultSelectedIndex = (options = []) => {
  for (let i = 0; i < options.length; i += 1) {
    if (options[i].selected) {
      return i;
    }
  }
  return -1;
};

const getSecondaryLabel = (fieldName, textDictionary) => {
  switch (fieldName) {
    case 'Warranty': {
      return textDictionary.extendedWarrantyLightBoxLinkText;
    }
    case '2': {
      return textDictionary.CART_ASSEMBLY_OPTION_SEC_LABEL;
    }
    default: {
      return '';
    }
  }
};

const getLink = (fieldName, endPoints, regionCode) => {
  switch (fieldName) {
    case 'Warranty': {
      return apiConfig.formatRelativeUrl(endPoints.extendedWarrantyLightBoxLink.path, regionCode);
    }
    default: {
      return '';
    }
  }
};

const getViewConfig = (deviceType) => {
  const dt = !deviceType || deviceType === 'tablet' ? 'mobile' : deviceType;
  const config = {
    desktop: {
      brandName: {
        size: 'title17',
        type: 'secondary',
      },
      description: {
        size: 'title19',
        type: 'primary',
      },
      price: 'CART',
    },
    mobile: {
      brandName: {
        size: 'title18',
        type: 'secondary',
      },
      description: {
        size: 'title20',
        type: 'primary',
      },
      price: 'CART',
    },
  };
  return config[dt];
};

const inPDP = (context, appCtx) => {
  const productRegex = /product/i;
  if (!context || !context.router) {
    const path = appCtx.url;
    return productRegex.test(path);
  }
  const { pathname } = context.router;
  return productRegex.test(pathname);
};

const CartItem = ({
  item,
  textDictionary,
  onAddToCart,
  onRemoveFromCart,
  setWarrantyOption,
  setAssemblyOption,
  showWarrantyOption,
  setBusy,
  labels,
  showButton,
  appCtx,
  type,
  endPoints,
  showAssemblyOption,
  product,
  setCrossSelling,
  variantDetails,
  disableQuantitySelection,
  setWarrantyOptionCurrent,
  limitAlerts,
  cart,
}) => {
  const {
    brandName,
    name,
    skuId,
    image,
    productId,
    offeringId,
    price,
    totalQuantity,
    commerceItemId,
    warrantyOptions,
    assemblyOptions,
    addedQuantity,
    cartLineId = '',
    childProducts = [],
  } = item;
  const [showMaxQuantityAlert, setShowMaxQuantityAlert] = useState(false);
  const [errorAddingToCart, setErrorAddingToCart] = useState('');
  const [errorWarranty, setErrorWarranty] = useState(false);
  const [errorServices, setErrorServices] = useState(false);
  const [limitFromAPI, setLimitFromAPI] = useState(false);
  const [inputVal, setInputVal] = useState(totalQuantity);
  const routerContext = useContext(RouterContext.Context);
  const { deviceType, regionCode } = appCtx;
  const isQuantityEditableEnabled = _.get(appCtx, 'siteConfig.toggles.isQuantityEditableEnabled', false);

  const isNewWarrantyUIEnabled = _.get(appCtx, 'siteConfig.toggles.isNewWarrantyUIEnabled', false);
  const isNewPriceApplicable = _.get(appCtx, 'siteConfig.toggles.isNewPriceApplicable', false);
  const isAddtionalInfoForSodimacEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isAddtionalInfoForSodimacEnabledInUI',
    false
  );
  const isSodimacProduct = constants.SODIMAC_REGEX.test(
    _.get(product, 'variants[0].offerings[0].sellerName', '').toLowerCase()
  );
  const timeputForLoginModalOn401 = _.get(appCtx, 'siteConfig.textDictionary.TIMEOUT_FOR_LOGIN_MODAL', 1000);

  const inPdp = inPDP(routerContext, appCtx);
  const inPageText = inPdp ? 'inPDP' : 'inXLP';

  const shouldRenderAdditionalInfo = () => {
    if (isSodimacProduct) {
      if (isAddtionalInfoForSodimacEnabled) {
        return true;
      }
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (limitAlerts && (limitAlerts[cartLineId] || limitAlerts.basket)) {
      setLimitFromAPI(true);
      if (limitAlerts[cartLineId]) {
        setErrorAddingToCart(limitAlerts[cartLineId].message);
      } else {
        setErrorAddingToCart(limitAlerts.basket.message);
      }
    } else {
      setLimitFromAPI(false);
    }
  }, [limitAlerts]);
  useEffect(() => {
    setInputVal(totalQuantity);
    const eventToDigitalData = new CustomEvent('DDPDPUpdateSelectedProductCount', {
      bubbles: true,
      detail: totalQuantity,
    });
    window.dispatchEvent(eventToDigitalData);
  }, [totalQuantity]);

  const warrantyTooltip =
    product.warrantyOptions && product.warrantyOptions.warrantyTooltip
      ? product.warrantyOptions.warrantyTooltip
      : undefined;
  const isWarrantyTooltipEnabled = _.get(appCtx, 'siteConfig.toggles.isWarrantyTooltipEnabled', false);
  const cheapestWarranty =
    product.warrantyOptions &&
    product.warrantyOptions.fieldOptions &&
    Array.isArray(product.warrantyOptions.fieldOptions) &&
    product.warrantyOptions.fieldOptions.length &&
    product.warrantyOptions.fieldOptions[0]
      ? product.warrantyOptions.fieldOptions[0].price
      : '';

  const getMaximumLimit = (qty, maxOnCartAmount) => {
    const max =
      (typeof qty === 'number' && qty <= (maxOnCartAmount || constants.MAX_CART_PRODUCT) && maxOnCartAmount) ||
      constants.MAX_CART_PRODUCT;

    return max;
  };

  const handleWarrantyOptionsSelected = (option) => {
    const value = option ? option.variantId || option.value : '';
    const warrantyOfferingId = _.get(option, 'offeringId', '');
    setBusy(true);
    setErrorWarranty(false);
    setWarrantyOptionCurrent({ ...option, selected: true });
    setWarrantyOption({
      appCtx,
      item: { commerceItemId, skuId: value },
      productContext: product,
      variantId: skuId,
      setWarrantyOptionCurrent,
      cartLineId: warrantyOptions.cartLineId || '',
      totalQuantity,
      offeringId: warrantyOfferingId,
    }).then((res) => {
      setBusy(false);
      if (res) {
        setErrorWarranty(true);
      }
    });
  };

  const handleAssemblyOptionSelected = (options, index) => {
    const option = _.get(assemblyOptions, `fieldOptions[${index}]`, {});
    const value = option ? option.variantId || option.value : '';
    const assemblyOfferingId = _.get(option, 'offeringId', '');
    setBusy(true);
    setErrorServices(false);
    setAssemblyOption({
      appCtx,
      item: { commerceItemId, skuId: value, type: 2 },
      productContext: product,
      variantId: skuId,
      cartLineId: option.cartLineId || '',
      totalQuantity,
      offeringId: assemblyOfferingId,
    }).then((res) => {
      setBusy(false);
      if (res) {
        setErrorServices(true);
      }
    });
  };

  const handleAddItem = (currQuantity = 1, isClickedByUser = false) => {
    const maximumLimit = getMaximumLimit(product.stockUnits[skuId], product.maxOnCartAmount);
    if (totalQuantity === maximumLimit) {
      setShowMaxQuantityAlert(true);
      return;
    }
    setBusy(true);
    const option = {
      appCtx,
      offeringId,
    };

    if (product.isSLP) {
      option.cb = (data) => {
        DDAddToCart(data.cartLines[0], currQuantity);
      };
    }

    const selectedWarranty =
      warrantyOptions && warrantyOptions.cartLineId
        ? childProducts.find((cp) => cp.cartLineId === warrantyOptions.cartLineId)
        : {};

    const selectedService =
      assemblyOptions && assemblyOptions.cartLineId && totalQuantity > 0
        ? childProducts.find((cp) => cp.cartLineId === assemblyOptions.cartLineId)
        : {};

    const warrantySkuId = _.get(selectedWarranty, 'variantId', '');
    const warrantyOfferingId = _.get(selectedWarranty, 'offeringId', '');
    const serviceSkuId = _.get(selectedService, 'variantId', '');
    const serviceOfferingId = _.get(selectedService, 'offeringId', '');
    const isMaxLimitExceeded = inputVal > maximumLimit;
    if (type === 'bundle') {
      option.bundledProducts = [{ skuId, productId, quantity: 1, offeringId }];
    } else {
      option.products = [
        {
          skuId,
          productId,
          quantity: inputVal < maximumLimit ? currQuantity : maximumLimit - totalQuantity,
          warrantySkuId,
          warrantyOfferingId,
          serviceSkuId,
          serviceOfferingId,
          offeringId,
        },
      ];
    }
    setErrorAddingToCart('');
    onAddToCart(option).then((res) => {
      if (res) {
        const errorMessage = getErrorMessage(res);
        const errorCode = _.get(res, '[0].code', '');
        if (errorCode === constants.ATC_SESSION_EXPIRED_CODE) {
          setTimeout(() => {
            cart.setShowCart(false);
            cart.setShowLoginForm(true);
          }, timeputForLoginModalOn401);
        }
        setErrorAddingToCart(errorMessage);
      }
      if (isClickedByUser && !res) {
        const eventToDigitalData = new CustomEvent('DDPDPAddQuantitySelector', {
          bubbles: true,
          detail: {
            maxCartQuantity: product.maxOnCartAmount === inputVal + 1 ? product.maxOnCartAmount : null,
            value: inputVal + 1,
            cart: true,
          },
        });
        window.dispatchEvent(eventToDigitalData);
      }
      if (isMaxLimitExceeded) {
        setShowMaxQuantityAlert(true);
        setInputVal(maximumLimit);
      }
      setBusy(false);
    });
  };

  const handleRemoveItem = (
    currQuantity = 1,
    disableServicesAndWarranties = false,
    cb = undefined,
    isClickedByUser = false
  ) => {
    setShowMaxQuantityAlert(false);
    setBusy(true);
    const option = {
      appCtx,
    };

    if (product.isSLP) {
      option.cb = (data) => {
        DDRemoveFromCart(data.cartLines[0], currQuantity);
      };
    }

    const warrantycartLineId = disableServicesAndWarranties ? '' : _.get(warrantyOptions, 'cartLineId', '');
    const servicecartLineId = disableServicesAndWarranties ? '' : _.get(assemblyOptions, 'cartLineId', '');

    if (type === 'bundle') {
      option.bundledProducts = [
        {
          cartLineId,
          totalQuantity: Number(totalQuantity),
          commerceItemId,
          quantity: currQuantity,
        },
      ];
    } else {
      option.products = [
        {
          cartLineId,
          totalQuantity: Number(totalQuantity),
          commerceItemId,
          quantity: currQuantity,
          warrantycartLineId,
          servicecartLineId,
        },
      ];
    }
    setErrorAddingToCart('');
    onRemoveFromCart(option).then((res) => {
      setBusy(false);
      if (!res && isClickedByUser) {
        const eventToDigitalData = new CustomEvent('DDPDPRemoveQuantitySelector', {
          bubbles: true,
          detail: {
            value: inputVal - 1,
            cart: true,
          },
        });
        window.dispatchEvent(eventToDigitalData);
      }
      if (res) {
        const errorMessage = getErrorMessage(res);
        const errorCode = _.get(res, '[0].code', '');
        if (errorCode === constants.ATC_SESSION_EXPIRED_CODE) {
          setTimeout(() => {
            cart.setShowCart(false);
            cart.setShowLoginForm(true);
          }, timeputForLoginModalOn401);
        }
        setErrorAddingToCart(errorMessage);
      } else if (cb) {
        cb();
      }
    });
  };
  const viewConfig = getViewConfig(deviceType);

  const showCartServicesSection = () => {
    return (showWarrantyOption && warrantyOptions) || (showAssemblyOption && assemblyOptions);
  };

  if (showCartServicesSection()) {
    setCrossSelling(true);
  }

  const getMaxForStepper = () => {
    if (limitFromAPI) {
      return totalQuantity;
    }
    const maximumLimit = getMaximumLimit(product.stockUnits[skuId], product.maxOnCartAmount);
    return showMaxQuantityAlert ? maximumLimit : maximumLimit + 1;
  };

  const getMaxForProductCounter = () => {
    if (limitFromAPI) {
      return totalQuantity;
    }
    const maximumLimit = getMaximumLimit(product.stockUnits[skuId], product.maxOnCartAmount);
    return maximumLimit;
  };

  const getCartItemActionLabel = () => {
    if (showButton && totalQuantity < 0) {
      return (
        <div className="addToCart-button">
          <Button size="medium" type="secondary" onClick={handleAddItem}>
            <span>{labels.ADD_TO_CART_TEXT}</span>
          </Button>
          <style jsx>{CartItemStyles}</style>
        </div>
      );
    }
    if (showMaxQuantityAlert) {
      const maxQuantityWarning = _.get(
        labels,
        'MAXIMUM_QUANTITY_LIMIT_LABEL',
        'Solo puedes llevar {maxCartQuantity} unidades'
      ).replace('{maxCartQuantity}', product.maxOnCartAmount);
      return (
        <div className="maxQuantity-alert">
          <Warning label={maxQuantityWarning} iconSize="copy2" labelSize="copy5" type="secondary" />
          <style jsx>{CartItemStyles}</style>
        </div>
      );
    }
    if (errorAddingToCart) {
      return (
        <div className="maxQuantity-alert">
          <Warning label={errorAddingToCart} iconSize="copy2" labelSize="copy5" />
          <style jsx>{CartItemStyles}</style>
        </div>
      );
    }
    if (totalQuantity !== 0 && addedQuantity > 0) {
      return (
        <div className="product-add-text">
          <i className="csicon-icon-success-circle mkp">
            <span className="sr-only">{labels.ITEM_ADDED_A11Y_LABEL}</span>
          </i>
          <BodyCopy size={appCtx.deviceType === 'desktop' ? 'copy3' : 'copy14'}>
            {_.get(labels, 'PRODUCT_ADDED_MKP', 'Producto agregado')}
          </BodyCopy>
          <style jsx>{CartItemStyles}</style>
        </div>
      );
    }
    const maxQuantityText = _.get(labels, 'MAX_QUANTITY_TEXT_PDP', 'Máximo {maxCartQuantity} unidades.').replace(
      '{maxCartQuantity}',
      product.maxOnCartAmount
    );
    return (
      <>
        <p className="max-units-text">{maxQuantityText}</p>
        <style jsx>{CartItemStyles}</style>
      </>
    );
  };
  const isDesktop = deviceType === 'desktop';
  const isMobile = !isDesktop;
  const mobileStyle = isDesktop ? { width: '100%' } : {};
  const mobileClass = isDesktop ? '' : 'mobile';
  const supportedKeyValues = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete'];

  const handleInputChange = (event, keyPressed) => {
    const { target } = event;
    let { value } = target;

    if (Number.isNaN(parseInt(value, 10))) {
      if (!inputVal || value.trim() === '') {
        setInputVal(undefined);
        target.value = '';
      } else {
        target.value = value.substring(0, value.length - 1);
      }
      return;
    }

    value = allowOnlyNumbers(value);
    if (value.length >= 4) {
      if (value.startsWith('0')) {
        value = value.slice(1, 4);
      } else {
        value = value.slice(0, 3);
      }
    }

    if ((keyPressed && !supportedKeyValues.includes(keyPressed)) || value > getMaxForProductCounter()) {
      return;
    }

    value = parseInt(value, 10);

    if (value < 1000) {
      setInputVal(value);
      target.value = value;
    }

    if (value > 1000) {
      setInputVal(totalQuantity);
      target.value = totalQuantity;
    }
    if (Number.isNaN(value)) {
      setInputVal(undefined);
    }
  };
  const handleQuantityInput = (keyPressed) => {
    if (keyPressed === 'ArrowLeft' || keyPressed === 'ArrowRight' || keyPressed === 'Backspace') {
      return;
    }

    setShowMaxQuantityAlert(false);

    let value = parseInt(inputVal, 10);

    if (value.length >= 4) {
      value = value.slice(0, 3);
    }
    if (value >= getMaxForProductCounter()) {
      setShowMaxQuantityAlert(true);
      value = getMaxForProductCounter();
    }
    if (Number.isNaN(value)) {
      setInputVal(totalQuantity);
      value = totalQuantity;
    }

    if (value < totalQuantity) {
      handleRemoveItem(totalQuantity - value, value === 0, () => {
        if (value === 0) {
          if (warrantyOptions.cartLineId) {
            handleWarrantyOptionsSelected();
          }
        }
      });
    } else if (value > totalQuantity) {
      const currValue = value - totalQuantity;
      handleAddItem(currValue);
    } else if (value === totalQuantity) {
      setInputVal(totalQuantity);
    }
  };

  const stepperStyle = (price && price.length) === 3 ? { marginTop: '15px' } : {};

  useEffect(() => {
    if (isQuantityEditableEnabled && totalQuantity > getMaxForProductCounter()) {
      handleRemoveItem(totalQuantity - getMaxForProductCounter());
      setShowMaxQuantityAlert(true);
    }
  }, []);

  return (
    <div className={`cart-item-wrapper ${mobileClass} mkp`} style={mobileStyle}>
      <div className="cart-item">
        <img src={image} width="50" height="50" alt={name} />
        <div className="additional-information">
          <div className="item-info">
            <BodyCopy type={viewConfig.brandName.type} size={viewConfig.brandName.size}>
              {brandName && brandName.toUpperCase()}
            </BodyCopy>
            <BodyCopy type={viewConfig.description.type} size={viewConfig.description.size} ellipsis maxlines={2}>
              {name}
            </BodyCopy>

            {variantDetails && (
              <BodyCopy type={viewConfig.description.type} size={viewConfig.description.size} ellipsis maxlines={2}>
                {variantDetails}
              </BodyCopy>
            )}
          </div>
          <div
            className={classNames('price', {
              'mt-8px': isNewPriceApplicable && isMobile,
            })}
          >
            <Prices key={productId} productId={productId} priceList={price} variant={viewConfig.price} page="CART" />
          </div>
          {!isMobile && !disableQuantitySelection && (
            <div className={isMobile ? 'stepper-mobile' : 'stepper'} style={stepperStyle}>
              {totalQuantity >= 0 && (
                <ProductCounter
                  count={isQuantityEditableEnabled ? inputVal : totalQuantity}
                  className={isMobile ? 'product-count-mobile' : 'product-count-desktop'}
                >
                  <ProductCounter.Decrement
                    className={isMobile ? 'decrement-mobile' : 'decrement-desktop'}
                    minimum={0}
                    onClick={() => handleRemoveItem(undefined, undefined, undefined, true)}
                    type="primary"
                  />
                  {isQuantityEditableEnabled ? (
                    <ProductCounter.CountInput
                      className={isMobile ? 'product-count-value-mobile' : 'product-count-value-desktop'}
                      handleInputBlurKey={handleQuantityInput}
                      handleOnChange={handleInputChange}
                      maximum={getMaxForProductCounter()}
                      minimum={0}
                    />
                  ) : (
                    <ProductCounter.CountText type="primary" />
                  )}
                  <ProductCounter.Increment
                    className={isMobile ? 'increment-mobile' : 'increment-desktop'}
                    maximum={getMaxForStepper()}
                    onClick={() => handleAddItem(undefined, true)}
                    type="primary"
                  />
                </ProductCounter>
              )}
            </div>
          )}
          {!isMobile && getCartItemActionLabel()}
        </div>
      </div>
      {isMobile && (
        <div>
          <div className="cart-item cart-item-mobile">
            <div className="additional-information">
              {!disableQuantitySelection && (
                <div className={isMobile ? 'stepper-mobile' : 'stepper'} style={stepperStyle}>
                  {totalQuantity >= 0 && (
                    <ProductCounter
                      count={isQuantityEditableEnabled ? inputVal : totalQuantity}
                      className={isMobile ? 'product-count-mobile' : 'product-count-desktop'}
                    >
                      <ProductCounter.Decrement
                        className={isMobile ? 'decrement-mobile' : 'decrement-desktop'}
                        minimum={0}
                        onClick={handleRemoveItem}
                        type="primary"
                      />
                      {isQuantityEditableEnabled ? (
                        <ProductCounter.CountInput
                          className={isMobile ? 'product-count-value-mobile' : 'product-count-value-desktop'}
                          handleInputBlurKey={handleQuantityInput}
                          handleOnChange={handleInputChange}
                          maximum={getMaxForProductCounter()}
                          minimum={0}
                        />
                      ) : (
                        <ProductCounter.CountText type="primary" />
                      )}
                      <ProductCounter.Increment
                        className={isMobile ? 'increment-mobile' : 'increment-desktop'}
                        maximum={getMaxForStepper()}
                        onClick={handleAddItem}
                        type="primary"
                      />
                    </ProductCounter>
                  )}
                </div>
              )}
              {getCartItemActionLabel()}
            </div>
          </div>
        </div>
      )}
      {showCartServicesSection() && (
        <>
          <div className="services-divider" />
          <div className={`cart-services ${isNewWarrantyUIEnabled ? 'new-options' : ''}`}>
            {!isNewWarrantyUIEnabled && showWarrantyOption && warrantyOptions && (
              <div className="list-wrapper warranty-chiplet-select">
                <SingleChipletSelect
                  key={warrantyOptions.label}
                  icon="csicon-shield"
                  options={warrantyOptions.fieldOptions}
                  tooltipContent={warrantyTooltip}
                  cheapestOption={cheapestWarranty}
                  tooltipToggle={isWarrantyTooltipEnabled}
                  label={labels.EXTENDED_WARRANTY_LABEL}
                  startsFromLabel={labels.PDP_STARTS_FROM}
                  secondaryLabel={getSecondaryLabel(warrantyOptions.fieldName, textDictionary)}
                  secondaryLink={getLink(warrantyOptions.fieldName, endPoints, regionCode)}
                  shouldHaveBackground={false}
                  defaultSelectedIndex={totalQuantity > 0 ? getDefaultSelectedIndex(warrantyOptions.fieldOptions) : -1}
                  onOptionSelected={handleWarrantyOptionsSelected}
                  secondaryLabelIcon="csicon-arrow"
                  variant="CART"
                  showAsDropdown={
                    warrantyOptions.fieldOptions.length >= constants.WARRANTY_OPTIONS_MIN_ITEM_COUNT_FOR_DROPDOWN
                  }
                  errorMessage={errorWarranty ? labels.FAILED_ADDING_TO_CART_WARRANTY : null}
                  clickableChiplet
                  disableAll={totalQuantity === 0}
                  serviceType={`InCart-${inPageText}-WarrantyOption`}
                />
              </div>
            )}

            {isNewWarrantyUIEnabled && showWarrantyOption && warrantyOptions && (
              <div className="cart-warranties">
                <WarrantySelect
                  defaultSelectedIndex={totalQuantity > 0 ? getDefaultSelectedIndex(warrantyOptions.fieldOptions) : -1}
                  deviceType={deviceType}
                  errorMessage={errorWarranty ? labels.FAILED_ADDING_TO_CART_WARRANTY : null}
                  handleWarrantyOptionsSelected={handleWarrantyOptionsSelected}
                  options={warrantyOptions.fieldOptions}
                  inPdp={inPdp}
                />
              </div>
            )}
            {showAssemblyOption && assemblyOptions && shouldRenderAdditionalInfo() && (
              <div className="list-wrapper">
                <SingleChipletSelect
                  key={assemblyOptions.label}
                  icon="csicon-icon-services"
                  options={assemblyOptions.fieldOptions}
                  label={assemblyOptions.label}
                  secondaryLabel={getSecondaryLabel(assemblyOptions.fieldName, labels)}
                  secondaryLink={getLink(assemblyOptions.fieldName, endPoints, regionCode)}
                  defaultSelectedIndex={totalQuantity > 0 ? getDefaultSelectedIndex(assemblyOptions.fieldOptions) : -1}
                  onOptionSelected={handleAssemblyOptionSelected}
                  secondaryLabelIcon="csicon-arrow"
                  variant="CART"
                  showAsDropdown={
                    assemblyOptions.fieldOptions.length >= constants.WARRANTY_OPTIONS_MIN_ITEM_COUNT_FOR_DROPDOWN
                  }
                  errorMessage={errorServices ? labels.FAILED_ADDING_TO_CART_SERVICE : null}
                  clickableChiplet
                  isModal
                  disableAll={totalQuantity === 0}
                  isNewWarrantyUIEnabled={isNewWarrantyUIEnabled}
                  serviceType={`InCart-${inPageText}-ServiceOption`}
                />
              </div>
            )}
          </div>
        </>
      )}

      <style jsx>{CartItemStyles}</style>
    </div>
  );
};

CartItem.defaultProps = {
  onAddToCart: noop,
  onRemoveFromCart: noop,
  setBusy: noop,
  setWarrantyOption: noop,
  setAssemblyOption: noop,
  showWarrantyOption: true,
  showButton: true,
  type: '',
  endPoints: {},
  showAssemblyOption: true,
  setCrossSelling: noop,
  variantDetails: '',
  disableQuantitySelection: false,
  setWarrantyOptionCurrent: noop,
  limitAlerts: undefined,
  cart: {},
};

CartItem.propTypes = {
  item: PropTypes.object.isRequired,
  textDictionary: PropTypes.object.isRequired,
  onAddToCart: PropTypes.func,
  onRemoveFromCart: PropTypes.func,
  setBusy: PropTypes.func,
  appCtx: PropTypes.object.isRequired,
  setWarrantyOption: PropTypes.func,
  setAssemblyOption: PropTypes.func,
  showWarrantyOption: PropTypes.bool,
  showAssemblyOption: PropTypes.bool,
  showButton: PropTypes.bool,
  labels: PropTypes.object.isRequired,
  type: PropTypes.string,
  endPoints: PropTypes.object,
  product: PropTypes.object.isRequired,
  setCrossSelling: PropTypes.func,
  variantDetails: PropTypes.string,
  disableQuantitySelection: PropTypes.bool,
  setWarrantyOptionCurrent: PropTypes.func,
  limitAlerts: PropTypes.object,
  cart: PropTypes.object,
};

export default withProduct(withLabels(withApplicationContext(withCart(CartItem))));
export { CartItem };
