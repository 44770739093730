import constants from '../config/constants';

const priceToNumber = (price, regionCode) => {
  const splitPrice = price.split(constants.DECIMAL_SEPARATOR[regionCode]);
  const integerPart = splitPrice[0].replace(/[,.]/g, '');
  const number = splitPrice[1] ? `${integerPart}.${splitPrice[1]}` : integerPart;
  return Number(number);
};

const addProductListEvent = (products, page, domain, regionCode) => {
  const items = products.map((product, index) => {
    const discountType = product.discountBadge && product.discountBadge.label;
    const price = product.prices.map((objPrice) => {
      const priceNumber = objPrice.price && objPrice.price[0] && priceToNumber(objPrice.price[0], regionCode);
      return priceNumber;
    });
    const variantsIds = product.variants.map((variant) => variant.options.map((data) => data.variant));
    const meatstickers = product.meatStickers && product.meatStickers.map((sticker) => sticker.label);
    return {
      brand: product.brand,
      category_id_subclase: product.merchantCategoryId,
      discount_type: discountType,
      id: product.productId,
      is_sponsored: product.isSponsored ? 'patrocinado' : 'no patrocinado',
      list: page.toLowerCase(),
      sellerId: product.sellerId,
      sellerName: product.sellerName,
      name: product.displayName,
      position: index + 1,
      price: Math.min(...price),
      sku_id: product.skuId,
      store_in_store: domain || 'falabella',
      sku_id_variant: variantsIds.flat(),
      gsc_catgory_id: product.GSCCategoryId,
      meatstickers: meatstickers && meatstickers.toString(),
    };
  });
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'xlpProductList',
    productsData: items,
  });
};

export { addProductListEvent };
