import _ from '../LodashImports';

const isStringEqual = (first, second) => {
  return first.toLowerCase() === second.toLowerCase();
};

const isAllAttributesMatching = (attributes, variant) => {
  return Object.keys(attributes).every((attribute) => {
    if (!attributes[attribute]) return true;
    if (!variant.attributes[attribute]) return false;
    return isStringEqual(attributes[attribute], variant.attributes[attribute]);
  });
};

const filterVariants = (variants, attributes) => {
  return variants.reduce((acc, variant) => {
    const { isPurchaseable } = variant;
    if (isPurchaseable && isAllAttributesMatching(attributes, variant)) {
      return [...acc, variant];
    }
    return acc;
  }, []);
};

const getFallback = (variants, colorName) => {
  return filterVariants(variants, { colorName });
};

const getMatchingVariants = (variants, attributes, fallback = false) => {
  const matchingVariants = filterVariants(variants, attributes);

  if (!matchingVariants.length && fallback) {
    return getFallback(variants, attributes.colorName);
  }

  return matchingVariants;
};

const getFirstMatchingVariantId = (variants, attributes) => {
  return _.get(getMatchingVariants(variants, attributes, true), '[0].id', undefined);
};

export { getFirstMatchingVariantId, getMatchingVariants };
