import { getOGImageURL, getOGSiteName } from '@digital-retail/store-manager';
import { NextSeo } from 'next-seo';
import Head from 'next/head';
import PropTypes from 'prop-types';
import React from 'react';

const SellerSEO = ({ SiteProps }) => {
  const { regionCode, metadata, canonicalUrl, store } = SiteProps;

  const title = metadata?.title || '';
  const description = metadata?.description || '';
  const canonicalUrlAux = metadata?.canonical || canonicalUrl;
  const siteName = getOGSiteName({ regionCode, store });

  return (
    <>
      <Head>
        <title> {title} </title>
      </Head>
      <NextSeo
        canonical={canonicalUrlAux}
        description={description}
        openGraph={{
          description,
          canonicalUrlAux,
          site_name: siteName,
          title,
          images: [
            {
              url: getOGImageURL({ regionCode, store }),
            },
          ],
        }}
      />
    </>
  );
};

SellerSEO.propTypes = {
  SiteProps: PropTypes.object.isRequired,
};

export default SellerSEO;
