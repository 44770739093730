/* eslint-disable no-param-reassign */
import { SponsoredProvider } from '@digital-retail/sponsored-provider-library';
import { isStandalone, isTottusStandalone } from '@digital-retail/store-manager';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import AutoCorrect from '../../components/AutoCorrect/AutoCorrect';
import { BrandTitle } from '../../components/BrandTitle/BrandTitle';
import CategoryBanner from '../../components/CategoryBanner/CategoryBanner';
import CategotyTitle from '../../components/CategoryTitle/CategoryTitle';
import Facets from '../../components/Facets/Facets';
import PromotedFilters from '../../components/PromotedFilters/PromotedFilters';
import SEOLeftContent from '../../components/SEOLeftContent/SEOLeftContent';
import SelectedFilters from '../../components/SelectedFilters/SelectedFilters';
import SponsorDisplayLeftWrapper from '../../components/SponsorDisplayLeftWrapper';
import SurveyBar from '../../components/SurveyBar/SurveyBar';
import BodyCopy from '../../components/ui/BodyCopy/BodyCopy';
import MatchMedia from '../../components/ui/MatchMedia/MatchMedia';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withCart } from '../../utils/CartContext';
import { withConfig } from '../../utils/ConfigurationContext';
import { withLabels } from '../../utils/LabelsContext';
import WithRouter from '../../utils/WithRouter';
import { scrollToTopProductList } from '../../utils/scrollToProductList';
import SearchResults from '../SearchResults/SearchResults';
import {
  getFacetParams,
  getHandleBannerActionForSponsorDisplay,
  getIsSponsoredDisplayEnabled,
  getPageId,
  getUrlBase,
} from '../SearchResults/utils';
import ProductLandingContainerStyles from './ProductLandingContainer.style';

const ProductLandingContainer = ({
  layout,
  products,
  facets,
  promotedFilter,
  appCtx,
  appliedFacets,
  availabilityFacets,
  baseFacetsURL,
  autoCorrections,
  pagination,
  sortOptions,
  searchTerm,
  labels,
  categoryParentDetails,
  brandDetails,
  categoryBannerHtml,
  leftPromotionContent,
  pageName,
  cart,
  collectionId,
  config,
}) => {
  const isDesktop = appCtx.deviceType === 'desktop';
  const productListRef = useRef();
  const [stopScroll, setStopScroll] = useState(true);
  const onFacetChange = () => {
    setStopScroll(false);
  };
  const handleScrollToTopProductList = (selectedURLs) => {
    if (!stopScroll) {
      scrollToTopProductList(selectedURLs, productListRef, appCtx.deviceType);
    }
  };

  const visibleFilters = appliedFacets.filter((f) => f.visible);

  const selectedFiltersLabel = labels.SELECTED_FILTERS;
  const resultsLabel = labels.RESULTS;

  const [showFacets, setShowFacets] = useState(true);
  const { regionCode, store } = appCtx;
  const name = WithRouter('name');
  const id = WithRouter('id');
  const Ntt = WithRouter('Ntt');
  const xlpId = pageName === 'search' ? Ntt : id;
  const providedParamforBanner = getPageId(pageName);
  const isSponsoredDisplayEnabled = getIsSponsoredDisplayEnabled(appCtx);
  const [hasImpressionBeenSent, setHasImpressionBeenSent] = useState(false);

  const configValue = {
    regionCode: appCtx.regionCode,
    deviceType: appCtx.deviceType,
    zones: appCtx.zones,
    site: appCtx.store,
    store: appCtx.store,
    urlBase: getUrlBase(config),
    facetParams: getFacetParams(),
    providedId: xlpId,
    providedParam: providedParamforBanner,
    categoryName: name,
  };
  const handleBannerAction = getHandleBannerActionForSponsorDisplay({
    appCtx,
    config,
    categoryName: name,
    mPos: 10,
    hasImpressionBeenSent,
    setHasImpressionBeenSent,
  });

  const leftPromoContent =
    leftPromotionContent ||
    (isStandalone({ store }) && pageName === 'category' && ['cl', 'pe'].includes(regionCode)
      ? appCtx.siteConfig.textDictionary?.CATEGORY_LATERAL_TEXT?.replace(
          /%Category Name%/g,
          categoryParentDetails[0].label
        )
      : '');

  const desktop = () => {
    if (visibleFilters.length > 0) {
      return (
        <SelectedFilters
          filters={visibleFilters}
          appCtx={appCtx}
          baseFacetsURL={baseFacetsURL}
          selectedFiltersLabel={selectedFiltersLabel}
          pageName={pageName}
          facets={facets}
        />
      );
    }
    return null;
  };

  return (
    <SponsoredProvider config={configValue}>
      <div className="pod-group">
        {categoryBannerHtml && (
          <div className="pad-group--category-banner container">
            <CategoryBanner categoryBannerHtml={categoryBannerHtml} store={appCtx.store} />
          </div>
        )}
        {autoCorrections.length !== 0 && (
          <div className="pad-group--auto-correct-mkp container">
            <AutoCorrect searchTerm={searchTerm} items={autoCorrections} />
          </div>
        )}
        <div className="pod-group--container container">
          <section className="pod-group--filters">
            {pageName === 'category' ? (
              <CategotyTitle
                appliedFacets={appliedFacets}
                level1Category={categoryParentDetails[1]}
                level2Category={categoryParentDetails[0]}
                resultsLabel={resultsLabel}
                paginationCount={pagination.data.count}
              />
            ) : (
              <BrandTitle
                brandTitle={brandDetails.displayName}
                resultsLabel={resultsLabel}
                paginationCount={pagination.data.count}
                deviceType={appCtx.deviceType}
              />
            )}

            {appCtx.deviceType !== 'mobile' && (
              <div className="total-results">
                <BodyCopy size="copy5" id="testId-ProductLandingContainer-totalResults" type="secondary">
                  {resultsLabel} ({pagination.data.count})
                </BodyCopy>
              </div>
            )}
            <MatchMedia
              deviceType={appCtx.deviceType}
              content={{
                desktop,
              }}
            />
            {showFacets && (
              <Facets
                availabilityFacets={availabilityFacets}
                baseFacetsURL={baseFacetsURL}
                sortOptions={sortOptions}
                data={facets}
                pageName={pageName}
                onFacetChange={onFacetChange}
                handleScrollToTopProductList={handleScrollToTopProductList}
                appliedFacets={appliedFacets}
                pagination={pagination}
              />
            )}
            <SEOLeftContent html={leftPromoContent} />

            {!isTottusStandalone(store) && (
              <div className="survey-container">
                <SurveyBar withBorder />
              </div>
            )}

            <div className="vertical-banner">
              {isSponsoredDisplayEnabled && isDesktop && (
                <SponsorDisplayLeftWrapper handleBannerAction={handleBannerAction} appCtx={appCtx} />
              )}
            </div>
          </section>
          <section className="pod-group--products" ref={productListRef}>
            {promotedFilter && (
              <PromotedFilters promotedFilter={promotedFilter} baseFacetsURL={baseFacetsURL} pageName={pageName} />
            )}
            <SearchResults
              layout={layout}
              products={products}
              pagination={pagination}
              sortOptions={sortOptions}
              pageName={pageName}
              tenant={appCtx.tenant}
              cart={cart}
              setShowFacets={setShowFacets}
              setStopScroll={setStopScroll}
              collectionId={collectionId}
            />
          </section>
        </div>
        {!isTottusStandalone(store) && (
          <div className="survey-container-mobile">
            <SurveyBar />
          </div>
        )}
        <style jsx>{ProductLandingContainerStyles}</style>
      </div>
    </SponsoredProvider>
  );
};

ProductLandingContainer.defaultProps = {
  products: [],
  facets: [],
  appliedFacets: [],
  searchTerm: '',
  labels: {},
  layout: {
    desktop: {
      view: '4_GRID',
    },
    mobile: {
      view: '2_GRID',
    },
  },
  categoryParentDetails: [{}, {}],
  leftPromotionContent: '',
  categoryBannerHtml: '<div></div>',
  pageName: 'category',
  brandDetails: {},
  collectionId: '',
};
ProductLandingContainer.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  facets: PropTypes.arrayOf(PropTypes.object),
  appliedFacets: PropTypes.arrayOf(PropTypes.object),
  appCtx: PropTypes.object.isRequired,
  pagination: PropTypes.shape({
    data: PropTypes.object,
    onPageSelection: PropTypes.func,
  }).isRequired,
  sortOptions: PropTypes.array.isRequired,
  searchTerm: PropTypes.string,
  baseFacetsURL: PropTypes.string.isRequired,
  autoCorrections: PropTypes.array.isRequired,
  availabilityFacets: PropTypes.array.isRequired,
  labels: PropTypes.object,
  layout: PropTypes.string,
  categoryParentDetails: PropTypes.arrayOf(PropTypes.object),
  categoryBannerHtml: PropTypes.string,
  leftPromotionContent: PropTypes.string,
  promotedFilter: PropTypes.object.isRequired,
  pageName: PropTypes.string,
  brandDetails: PropTypes.object,
  cart: PropTypes.object.isRequired,
  collectionId: PropTypes.string,
  config: PropTypes.object.isRequired,
};

export default withApplicationContext(withLabels(withConfig(withCart(ProductLandingContainer))));
export { ProductLandingContainer };
