import css from 'styled-jsx/css';
import { colors } from '../../../../config/styles';

const VerMasButtonStyle = css`
  .verMas-button-container {
    margin: 5px 0 5px 30px;
    :global(.button) {
      text-wrap: nowrap;
      width: auto;
      height: 22px;
    }
    @mixin mobileToTablet {
      background-color: ${colors.gray.shade12};
      margin: unset;
      display: flex;
      justify-content: center;
      height: 41px;
      padding-top: 3px;
      :global(.button) {
        text-wrap: nowrap;
        width: auto;
        height: 27px;
      }
    }
  }
`;

export default VerMasButtonStyle;
