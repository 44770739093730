import css from 'styled-jsx/css';

const singleSelectStyle = css`
  .singleselect {
    overflow-y: auto;
    @mixin tabletToDesktop {
      margin: 12px 0 16px;
      max-height: 245px;
      border-bottom: 0;
    }
    li {
      padding: 10px 20px;
      display: flex;
      height: auto;
      @mixin tabletToDesktop {
        padding: 5px 0 0 0;
        border-bottom: 0;
      }
    }
  }
`;

export { singleSelectStyle };
