import css from 'styled-jsx/css';

const ProductCounterStyles = css`
  .product-count {
    display: inline-flex;
    position: relative;
    border-radius: 2px;
    width: 100%;
    height: 30px;

    &.secondary {
      width: 166px;
      height: 48px;
      border: 2px solid #f0f0f0;
      border-radius: 4px;
    }
  }
`;

export { ProductCounterStyles };
