import css from 'styled-jsx/css';

const PodStyles = css`
  .search-results-4-grid {
    width: 50%;
    padding-left: 10px;
    @mixin tabletToDesktop {
      width: 25%;
    }
  }
  .search-results-2-grid {
    width: 50%;
    margin-bottom: 10px;
    @mixin tabletToDesktop {
      padding-left: 10px;
    }
  }
  .search-results-1-grid {
    width: 100%;
  }
  .search-results-list {
    width: 100%;
    margin-bottom: 10px;
    @mixin tabletToDesktop {
      padding-left: 10px;
    }
  }
  .grid-pod {
    margin-bottom: 10px;
  }
  .search-results-3-grid {
    width: 33.33%;
    margin-bottom: 10px;
    padding-left: 10px;
  }
  .search-results-2-grid:nth-child(2n + 1) {
    @mixin mobileToTablet {
      padding-right: 5px;
      padding-left: 10px;
    }
  }
  .search-results-2-grid:nth-child(2n + 2) {
    @mixin mobileToTablet {
      padding-right: 10px;
      padding-left: 5px;
    }
  }
  .basket-icon {
    background: url('/a/fa/listing/static/styles/svg/basket-rebranding.svg') no-repeat center;
    width: 20px;
    height: 20px;
    background-size: 19px;
    margin-top: 1px;
    margin-left: 5px;
  }
`;

export default PodStyles;
