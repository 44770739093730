import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

const successIconSvgMkp = `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.80785 23.1922C9.88497 28.2693 18.1161 28.2693 23.1932 23.1922C28.2689 18.115 28.2689 9.88392 23.1932 4.8068C18.1161 -0.268932 9.88497 -0.268932 4.80785 4.8068C-0.269282 9.88392 -0.269282 18.115 4.80785 23.1922Z" stroke="#30871F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 14.2538L12.7343 18L19 10" stroke="#30871F" stroke-width="1.5"/>
</svg>`;

const CartMobileStyles = css`
  .cart-mobile {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .header {
    min-height: 65px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${colors.gray.shade14};

    .csicon-icon-success-circle {
      color: ${colors.green.shade1};
      font-size: ${typography.xl3a};
    }

    .label {
      flex-grow: 1;
      margin: 0 14px;
    }
    &.mkp {
      .label {
        font-size: ${typography.xl};
        color: ${colors.black.shade1};
      }
      .csicon-icon-success-circle {
        font-size: ${typography.xl3c};
        color: ${colors.green.shade20};
      }
      .csicon-icon-success-circle::before {
        content: url('${svgToDataUri(successIconSvgMkp)}');
      }
    }

    .csicon-danger {
      color: ${colors.gray.shade29};
      font-size: ${typography.sm};
    }
  }
  .body {
    flex-grow: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100vw;
    height: 100%;

    .cart-item {
      margin-top: 5px;
    }

    .collections,
    .bundles {
      border-top: 1px solid ${colors.gray.shade14};

      span {
        margin: 22px 0;
        display: block;
      }
    }

    .collections {
      padding: 22px 21px;
    }

    .bundles {
      margin-bottom: 25px;
      padding: 0;
    }
  }

  .footer {
    background-color: ${colors.gray.shade41};
    padding: 0 27px;
    position: relative;
    flex-grow: 1;

    &.mkp {
      padding: 0 24px 0 25px;
    }

    &::before {
      content: ' ';
      position: absolute;
      left: 0;
      right: 0;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 100%);
      height: 52px;
      top: -51px;
    }

    .warning {
      height: 30px;
      border-bottom: 1px solid ${colors.gray.shade14};
      color: ${colors.black.shade1};

      .csicon-alert {
        color: ${colors.orange.shade1};
        font-size: ${typography.lg};
        margin-right: 10px;
      }

      span {
        font-family: inherit;
        font-size: ${typography.xs};
        line-height: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      display: flex;
      align-items: center;
    }

    .sub-footer {
      padding: 12px 0;
      display: flex;
      flex-direction: column;

      .sub-total-wrapper {
        display: flex;
        margin-bottom: 12px;

        div {
          flex: 1 1 auto;
        }
      }

      & :global(button) {
        align-self: center;
        margin-bottom: 13px;
      }

      & :global(button.button-tertiary-link) {
        width: auto;
      }

      & :global(button:last-child) {
        align-self: center;
        margin-bottom: 0;
      }

      .sub-total {
        font-size: 12px;
        line-height: 14px;

        :global(span) {
          margin-right: 5px;
          display: block;
        }
      }
    }
  }

  .addToCart-btn {
    margin-bottom: 15px;
  }

  @supports (-webkit-overflow-scrolling: touch) {
    /* CSS specific to iOS devices */
    .cart-mobile {
      height: 85vh;
    }
  }
`;

const CartBrandNameStyle = css`
  .brand-name {
    display: block;
    font-weight: 700;
    letter-spacing: 0.73px;
    line-height: 12px;
  }
`;

const CartDescriptionStyle = css`
  .description {
    letter-spacing: 0.3px;
    line-height: 19px;
  }
`;

const CartAddedBundleStyles = css`
  .added-bundles {
    margin: 0 21px;

    .header {
      height: 60px;
      display: flex;
      align-items: center;

      .csicon-icon-success-circle {
        color: ${colors.green.shade1};
        font-size: 25px;
        margin-right: 14px;
      }
      &.mkp {
        .csicon-icon-success-circle {
          color: ${colors.green.shade20};
          font-size: 18px;
        }
      }
    }

    .bundle-items {
      display: flex;
      flex-direction: column;

      .bundle-item {
        align-items: center;
        display: flex;
        margin-bottom: 22px;
      }

      img {
        margin-right: 18px;
        height: 90px;
        width: 90px;
        border: 1px solid ${colors.gray.shade43};
        flex-shrink: 0;
      }
    }
  }
`;

export { CartMobileStyles, CartBrandNameStyle, CartDescriptionStyle, CartAddedBundleStyles };
