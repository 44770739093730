import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DrawerStyles, DrawerGlobalStyles } from './Drawer.style';
import WithOutsideClick from '../../../utils/WithOutsideClick';
import BackgroundOverlay from '../BackgroundOverlay/BackgroundOverlay';
import { toggleBodyClass } from '../../../utils/jsUtils';
import noop from '../../../utils/noop';
import Loader from '../../Loader/Loader';

const Drawer = ({ heading, children, toggleState, isLoading, onOpen }) => {
  const [isDrawerActive, activateDrawer] = toggleState;

  useEffect(() => {
    toggleBodyClass(isDrawerActive, 'drawer-active');
  }, [isDrawerActive]);

  useEffect(() => {
    if (isDrawerActive) onOpen();
  }, [isDrawerActive]);

  return (
    <div
      className={classNames('drawer-container', {
        'is-open': isDrawerActive,
      })}
    >
      {isDrawerActive && (
        <div className="drawer-layout">
          <WithOutsideClick
            onOutsideClick={() => {
              activateDrawer(false);
            }}
          >
            <div className="drawer-header">
              {heading}
              <i
                className="csicon-danger"
                onKeyDown={noop}
                role="button"
                tabIndex={0}
                onClick={() => activateDrawer(false)}
              />
            </div>
            <div className="drawer-content">
              {children}
              {isLoading && <Loader />}
            </div>
          </WithOutsideClick>
          <BackgroundOverlay />
        </div>
      )}
      <style jsx>{DrawerStyles}</style>
      <style jsx>{DrawerGlobalStyles}</style>
    </div>
  );
};

Drawer.defaultProps = {
  isLoading: true,
  onOpen: () => {},
};

Drawer.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.func.isRequired,
  toggleState: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  onOpen: PropTypes.func,
};

export default Drawer;
