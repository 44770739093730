import css from 'styled-jsx/css';
import { zIndex } from '../../../config/styles';

const BackgroundOverlayStyles = css`
  .background-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: ${zIndex.taxonomy.overlay};
    transition: background-color 0.5s ease;
  }
  .shade-lighter {
    background-color: rgba(51, 51, 51, 0.6);
  }
`;

export { BackgroundOverlayStyles };
