import PropTypes from 'prop-types';
import React from 'react';
import _ from '../../../utils/LodashImports';
import { TooltipStyleStyles, TooltipTextStyles } from './Tooltip.style';

const TooltipText = ({ text, viewType }) => {
  return (
    <p className={`tooltipText ${viewType}`}>
      {text} <style jsx>{TooltipTextStyles}</style>
    </p>
  );
};

TooltipText.defaultProps = {
  viewType: 'default',
};

TooltipText.propTypes = {
  text: PropTypes.string.isRequired,
  viewType: PropTypes.oneOf(['fullWidth', 'default', 'medium']),
};

const Tooltip = ({ tooltipContent, children, hasCaret, position, viewType, showArrow, tooltipContentRef }) => {
  const offsetLeft = _.get(tooltipContentRef, 'current.offsetLeft', '');
  return (
    <>
      <div className={`tooltipParent ${viewType}`}>
        {children}
        <div className={`tooltipContainer ${position}`}>
          {hasCaret && (
            <div
              style={
                offsetLeft
                  ? {
                      left: `${offsetLeft - 13.5}px`,
                      transform: 'translateX(0px)',
                    }
                  : {}
              }
              className={`caret ${position}`}
            />
          )}
          <div className="tooltipContent">{tooltipContent}</div>
        </div>
        {showArrow && <div className="pointer" />}
      </div>
      <style jsx>{TooltipStyleStyles}</style>
    </>
  );
};

Tooltip.defaultProps = {
  hasCaret: false,
  viewType: 'default',
  showArrow: false,
  tooltipContentRef: null,
};

Tooltip.propTypes = {
  children: PropTypes.object.isRequired,
  tooltipContent: PropTypes.object.isRequired,
  tooltipContentRef: PropTypes.any,
  hasCaret: PropTypes.bool,
  position: PropTypes.oneOf([
    'left',
    'right',
    'bottom',
    'bottom-middle',
    'top',
    'top-left',
    'top-middle',
    'bottom-left',
    'bottom-left-1',
  ]).isRequired,
  viewType: PropTypes.oneOf(['fullWidth', 'default', 'medium', 'small']),
  showArrow: PropTypes.bool,
};

export { TooltipText };
export default Tooltip;
