import css from 'styled-jsx/css';

const SponsorDisplayLeftStyles = css`
  .sponsored-display-left {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

export default SponsorDisplayLeftStyles;
