import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';

const TypeCProductDesktopStyles = css`
  .typeCProduct {
    padding: 14px 0;
    border-bottom: 1px solid ${colors.gray.shade3};
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &-variants {
      margin-top: 30px;
      width: 200px;
    }

    .addToCart-button {
      &-text {
        font-size: 14px;
        letter-spacing: 1.08px;
        line-height: 16px;
        text-transform: uppercase;
        font-weight: bold;
      }
      &-wrapper {
        margin-top: 30px;
        width: 300px;
        &.mkp {
          .addToCart-button-text {
            font-weight: 400;
            letter-spacing: 0.5px;
            line-height: 23px;
            text-transform: inherit;
            font-size: 19px;
          }
        }
      }
    }
    &:last-child {
      border-bottom: none;
    }
    &:first-child {
      padding-top: 0;
    }
  }
  .colorName {
    font-size: ${typography.base};
    color: ${colors.black.shade1};

    .colorLabel {
      font-weight: 700;
    }
  }

  .typeCProduct.cart {
    justify-content: flex-start;
    padding: 18px 0 0;

    .typeCProduct-variants {
      margin-top: 0;
      margin-left: 18px;
      flex-grow: 1;
    }

    .addToCart-button-wrapper {
      margin-top: 0;
      max-width: 207px;

      span {
        font-weight: bold;
        letter-spacing: 0.92px;
        line-height: 13px;
        text-align: center;
      }
      &.mkp {
        display: flex;
        justify-content: flex-end;
        span {
          font-weight: 400;
          letter-spacing: 0.5px;
          line-height: 23px;
          text-transform: inherit;
          &.addToCart-button-text {
            font-size: 19px;
          }
        }
      }
    }
  }
`;

export { TypeCProductDesktopStyles };
