import css from 'styled-jsx/css';
import { typography } from '../../config/styles';

const ProdFeaturesStyles = css`
  .section__pod-bottom-description {
    font-size: 1.1rem;
    line-height: 1.3rem;
    li {
      position: relative;
      padding-left: 5px;
      margin-bottom: 2px;
      font-size: 10px;
      line-height: 12px;
      @mixin tabletToDesktop {
        font-size: 12px;
        line-height: 15px;
      }
      &::before {
        position: absolute;
        font-size: ${typography.xl2};
        font-weight: 700;
        left: -3px;
        top: -1px;
        content: '·';
      }
    }
  }
`;

export default ProdFeaturesStyles;
