import css from 'styled-jsx/css';
import { colors, typography } from '../../../../config/styles';

const SizeButtonStyles = css`
  .size-button {
    padding: 9px 10px;
    box-sizing: border-box;
    font-size: ${typography.sm1};
    width: auto;
    min-width: 44px;
    height: 41px;
    line-height: 19px;

    &.rebranded {
      @mixin mobileToTablet {
        font-size: ${typography.lg1};
      }
      @mixin tabletToDesktop {
        font-size: ${typography.sm1};
      }
    }

    @mixin desktop {
      width: auto;
      min-width: 38px;
      height: 35px;
      line-height: 16px;
      padding: 9px 10px;
    }

    font-family: inherit;
    font-weight: bold;
    border-radius: 3px;
    margin: 0 8px 8px 0;
    color: ${colors.black.shade7};
    border: 1.1px solid ${colors.gray.shade36};
    background-color: ${colors.gray.shade12};

    &-selected {
      color: ${colors.green.shade1};
      border-color: ${colors.green.shade12};
      background-color: ${colors.green.shade13};
      &.rebranded {
        color: ${colors.black.shade10};
        border: 2px solid var(--primary-color);
        background-color: ${colors.white.shade1};
        border-radius: 3px;
        @mixin tabletToDesktop {
          padding-left: 9px;
          padding-right: 9px;
          padding-top: 8px;
        }
      }
    }

    &-unavailable {
      position: relative;
      color: ${colors.gray.shade33};
      border: 1px solid ${colors.gray.shade36};
      background-color: inherit;
      background: linear-gradient(to bottom right, #fff calc(50% - 1px), ${colors.gray.shade36}, #fff calc(50% + 1px));
    }

    &-withText {
      padding: 0.5px;
    }

    &-text {
      font-size: ${typography.xs1};
    }
  }
`;

export { SizeButtonStyles };
