import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';

const styles = css`
  .my-lists-alert {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    &.warning {
      color: ${colors.gray.shade15};
    }
    b {
      font-weight: 700;
    }
    a {
      text-decoration: underline;
      color: inherit;
      text-underline-offset: 3px;

      text-decoration-thickness: 1px; /* stylelint-disable-line */
    }
    button {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-decoration: underline;
      text-underline-offset: 3px;
      text-decoration-thickness: 1px; /* stylelint-disable-line */
      font-family: Lato, sans-serif;
      color: ${colors.gray.shade15};
    }
  }
`;

export default styles;
