import PropTypes from 'prop-types';
import React from 'react';
import { withLabels } from '../../../utils/LabelsContext';
import WithToggle from '../../../utils/WithToggle';
import noop from '../../../utils/noop';
import BackgroundOverlay from '../BackgroundOverlay/BackgroundOverlay';
import { dialogStyles } from './Dialog.style';

const Dialog = ({ header, body, footer, animationDirection, showDialog, toggleState, isMenuActive }) => {
  const getClassName = () => {
    if (showDialog) {
      return `${animationDirection} ${animationDirection}-active`;
    }
    return `${animationDirection}`;
  };

  const hasFooterClassName = footer() ? '' : 'no-footer';
  return (
    <>
      <div
        role="button"
        tabIndex="0"
        onKeyDown={noop}
        className={`dialog ${getClassName()} ${hasFooterClassName}`}
        onClick={() => {
          header.onClose();
        }}
      >
        <div
          role="button"
          tabIndex="0"
          onKeyDown={noop}
          className="dialog dialog-background"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <header className={header.headerClass || ''}>
            {header.title && <h1>{header.title}</h1>}
            <div className="actions">
              {isMenuActive && (
                <button type="button" className="back" onClick={header.hideActiveMenu}>
                  <i className="icon csicon-arrow_left" />
                </button>
              )}
              <button type="button" className="clear" onClick={header.onClose} id="testId-Dialog-close">
                <i className="icon csicon-danger" />
              </button>
            </div>
          </header>
          <div className="body">{body(toggleState)}</div>
          {footer && <div className="footer">{footer()}</div>}
        </div>
        <style jsx>{dialogStyles}</style>
      </div>
      {showDialog && <BackgroundOverlay shade="lighter" />}
    </>
  );
};

Dialog.defaultProps = {
  footer: noop,
  header: {},
  animationDirection: 'rightToLeft',
  showDialog: false,
  isMenuActive: false,
};

Dialog.propTypes = {
  toggleState: PropTypes.object.isRequired,
  header: PropTypes.object,
  body: PropTypes.func.isRequired,
  footer: PropTypes.func,
  animationDirection: PropTypes.oneOf(['rightToLeft']),
  showDialog: PropTypes.bool,
  isMenuActive: PropTypes.bool,
};

export { Dialog };
export default WithToggle(withLabels(Dialog));
