import css from 'styled-jsx/css';
import { colors, zIndex } from '../../../config/styles';

const snackbarStyle = css`
  .snackbar {
    display: flex;
    min-width: 250px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 8px;
    position: fixed;
    z-index: ${zIndex.popover.content};
    bottom: 107px;
    left: 0;
    right: 0;
    margin-left: 14px;
    margin-right: 14px;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    -webkit-animation: fadein 0.5s;
    animation: fadein 0.5s;
    align-items: center;
    .csicon-icon-success-circle {
      margin-right: 10px;
      font-size: 19px;
    }
    &.xlp {
      bottom: 14px;
    }
  }
  .snackbar.success {
    background-color: ${colors.green.shade21};
    color: ${colors.green.shade22};
  }
  .snackbar.warning {
    background-color: ${colors.yellow.shade5};
    color: #f7b500;
  }
  @-webkit-keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 1em;
      opacity: 1;
    }
  }

  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 1em;
      opacity: 1;
    }
  }
`;

export { snackbarStyle };
