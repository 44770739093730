import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../../../ui/Modal/Modal';
import VerMasFacetContent from '../VerMasFacetContent/VerMasFacetContent';

const VerMasFacetModal = ({ facet, hidden, visible, setVisible, handleViewResults, loading }) => {
  if (hidden) return null;
  const digitalDataArray = new Array(6);

  const digitalData = () => {
    const payload = Array.from(digitalDataArray, (value) => {
      if (!value) {
        // eslint-disable-next-line no-param-reassign
        value = 'x';
      }
      return value;
    }).join('|');

    const eventToDigitalData = new CustomEvent('DDXLPFacetModal', {
      bubbles: true,
      detail: { payload },
    });
    window.dispatchEvent(eventToDigitalData);
  };
  const setDigitalData = (value, index) => {
    switch (index) {
      case 1:
        if (!digitalDataArray[1]) {
          digitalDataArray[1] = value;
        } else {
          digitalDataArray[1] = `${digitalDataArray[1]},${value}`;
        }
        break;
      case 3:
        if (digitalDataArray[3]) {
          const selectedBrandArray = digitalDataArray[3].split(',');
          if (!selectedBrandArray.includes(value)) {
            digitalDataArray[3] = `${digitalDataArray[3]},${value}`;
            selectedBrandArray.push(value);
            digitalDataArray[2] = selectedBrandArray.length;
          }
        } else {
          digitalDataArray[3] = value;
          digitalDataArray[2] = 1;
        }
        break;
      default:
        digitalDataArray[index] = value;
    }
  };

  const modalCloseAction = () => {
    digitalDataArray[0] = 'Close window';
    digitalData();
  };

  const modalContent = (actions) => {
    return (
      <VerMasFacetContent
        facet={facet}
        handleViewResults={handleViewResults}
        setVisible={setVisible}
        loading={loading}
        actions={actions}
        setDigitalData={setDigitalData}
        digitalData={digitalData}
      />
    );
  };

  return (
    <Modal
      showCloseButton
      content={modalContent}
      options={{
        width: 656,
        height: 639,
        padding: '10px',
        disableOutsideClick: true,
      }}
      toggleState={{ setVisible, visible }}
      withPadding={false}
      className="bottom"
      deviceType="desktop"
      stopScroll
      onClose={modalCloseAction}
    />
  );
};

VerMasFacetModal.propTypes = {
  facet: PropTypes.object.isRequired,
  hidden: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  handleViewResults: PropTypes.func.isRequired,
  loading: PropTypes.func.isRequired,
};

VerMasFacetModal.defaultProps = {
  hidden: false,
};

export { VerMasFacetModal };
export default VerMasFacetModal;
