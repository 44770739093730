export const facetClick = () => {
  if (window.digitalData) {
    window.dispatchEvent(
      new CustomEvent('DDXLPFacetClick', {
        bubbles: true,
        detail: 1,
      })
    );
  }
};

export const facetFilters = (facets) => {
  if (window.digitalData) {
    window.dispatchEvent(
      new CustomEvent('DDXLPFacetFilters', {
        bubbles: true,
        detail: facets,
      })
    );
  }
};
