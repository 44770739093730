import css from 'styled-jsx/css';
import { typography } from '../../../../config/styles';

const CountInputStyles = css`
  .product-count-value {
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    -moz-appearance: textfield;

    &-mobile {
      height: 30px;
    }

    &-desktop {
      height: 30px;
    }

    &-input {
      width: 30px;
      text-align: center;
      font-size: ${typography.lg1};
      font-weight: 400;
      line-height: 19.2px;
      background-color: transparent;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:focus {
        outline: none;
      }
    }
  }
`;

export { CountInputStyles };
