import css from 'styled-jsx/css';
import { colors, typography, zIndex } from '../../../config/styles';

const DropdownStyles = css`
  .dropdown-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    @mixin tabletToDesktop {
      width: auto;
    }

    button {
      width: 100%;
      text-align: left;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .icon {
      font-size: ${typography.xxs};
      padding: 5px 0;
      color: ${colors.gray.shade15};
    }

    .dropdown-list {
      background: ${colors.white.shade1};
      box-shadow: 0 3px 15px 1px rgba(0, 0, 0, 0.1);
      border-radius: 0 0 3px 3px;
      display: block;
      padding: 8px 0;
      margin: 0;
      height: auto;
      position: absolute;
      top: calc(100% + 2px);
      width: 175px;
      right: 0;
      max-height: 331px;
      overflow-y: auto;
      white-space: initial;
      z-index: ${zIndex.popover.content};

      li {
        display: block;
      }
      @mixin tabletToDesktop {
        width: 250px;
      }
    }

    .dropdown-link {
      color: ${colors.gray.shade15};
      padding: 12px 20px;
      display: block;
      font-size: ${typography.base};
      font-family: inherit;
      line-height: 14px;
    }

    .btn-with-icon {
      font-size: ${typography.lg1};
      justify-content: center;
      height: 55px;
      padding: 0 10px;
      border-right: 1px ${colors.gray.shade14} solid;
      align-items: center;
      text-align: center;
      i {
        margin-right: 3px;
        width: 20px;
        height: 24px;
        font-size: 24px;
      }
    }

    .dropdown-select {
      display: inline-block;
      border: 0;
      border-radius: 0;
      border-bottom: 1px solid ${colors.gray.shade5};
      padding: 0;
      margin: 0;
      font: inherit;
      outline: none;
      line-height: 1.2;
      background: transparent;
      min-width: 250px;
      font-size: ${typography.base};
      font-weight: 700;
      -webkit-appearance: none;

      button {
        padding-top: 5px;
      }
    }

    .dropdown-list-item {
      border-left: 2px solid transparent;
      :hover {
        background: ${colors.gray.shade1};
      }
    }

    .dropdown-list-item-active {
      border-left: 3px solid ${colors.green.shade2};
      background: ${colors.gray.shade3};
      :hover {
        background: ${colors.gray.shade1};
      }
    }

    .dropdown-list-item-mkp-active {
      border-left: 3px solid var(--secondary-color);
      background: ${colors.gray.shade3};
      &.primary-theme {
        border-left: 3px solid var(--primary-color);
      }
      :hover {
        background: ${colors.gray.shade1};
      }
    }
  }

  .dropdown-wrapper.CART {
    .icon {
      font-size: ${typography.xxs3};
    }
    .dropdown-list {
      width: 100%;
    }
    .dropdown-link {
      font-size: ${typography.sm};
    }
    .dropdown-select {
      min-width: auto;
      width: 100%;

      @mixin tabletToDesktop {
        min-width: 200px;
      }
    }

    @mixin desktop {
      .dropdown-list {
        width: 220px;
      }
      .dropdown-select {
        min-width: auto;
        width: 220px;
      }
    }
  }
`;

export { DropdownStyles };
