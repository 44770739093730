import PropTypes from 'prop-types';
import React from 'react';
import BodyCopy from '../../ui/BodyCopy/BodyCopy';

const PaginationDetail = ({ totalResults, resultsPerPage, currentPage, ofTextLabel, resultsTextLabel }) => {
  const generateText = () => {
    const firstIndex = (currentPage - 1) * resultsPerPage + 1;
    const lastIndex = Math.min(currentPage * resultsPerPage, totalResults);
    return `${firstIndex} - ${lastIndex} ${ofTextLabel} ${totalResults} ${resultsTextLabel}`;
  };

  if (totalResults === 0) {
    return null;
  }

  return (
    <BodyCopy size="copy5" isHighlighted>
      <span id="search_numResults" data-results={totalResults}>
        {generateText()}
      </span>
    </BodyCopy>
  );
};

PaginationDetail.propTypes = {
  totalResults: PropTypes.number.isRequired,
  resultsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  ofTextLabel: PropTypes.string.isRequired,
  resultsTextLabel: PropTypes.string.isRequired,
};

export default PaginationDetail;
