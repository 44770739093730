import css from 'styled-jsx/css';
import colors from '../../config/styles/colors';

const FacetsPriceRangeStyle = css`
  /* stylelint-disable declaration-no-important, length-zero-no-unit */
  .price-range-wrapper {
    padding: 15px 25px;
    @mixin tabletToDesktop {
      padding: 4px 13px 0px 0px;
    }
    @mixin mobileToTablet {
      background-color: ${colors.gray.shade12};
      padding: 4px 13px 4px 13px;
    }
  }
  .price-range-container {
    display: flex;
    align-items: center;
    width: 88%;
    justify-content: space-around;
    @mixin mobileToTablet {
      width: 100%;
    }

    button {
      height: 30px;
      width: 30px;
      color: ${colors.black.shade1};
      transition: 0.2s;
      flex-shrink: 0;
      margin-left: 10px;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      text-decoration-line: underline;

      &:disabled {
        color: ${colors.gray.shade5};

        &:hover {
          background-color: ${colors.white.shade1};
          color: ${colors.gray.shade5};
        }
      }
    }
    .priceRangeButton {
      display: inline-block;
    }
    .range {
      height: 16px;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0.16px;
      line-height: 16px;
      text-align: center;
      text-transform: lowercase;
      width: 45px;

      @mixin desktop {
        width: auto;
        margin: 0 5px;
      }
    }
    .range.active {
      color: ${colors.black.shade1};
    }
  }

  .error-text {
    color: ${colors.red.shade1};
    font-size: 12px;
    line-height: 12px;
    margin-top: 5px;
    text-transform: none;
  }

  .divider-line {
    border: 1px solid ${colors.gray.shade14};
    margin-top: 14px;
    margin-bottom: 14px;
  }
`;

export { FacetsPriceRangeStyle };
