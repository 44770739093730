import { isTottusStandalone } from '@digital-retail/store-manager';
import apiConfig from '../../config/api/apiConfig';
import { getLatAndLong } from '../CartHelpers';
import CookieStorage from '../CookieStorage';
import _ from '../LodashImports';
import Logger from '../Logger';
import httpService from '../httpService';
import { getCommerceHeaders } from '../tenantHelper';
import { cartAlertsAndErrorSeggregator, mapProduct, segregateProduct } from './helpers';

const removeProductCommerce =
  ({ cart, setCart }) =>
  async ({ appCtx, products = [], bundledProducts = [], cb = () => {} }) => {
    const { regionCode, store } = appCtx;
    const cartId = CookieStorage.getCookie('cartId');
    const {
      OFFER_PRICE_LABEL,
      NORMAL_PRICE_LABEL,
      DEFAULT_LATITUDE = '-33.731683',
      DEFAULT_LONGITUDE = '-70.735547',
    } = _.get(appCtx, 'siteConfig.textDictionary', {});
    const toggles = _.get(appCtx, 'siteConfig.toggles', {});
    const combinedProducts = [...products, ...bundledProducts];

    const patchCart = [];

    combinedProducts.forEach(({ cartLineId, totalQuantity, warrantycartLineId, servicecartLineId, quantity = 1 }) => {
      patchCart.push({
        op: 'replace',
        path: `${cartLineId}/quantityNumber`,
        value: totalQuantity - quantity,
      });
      if (warrantycartLineId && totalQuantity > 1) {
        patchCart.push({
          op: 'replace',
          path: `${warrantycartLineId}/quantityNumber`,
          value: totalQuantity - quantity,
        });
      }
      if (servicecartLineId && totalQuantity > 1) {
        patchCart.push({
          op: 'replace',
          path: `${servicecartLineId}/quantityNumber`,
          value: totalQuantity - quantity,
        });
      }
    });
    const reqBody = {
      data: {
        cart: {
          patchCart,
        },
      },
      metadata: {
        politicalAreaId: appCtx.politicalId,
        priceGroup: appCtx.priceGroupId,
      },
    };
    if (isTottusStandalone(store)) {
      const { latitude = DEFAULT_LATITUDE, longitude = DEFAULT_LONGITUDE } = getLatAndLong();
      reqBody.metadata.latAndLong = {
        lat: `${latitude}`,
        long: `${longitude}`,
      };
    }

    const additionalHeaders = getCommerceHeaders(regionCode, true, appCtx.url, appCtx.store);

    const patchCartLineUrl = apiConfig.patchCartlineCommerceUrl(cartId, regionCode);

    const { data: response, statusCode } = await httpService().patch(
      patchCartLineUrl,
      reqBody,
      {
        credentials: 'include',
      },
      additionalHeaders,
      'json'
    );

    const items = _.get(response, 'data.cart.cartLines', []);

    const alerts = _.get(response, 'data.data.cart.cartAlerts', []);
    const { limitAlerts } = cartAlertsAndErrorSeggregator(alerts, appCtx.siteConfig);
    const totalProductsQuantityCount = _.get(response, 'data.cart.totalProductsQuantityCount', 0);
    const totalProductsCount = totalProductsQuantityCount;

    if (statusCode === 200 && items) {
      const mappedProducts = await items.map((item) =>
        mapProduct(item, regionCode, { OFFER_PRICE_LABEL, NORMAL_PRICE_LABEL }, toggles)
      );

      const mappedItem = segregateProduct({
        mappedProducts,
        products,
        action: 'REMOVE',
        bundledProducts,
        bundleItems: cart.bundleItems,
        cartItems: cart.cartItems,
      });

      setCart({
        ...cart,
        ...mappedItem,
        totalProductsCount,
        limitAlerts,
      });

      if (cb) {
        // Call callback passing the cart.
        try {
          cb(response.data.cart);
        } catch (e) {
          Logger.error('Error triggering removeProductCommerce callback', e);
        }
      }
    } else {
      const { errors } = response;
      Logger.error(`Failed to Remove Product ${JSON.stringify(products)}`);
      return errors || [];
    }
    return null;
  };

export default removeProductCommerce;
