import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';

const tabStyles = css`
  .tab-button {
    width: 50%;
  }
  .tab-label,
  .tab-label-rebranding {
    cursor: pointer;
    display: inline-block;
    width: 100%;
    border-top: 2px solid ${colors.white.shade2};
    padding: 10px 0;
    text-align: center;
    background-color: ${colors.gray.shade26};
  }
  .tab-input {
    display: none;
  }
  .tab {
    margin-bottom: 11px;
    min-height: 36px;

    @mixin tabletToDesktop {
      margin-bottom: 25px;
    }
  }
  .tab-container {
    display: inline-block;
    width: 50%;
  }
  input:checked + .tab-label {
    background-color: ${colors.white.shade1};
    color: ${colors.gray.shade5};
    border-top: 2px solid ${colors.green.shade2};
  }
  input:checked + .tab-label-rebranding {
    background-color: ${colors.white.shade1};
    color: ${colors.gray.shade5};
    border-top: 2px solid ${colors.gray.shade45};
  }
`;

export { tabStyles };
