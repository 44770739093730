import css from 'styled-jsx/css';
import { colors, icons } from '../../config/styles';
import { svgUrl } from '../../utils/staticUrls';
import { svgToDataUri } from '../../utils/styles/svgMixins';

const ShippingDetailsStyles = css`
  .shipping-details {
    height: auto;

    li {
      display: flex;
      margin-bottom: 9px;

      @mixin mobileToTablet {
        align-items: baseline;
      }
    }

    .icon {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 0;

      @mixin tabletToDesktop {
        margin-bottom: 4px;
      }
    }
    .international-shipping-wrapper {
      margin: 15px 0 14px 0;
      align-items: center;

      span {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.2px;
        color: ${colors.gray.shade40};
      }
    }
    .shipping-text {
      margin-bottom: 5px;
    }
    .boomIcon {
      background-image: url('/a/fa/listing/static/styles/svg/boomIcon.svg');
      background-repeat: no-repeat;
      width: 60px;
      height: 13px;
      margin-bottom: 12px;
      display: flex;
    }
    .csicon-international-shipping {
      margin-right: 7px;
    }
    .international-shipping-icon {
      background-image: url(${svgUrl('aeroplane.svg')});
      background-repeat: no-repeat;
      height: 18px;
      width: 26px;
      margin-right: 9px;
      display: inline-block;
      vertical-align: middle;
    }
    .csicon-succes {
      color: ${colors.green.shade1};
      font-size: 9px;

      @mixin desktop {
        font-size: 11px;
      }
    }
    .rebranding-sucess-icon {
      background: url('/a/fa/listing/static/styles/svg/rebranding-sucess-icon.svg') no-repeat center;
      width: 9px;
      height: 9px;
      background-size: 9px;
      @mixin desktop {
        width: 11px;
        height: 11px;
        background-size: 11px;
        margin-top: 1px;
      }
    }
    .rebranding-sucess-icon-list {
      background: url('/a/fa/listing/static/styles/svg/rebranding-sucess-icon.svg') no-repeat center;
      width: 11px;
      height: 9px;
      background-size: 11px;
      margin-top: 1px;
    }
    .rebranding-sucess-icon-grid {
      background: url('/a/fa/listing/static/styles/svg/rebranding-sucess-icon.svg') no-repeat center;
      width: 14px;
      height: 14px;
      background-size: 14px;
      margin-top: 1px;
    }
    .icon-1_GRID {
      background-size: contain;
      width: 75px;
      height: 15px;
      margin-top: 5px;
      margin-bottom: 13px;
    }
    .csicon-succes-1_GRID {
      font-size: 14px;
    }
    .aeroplane-icon {
      background-image: url('${svgToDataUri(icons.aeroplaneRebranded)}');
      background-repeat: no-repeat;
      height: 18px;
      min-width: 26px;
      width: 26px;
      margin-right: 6px;
      display: inline-block;
      vertical-align: middle;
    }
  }
`;

export default ShippingDetailsStyles;
