import React from 'react';
import PropTypes from 'prop-types';
import { mobileLinkMenuStyle } from './MobileLinkMenu.style';
import noop from '../../../utils/noop';

const MobileLinkMenu = ({ title, links, onSelection }) => {
  return (
    <div className="mobile-link-menu">
      <div className="mobile-link-menu-title">{title}</div>
      {links.map((link) => {
        const activeClassName = link.selected ? { className: 'active' } : {};
        return (
          <button
            key={link.label}
            className="mobile-link-menu-item"
            onClick={() => {
              if (!link.selected) onSelection(link);
            }}
            type="button"
            id={`testId-MobileLinkMenu-${link.label}`}
          >
            {link.selected && <i className="csicon-succes icon-success-mkp" />}
            <span {...activeClassName}>{link.label}</span>
          </button>
        );
      })}
      <style jsx>{mobileLinkMenuStyle}</style>
    </div>
  );
};

MobileLinkMenu.defaultProps = {
  title: '',
  onSelection: noop,
};

MobileLinkMenu.propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
      selected: PropTypes.bool,
    })
  ).isRequired,
  onSelection: PropTypes.func,
};

export default MobileLinkMenu;
