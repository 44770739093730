import constants from '../../config/constants';

const getCallToActionText = (product, shouldShowPlans) => {
  let ctaCopy;
  if (product.viewTemplate === 'CONNECT') {
    ctaCopy = 'VIEW_PREPAID';
    if (shouldShowPlans) {
      ctaCopy = 'VIEW_PLAN';
    }
  } else if (product.variants.length && product.variants[0].options.length > 1) {
    ctaCopy = 'CHOOSE_VARIANTS';
  } else {
    ctaCopy = 'ADD_TO_CART';
  }
  return ctaCopy;
};

const formatUrl = (callToAction, productURL) => {
  if (callToAction === 'VIEW_PLAN') {
    if (productURL.match(/\?/g)) {
      return productURL.replace('product', 'plans');
    }

    return productURL.replace('product', 'plans');
  }

  return productURL;
};

function addSpacesAroundAmpersand(inputString) {
  const modifiedString = inputString.replace(/([^ ])&([^ ])/g, '$1 & $2');
  return modifiedString;
}

const capitalizeText = (text) => {
  const words = addSpacesAroundAmpersand(text).split(' ');
  if (words.length === 1) {
    return `${words[0].charAt(0).toUpperCase()}${words[0].slice(1).toLowerCase()}`;
  }
  let capSentence = '';
  words.forEach((word) => {
    capSentence += `${word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()} `;
  });
  return capSentence;
};

const buildPodUrl = (url = '', skuId = '') => {
  if (url.split('?').length > 1 && skuId) {
    const productUrlWithoutQueryParams = url.split('?')[0];
    const queryParamsInProductUrl = url.split('?')[1];
    const finalProductUrl = `${productUrlWithoutQueryParams}/${skuId}${
      queryParamsInProductUrl ? `?${queryParamsInProductUrl}` : ''
    }`;
    return finalProductUrl;
  }
  return `${url}${skuId ? '/' : ''}${skuId}`;
};

const isColorVariant = (variant) => variant.type === 'COLOR';

const getSelectedColorIndex = (product) => {
  const colorVariants = product.variants.filter((variant) => isColorVariant(variant));
  const swatchIndex = colorVariants?.[0]?.options?.findIndex((option) => option.selected);

  return swatchIndex && swatchIndex >= 0 ? swatchIndex : 0;
};

const getSellerIconClass = (sellerName = '') => {
  const sellerNameLower = sellerName.toLowerCase();
  let sellerClass = '';
  if (constants.FALABELLA_REGEX.test(sellerNameLower)) sellerClass = 'falabella';
  else if (constants.SODIMAC_REGEX.test(sellerNameLower)) {
    sellerClass = 'sodimac';
  } else if (constants.HOMECENTER_REGEX.test(sellerNameLower)) {
    sellerClass = 'homecenter';
  } else if (constants.TOTUS_REGEX.test(sellerNameLower)) sellerClass = 'tottus';
  else if (constants.LINIO_REGEX.test(sellerNameLower)) sellerClass = 'linio';
  return sellerClass;
};

export {
  buildPodUrl,
  capitalizeText,
  formatUrl,
  getCallToActionText,
  getSelectedColorIndex,
  getSellerIconClass,
  isColorVariant,
};
