import css from 'styled-jsx/css';
import { typography, colors } from '../../config/styles';

const ProductColorSwatchesStyles = css`
  .color-swatch-container {
    position: relative;
    display: flex;
    margin-bottom: 13px;

    .expand-swatch {
      z-index: 22;
      position: absolute;
      right: 0;
      top: 18px;
      font-size: ${typography.xs};
    }
  }
  .mkp-color-swatch {
    margin-bottom: 17px;
  }
  .color-swatch-HARDLINE {
    width: 100%;
    @mixin tabletToDesktop {
      max-width: 305px;
    }

    &-collapse {
      display: block;
      max-height: 77px;
      @mixin desktop {
        max-height: 100px;
      }

      overflow: hidden;
      position: relative;

      &::after {
        content: '';
        box-shadow: inset 0 -22px 13px 0 ${colors.white.shade1};
        z-index: 2;
        height: 30px;
        width: 100%;
        position: absolute;
        bottom: 0;
      }
    }
  }

  .color-swatch-SOFTLINE {
    width: 100%;

    &-collapse {
      max-height: 70px;
      overflow: hidden;
      position: relative;

      &::after {
        content: '';
        box-shadow: inset 0 -17px 13px 0 ${colors.white.shade1};
        height: 20px;

        @mixin desktop {
          height: 30px;
          box-shadow: inset 0 -24px 11px 0 ${colors.white.shade1};
        }

        width: 100%;
        position: absolute;
        bottom: 0;
      }
    }
  }
`;

export { ProductColorSwatchesStyles };
