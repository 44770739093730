import css from 'styled-jsx/css';
import { colors, typography, zIndex } from '../../../config/styles';

const mobileMenuStyles = css`
  ul {
    position: relative;
    margin-bottom: 2rem;
    li {
      background-color: ${colors.white.shade1};
      cursor: pointer;
      button {
        display: flex;
        align-items: center;
        text-align: left;
        font-size: ${typography.sm};
        text-transform: uppercase;
        padding: 17px 24px 16px 22px;
        width: 100%;
        font-weight: 400;
        font-family: inherit;
        color: ${colors.gray.shade15};
        &.disabled {
          pointer-events: none;
          color: ${colors.gray.shade2};
        }
        &.highlighted {
          font-weight: 700;
          color: ${colors.gray.shade15};
          background-color: ${colors.gray.shade6};
        }
      }
      .new-badge {
        height: 18px;
        width: 50px;
        background: #1e364b;
        font-weight: 700;
        color: ${colors.white.shade1};
        border-radius: 9px;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.4px;
        padding: 3px 6px;
        margin-left: 8px;
      }
      .badge-title {
        display: flex;
        align-items: center;
      }
    }
  }
  .facet-title {
    flex-grow: 1;
  }
  .mkp-facet-title {
    font-size: 13px;
    color: ${colors.gray.shade40};
    font-weight: 700;
    letter-spacing: 0.5px;
  }
  .mkp-arrow {
    margin-left: 11px;
  }
  .count {
    background: ${colors.black.shade1};
    border-radius: 50px;
    color: ${colors.white.shade1};
    width: 20px;
    height: 20px;
    text-align: center;
    padding: 2px 0;
    margin-right: 12px;
  }
  .mkp-count {
    background: ${colors.blue.shade3};
    border-radius: 15px;
    color: ${colors.white.shade1};
    width: 20px;
    height: 20px;
    text-align: center;
    padding: 2px 0;
    margin-right: 12px;
  }
  section {
    font-size: ${typography.sm};
    pointer-events: auto;
    text-transform: uppercase;
    color: ${colors.gray.shade15};
    font-weight: 400;
    z-index: ${zIndex.factesMobileMenu.child};
    overflow-y: auto;
    background: ${colors.white.shade1};
  }
  .multi-pane-section {
    position: fixed;
    right: 0;
    top: 67px;
    height: calc(100vh - 60px * 2);
    width: 84%;
    box-shadow: 1px -4px 12px rgba(0, 0, 0, 0.15);
  }
  .singlePaneMobileMenu {
    li:last-child {
      border-top: 1px solid #f0f0f0;
    }
    .title-container {
      display: flex;
      align-items: center;
      padding-right: 20px;
      .facet-title {
        padding: 17px 24px 16px 22px;
      }
      i {
        font-size: 20px;
      }
    }
    &.noBorder {
      li {
        border: none;
      }
    }
  }
`;

export { mobileMenuStyles };
