import { isTottusStandalone } from '@digital-retail/store-manager';
import cn from 'classnames';
import Router from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../config/constants';
import { storeMap } from '../../config/storeMap';
import WebStorage from '../../utils/WebStorage';
import noop from '../../utils/noop';
import { replacePathsToStandalonePaths } from '../../utils/store.helpers';
import { resetDigitalData } from '../Facets/Common/digitalData';
import { paginationBodyStyle, paginationCommonStyle, paginationItemStyle } from './Pagination.style';

const Pagination = ({
  getPaginatedUrl,
  resultsPerPage,
  totalResults,
  currentPage,
  neighborButtonCount,
  section,
  tenant,
  setBusy,
  pageName,
  setStopScroll,
  store,
}) => {
  const totalPages = Math.ceil(totalResults / resultsPerPage);
  const onPageSelection = (url) => {
    WebStorage.setItem({
      name: constants.SESSION_STORAGE_NEW_PAGE,
      value: 'true',
    });
    setStopScroll(false);
    let href;
    let asHref;
    const site = tenant;
    setBusy(true);
    let newTenant = tenant;
    const splitTenant = tenant.split('-');
    if (store && store !== 'tienda') {
      newTenant = `${storeMap[store]}-${splitTenant[1]}`;
    }
    if (pageName === 'search') {
      const newUrl = url
        .replace(`/${newTenant}/${replacePathsToStandalonePaths(pageName, store)}?`, '')
        .replace(`/${newTenant}/${replacePathsToStandalonePaths(pageName, store)}/?`, '');
      href = `/${replacePathsToStandalonePaths(pageName, store)}?site=${site}&${newUrl}`;
      asHref = `/${store && store !== 'tienda' ? newTenant : site}/${replacePathsToStandalonePaths(pageName, store)}${
        newUrl ? `?${newUrl}` : ''
      }`;
    } else {
      const newPath = url.replace(`/${newTenant}/${replacePathsToStandalonePaths(pageName, store)}/`, '');
      const params = newPath.split('?');
      const splitUrl = params[0].split('/');
      const categoryId = splitUrl[0];
      const categoryName = splitUrl[1];
      href = `/${replacePathsToStandalonePaths(pageName, store)}?site=${tenant}&id=${categoryId}&name=${categoryName}&${
        params[1]
      }`;
      asHref = url;
    }
    Router.push(href, asHref);
  };

  const renderPaginationItem = (pageIndex, key, disabled = false) => {
    let paginationItemClassName = 'pagination-item';

    paginationItemClassName += ` pagination-item-mkp${disabled ? ' disabled' : ''}`;

    const paginationButtonClass = 'pagination-button-mkp';
    return (
      <>
        <li key={key} className={paginationItemClassName}>
          <button
            className={paginationButtonClass}
            id={`testId-pagination-${section}-button${pageIndex}`}
            onClick={() => {
              onPageSelection(getPaginatedUrl(pageIndex));
              resetDigitalData();
            }}
            type="button"
            disabled={disabled}
          >
            {pageIndex}
          </button>
        </li>
        <style jsx>{paginationCommonStyle}</style>
        <style jsx>{paginationItemStyle}</style>
      </>
    );
  };

  const renderNeighborButtons = (type) => {
    const neighborCount = [1, totalPages].includes(currentPage) ? 2 : neighborButtonCount;
    let startIndex = 0;
    let endIndex = 0;

    if (type === 'LEFT') {
      startIndex = currentPage - neighborCount;
      startIndex = startIndex > 1 ? startIndex : 2;
      endIndex = currentPage - 1;
    }

    if (type === 'RIGHT') {
      startIndex = currentPage + 1;
      endIndex = currentPage + neighborCount;
    }

    const indexes = [];
    for (let i = startIndex; i <= endIndex && i < totalPages; i += 1) {
      indexes.push(i);
    }

    return indexes.map((e) => {
      return renderPaginationItem(e, `${type}${e}`);
    });
  };

  const pageSelectedClass = 'selected';
  const ellipsesClass = 'ellipses';
  const arrowLeftClass = `csicon-arrow_left arrow_left-mkp${currentPage === 1 ? ' disabled' : ''}`;
  const arrowRightClass = `csicon-arrow_right arrow_right-mkp${currentPage === totalPages ? ' disabled' : ''}`;

  return (
    <div className="pagination">
      {/* Left Arrow */}
      <div className="arrow">
        <button
          type="button"
          id={`testId-pagination-${section}-arrow-left`}
          onClick={() => {
            onPageSelection(getPaginatedUrl(currentPage - 1));
          }}
          disabled={currentPage === 1}
        >
          <i className={arrowLeftClass} />
        </button>
      </div>

      {/* Pagination Button */}
      <ol>
        {currentPage !== 1 && renderPaginationItem(1, 'LEFT1')}
        {currentPage - 2 > neighborButtonCount && totalPages > 4 && <li className={ellipsesClass}>...</li>}
        {renderNeighborButtons('LEFT', currentPage)}
        <li className={cn(pageSelectedClass, { tottus: isTottusStandalone(store) })}>{currentPage}</li>
        {renderNeighborButtons('RIGHT', currentPage)}
        {totalPages - (currentPage + 1) > neighborButtonCount && totalPages > 4 && (
          <li className={ellipsesClass}>...</li>
        )}
        {currentPage !== totalPages &&
          renderPaginationItem(
            totalPages,
            `RIGHT${totalPages}`,
            totalPages - (currentPage + 1) > neighborButtonCount && totalPages > 4
          )}
      </ol>

      {/* Right Arrow */}
      <div className="arrow">
        <button
          type="button"
          id={`testId-pagination-${section}-arrow-right`}
          onClick={() => {
            onPageSelection(getPaginatedUrl(currentPage + 1));
          }}
          disabled={currentPage === totalPages}
        >
          <i className={arrowRightClass} />
        </button>
      </div>
      <style jsx>{paginationCommonStyle}</style>
      <style jsx>{paginationBodyStyle}</style>
    </div>
  );
};

Pagination.propTypes = {
  getPaginatedUrl: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  resultsPerPage: PropTypes.number.isRequired,
  totalResults: PropTypes.number.isRequired,
  neighborButtonCount: PropTypes.number,
  section: PropTypes.string,
  tenant: PropTypes.string.isRequired,
  setBusy: PropTypes.func.isRequired,
  pageName: PropTypes.string.isRequired,
  setStopScroll: PropTypes.func,
  store: PropTypes.string,
};

Pagination.defaultProps = {
  neighborButtonCount: 1,
  section: '',
  setStopScroll: noop,
  store: '',
};

export default Pagination;
