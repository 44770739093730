import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const ProductCounterContext = createContext(undefined);
const ProductCounterProvider = ({ children, value }) => (
  <ProductCounterContext.Provider value={value}>{children}</ProductCounterContext.Provider>
);
const useProductCounterContext = () => {
  const context = useContext(ProductCounterContext);

  if (!context) {
    throw new Error('useProductCounterContext must be used within a ProductCounterProvider');
  }

  return context;
};

ProductCounterProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  value: PropTypes.object.isRequired,
};

export { ProductCounterProvider, useProductCounterContext };
