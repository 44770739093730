import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../ui/Button/Button';
import { actionsStyles } from './FacetsMobile.style';
import noop from '../../../utils/noop';
import { withLabels } from '../../../utils/LabelsContext';
import { setDigitalData } from '../../SelectedFilters/helpers';

const FacetsMobileFooter = ({
  facetMobileDialogAction,
  hideMenu,
  isGroupedFacets,
  labels,
  cleanSelectedFacets,
  readyButtonType,
  readyButtonSize,
  count,
  appliedFacets,
  digitalDataSetter,
  isSelectedFacetProductCountVisible,
}) => {
  const handleFacetApply = () => {
    hideMenu();
    facetMobileDialogAction({
      action: 'APPLY',
      isGroupedFacets,
    });
    digitalDataSetter('', true);
  };
  const renderCount = () => {
    if (typeof count === 'number') {
      return count.toLocaleString('en-US').replace(/,/g, '.');
    }
    return count;
  };
  const cleanLabel = labels.CLEAN;
  const readyLabel = labels.READY;
  const resultsLabel = labels.RESULTS && labels.RESULTS.toLowerCase();
  const hasAppliedFacets = appliedFacets.filter((facet) => facet.visible === true);
  return (
    <div className="actions">
      <div className="button-container">
        {hasAppliedFacets.length > 0 && isSelectedFacetProductCountVisible && (
          <div className="total-results">{`${renderCount()} ${resultsLabel}`}</div>
        )}
        <div className="buttons">
          <div className="actions-btn link">
            <Button
              type="secondary-link-mkp"
              isHighlighted
              onClick={() => {
                cleanSelectedFacets();
                setDigitalData();
              }}
            >
              {cleanLabel}
            </Button>
          </div>
          <div className={`actions-btn actions-${readyButtonType}`}>
            <Button type="mkp-secondary" onClick={handleFacetApply} size={readyButtonSize}>
              {readyLabel}
            </Button>
          </div>
        </div>
      </div>
      <style jsx>{actionsStyles}</style>
    </div>
  );
};

FacetsMobileFooter.defaultProps = {
  facetMobileDialogAction: noop,
  hideMenu: noop,
  isGroupedFacets: false,
  labels: {},
  cleanSelectedFacets: noop,
  readyButtonType: 'primary',
  readyButtonSize: 'medium',
  count: 0,
  appliedFacets: [],
};

FacetsMobileFooter.propTypes = {
  facetMobileDialogAction: PropTypes.func,
  hideMenu: PropTypes.func,
  isGroupedFacets: PropTypes.bool,
  labels: PropTypes.object,
  cleanSelectedFacets: PropTypes.func,
  readyButtonType: PropTypes.string,
  readyButtonSize: PropTypes.string,
  count: PropTypes.number,
  appliedFacets: PropTypes.array,
  digitalDataSetter: PropTypes.func.isRequired,
  isSelectedFacetProductCountVisible: PropTypes.func.isRequired,
};

export default withLabels(FacetsMobileFooter);
export { FacetsMobileFooter };
