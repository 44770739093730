import React from 'react';
import PropTypes from 'prop-types';
import BestSellerribbonStyle from './BestSellerRibbon.style';

const BestSellerRibbon = ({ label }) => {
  return (
    <div className="ribbonBox">
      <span>{label}</span>
      <style jsx>{BestSellerribbonStyle}</style>
    </div>
  );
};

BestSellerRibbon.defaultProps = {
  label: 'TOP EN VENTAS',
};

BestSellerRibbon.propTypes = {
  label: PropTypes.string,
};

export default BestSellerRibbon;
