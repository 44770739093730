import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SizeDropdownStyles } from './SizeDropdown.style';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import WithOutsideClick from '../../../utils/WithOutsideClick';
import noop from '../../../utils/noop';
import { withLabels } from '../../../utils/LabelsContext';

const SizeDropdown = ({ sizes, selectedSize, onSizeChange, labels, variant }) => {
  const [toggle, setToggle] = useState(false);
  const toggleMenu = () => {
    setToggle(!toggle);
  };

  return (
    <div className={`size-container ${variant}`}>
      <button id="testId-size-dropdown-button" className="menu-button" type="button" onClick={toggleMenu}>
        {selectedSize || labels.SELECTION_SIZE}
        <i className={`icon ${toggle ? 'csicon-arrow_up_small' : 'csicon-arrow_down_small'}`} />
      </button>
      {toggle && (
        <WithOutsideClick
          onOutsideClick={(e) => {
            e.stopPropagation();
            toggleMenu();
          }}
        >
          <div className="list">
            {sizes.map(({ value }) => {
              return (
                <button
                  type="button"
                  key={`${value}`}
                  className={`list-item mkp} ${value === selectedSize ? 'list-item-selected' : ''}`}
                  onClick={() => {
                    onSizeChange(value);
                    toggleMenu();
                  }}
                >
                  {value}
                </button>
              );
            })}
          </div>
        </WithOutsideClick>
      )}
      <style jsx>{SizeDropdownStyles}</style>
    </div>
  );
};

SizeDropdown.defaultProps = {
  sizes: [],
  onSizeChange: noop,
  selectedSize: '',
  variant: '',
};

SizeDropdown.propTypes = {
  sizes: PropTypes.arrayOf(PropTypes.object),
  labels: PropTypes.object.isRequired,
  onSizeChange: PropTypes.func,
  selectedSize: PropTypes.string,
  variant: PropTypes.oneOf(['', 'medium']),
};

export { SizeDropdown };
export default withLabels(withApplicationContext(SizeDropdown));
