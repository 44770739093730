import css from 'styled-jsx/css';

const badgesStyles = css`
  /* stylelint-disable declaration-no-important */
  .discount-badge {
    display: flex;
    &-item {
      padding: 2px 5px;
      border-radius: 3px;
      margin-right: 0 !important;
      line-height: 1.44rem !important;
      margin-left: 4px;
    }
  }
`;

export default badgesStyles;
