import React from 'react';
import PropTypes from 'prop-types';
import { ratingsVisualGlobalStyles, halfStarRatingVisualStyles } from './RatingsVisual.style';

const RatingsVisual = (props) => {
  const { maximum, value, size, productId } = props;
  const starMarkup = [...Array(maximum).keys()];
  const markedStars = Math.round(value * 2) / 2;
  const renderHalfStar = (i) => {
    return (
      <i key={`star-${i}`} className="csicon-star_half_filled">
        <span className="path1" />
        <span className="path2" />
        <style jsx>{halfStarRatingVisualStyles}</style>
      </i>
    );
  };
  const renderFullStar = (i) => {
    return <i key={`star-${i}`} className="csicon-star_full_filled" />;
  };
  const renderNoStar = (i) => {
    return <i key={`star-${i}`} className="csicon-star_no_filled" />;
  };
  const checkHalfStar = (i) => {
    return i + 1 - markedStars === 0.5 ? renderHalfStar(i) : renderNoStar(i);
  };
  return (
    <div data-rating={value} className="ratings">
      <div id={`testId-Pod-Rating-${productId}`} className={`ratings--container ${size}`}>
        {starMarkup.map((i) => {
          return i + 1 <= markedStars ? renderFullStar(i) : checkHalfStar(i);
        })}
      </div>
      <style jsx>{ratingsVisualGlobalStyles}</style>
    </div>
  );
};

RatingsVisual.defaultProps = {
  maximum: 5,
  value: 0,
  size: 'normal',
  productId: '',
};

RatingsVisual.propTypes = {
  maximum: PropTypes.number,
  value: PropTypes.number,
  productId: PropTypes.string,
  size: PropTypes.oneOf(['large', 'normal', 'xlarge']),
};

export default RatingsVisual;
