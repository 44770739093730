import { RestrictedVariantModal } from '@digital-retail/falabella-ui-cross-components';
import {
  CARTLINE_QUANTITIES_COOKIE_NAME,
  getMaxQuantityString,
  parseCartQuantities,
} from '@digital-retail/store-manager';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import constants from '../../../config/constants';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withCart } from '../../../utils/CartContext';
import CookieStorage from '../../../utils/CookieStorage';
import { isWindow } from '../../../utils/deviceChecker';
import _ from '../../../utils/LodashImports';
import { disableScroll, enableScroll } from '../../../utils/manageScroll';
import noop from '../../../utils/noop';
import { withUser } from '../../../utils/UserContext';
import { addToCartButtonStyle } from './AddToCartButton.style';

const AddToCartButton = ({
  addToCart,
  appCtx,
  cart,
  colorIndex,
  isAddToCartInProgress,
  product,
  setIsAddToCartInProgress,
  tick,
}) => {
  const { skuId } = product;
  const { setBusy } = appCtx;
  const timeputForLoginModalOn401 = _.get(appCtx, 'siteConfig.textDictionary.TIMEOUT_FOR_LOGIN_MODAL', 1000);
  const isRestrictedVariantModalEnabled = _.get(appCtx, 'siteConfig.toggles.isRestrictedVariantModalEnabled', false);
  const restrictedVariantModalText = _.get(
    appCtx,
    'siteConfig.textDictionary.RESTRICTED_VARIANT_MODAL_TEXT',
    'Para comprar este producto debes ser mayor de 18 años.'
  );
  const cartCookieValue = isWindow()
    ? CookieStorage.getCookie(CARTLINE_QUANTITIES_COOKIE_NAME)
    : CookieStorage.parseCookies(appCtx.ssrCookies)[CARTLINE_QUANTITIES_COOKIE_NAME];
  const [show18PlusModal, setShow18PlusModal] = useState(false);
  const [hasReachedMaxQty, setHasReachedMaxQty] = useState(false);
  const { cartItems, removeProduct: onRemoveFromCart, limitAlerts } = cart;
  const item = cartItems?.[0]?.skuId === skuId ? cartItems[0] : {};
  const { totalQuantity = 0, commerceItemId, cartLineId = '' } = item;
  const [inputVal, setInputVal] = useState(totalQuantity);
  const [cartLineIdValue, setCartLineIdValue] = useState(cartLineId);
  const [limit, setLimit] = useState(product?.measurements?.maxUnits || 999);
  const minSaleQty = product?.measurements?.minSaleQty || 1;
  const unit = product?.measurements?.unit || 'UN';

  useEffect(() => {
    setBusy(isAddToCartInProgress);
  }, [isAddToCartInProgress]);

  useEffect(() => {
    const quantitiesMap = parseCartQuantities(cartCookieValue || '');
    if (cart?.cartItems?.[0]?.skuId === skuId) {
      const skuCartItems = cart.cartItems[0];
      const skuQtyLimits = skuCartItems.qtyLimits;
      const skuTotalQty = skuCartItems.totalQuantity;
      setInputVal(skuTotalQty);
      if (skuQtyLimits) {
        setLimit(skuQtyLimits);
      }
      setHasReachedMaxQty(skuTotalQty >= limit);
    } else if (quantitiesMap.has(skuId)) {
      const {
        quantity = totalQuantity,
        cartLineId: cookieCartLineId = cartLineId,
        quantityLimit,
      } = quantitiesMap.get(skuId);
      setInputVal(quantity);
      setCartLineIdValue(cookieCartLineId);
      setLimit(quantityLimit);
      setHasReachedMaxQty(quantity >= quantityLimit);
    } else {
      setInputVal(0);
      setCartLineIdValue('');
      setHasReachedMaxQty(false);
    }
  }, [cart, skuId, cartCookieValue]);

  useEffect(() => {
    if (limitAlerts && (limitAlerts[cartLineId] || limitAlerts.basket)) {
      if (limitAlerts[cartLineId]) {
        setHasReachedMaxQty(true);
      }
    }
  }, [limitAlerts]);

  const handleRemoveItem = () => {
    setIsAddToCartInProgress(true);
    const option = {
      appCtx,
    };

    option.products = [
      {
        cartLineId: cartLineId || cartLineIdValue,
        totalQuantity: Number(inputVal),
        commerceItemId,
        quantity: 1,
      },
    ];

    onRemoveFromCart(option)
      .then((res) => {
        setIsAddToCartInProgress(false);
        if (res) {
          const errorCode = _.get(res, '[0].code', '');
          if (errorCode === constants.ATC_SESSION_EXPIRED_CODE) {
            setTimeout(() => {
              cart.setShowCart(false);
              cart.setShowLoginForm(true);
            }, timeputForLoginModalOn401);
          }
        }
      })
      .catch(() => {
        setIsAddToCartInProgress(false);
      });
  };

  const handleAddItem = () => {
    const isUserOver18 = CookieStorage.getCookie(constants.IS_USER_OVER_18);
    const restriction = (product.restrictions || '').toLowerCase();
    if (isRestrictedVariantModalEnabled && restriction === 'adult' && isUserOver18 !== 'true') {
      setShow18PlusModal(true);
    } else if (!hasReachedMaxQty) {
      addToCart(colorIndex);
    }
  };
  const roundOffPrice = (value, precision = 2) => {
    const power = 10 ** (precision || 0);
    return Math.round(value * power) / power;
  };
  const displayUnit = () => {
    return `${roundOffPrice(inputVal * minSaleQty)} ${unit}`.toLowerCase();
  };

  const preventParentEvents = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
  };

  useEffect(() => {
    if (appCtx.deviceType !== 'desktop') {
      if (show18PlusModal) {
        disableScroll();
      } else {
        enableScroll();
      }
    }
  }, [show18PlusModal]);

  return (
    <>
      <div
        key={`add-to-cart-button-${product.offeringId}`}
        id={`add-to-cart-button-${product.offeringId}`}
        role="button"
        onKeyDown={noop}
        className="add-to-cart"
        onClick={(e) => preventParentEvents(e)}
        tabIndex={0}
      >
        <button
          id="remove-from-cart-button"
          className="remove-from-cart-button"
          type="button"
          onClick={handleRemoveItem}
          hidden={!inputVal}
        >
          {inputVal > 1 ? <span className="remove-from-cart-minus-icon" /> : <span className="remove-from-cart-icon" />}
        </button>
        <div className="count-box" hidden={!inputVal}>
          {displayUnit()}
        </div>

        <button
          type="button"
          className={`add-to-cart-button ${hasReachedMaxQty ? 'disabled' : ''}`}
          onClick={handleAddItem}
        >
          <span className="add-to-cart-icon" />
        </button>
      </div>
      <div className="quantity-display">
        {hasReachedMaxQty ? (
          <div className="max-qnty">{getMaxQuantityString({ maxUnits: limit * minSaleQty, unit })}</div>
        ) : (
          <div className="empty-quantity-display-space" />
        )}
      </div>
      <div
        key="a2cRestrictedModal"
        id="a2cRestrictedModal"
        role="button"
        onKeyDown={noop}
        className="add-to-cart"
        onClick={(e) => preventParentEvents(e)}
        tabIndex={0}
      >
        {isRestrictedVariantModalEnabled && (
          <RestrictedVariantModal
            deviceType={appCtx.deviceType}
            show18PlusModal={show18PlusModal}
            onCloseModal={() => setShow18PlusModal(false)}
            handleAddToCart={() => {
              setShow18PlusModal(false);
              handleAddItem();
            }}
            modalText={restrictedVariantModalText}
          />
        )}
      </div>
      <style jsx>{addToCartButtonStyle}</style>
    </>
  );
};

AddToCartButton.defaultProps = {
  cart: {},
};

AddToCartButton.propTypes = {
  product: PropTypes.object.isRequired,
  addToCart: PropTypes.func.isRequired,
  colorIndex: PropTypes.func.isRequired,
  appCtx: PropTypes.object.isRequired,
  cart: PropTypes.object,
  isAddToCartInProgress: PropTypes.bool.isRequired,
  setIsAddToCartInProgress: PropTypes.bool.isRequired,
  tick: PropTypes.number.isRequired,
};

export { AddToCartButton };
export default withApplicationContext(withCart(withUser(AddToCartButton)));
