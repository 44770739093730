import PropTypes from 'prop-types';
import React from 'react';
import BodyCopy from '../ui/BodyCopy/BodyCopy';
import badgesStyles from './Badges.style';
import stickersStyles from './Stickers.style';

const Badges = ({ badgeItems, deviceType, layout, isSticker }) => {
  const getBadgeSize = () => {
    if (deviceType === 'mobile') {
      return layout.indexOf('PDP') !== -1 ? 'copy4' : 'copy7';
    }
    return 'copy8';
  };
  return (
    <div className={`pod-badges pod-badges-${layout}${isSticker ? ' sticker-group' : ''}`}>
      {badgeItems?.map((badge) => (
        <BodyCopy size={getBadgeSize()} key={badge.label} isHighlighted>
          {(classes) => (
            <span
              id={`testId-Pod-badges-${badge.label}`}
              className={`${classes} pod-badges-item-${layout} pod-badges-item`}
              style={{
                backgroundColor: `${badge.styles.backgroundColor}`,
                color: `${badge.styles.textColor}`,
              }}
            >
              {badge.label}
            </span>
          )}
        </BodyCopy>
      ))}
      <style jsx>{badgesStyles}</style>
      <style jsx>{stickersStyles}</style>
    </div>
  );
};

Badges.propTypes = {
  badgeItems: PropTypes.array,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
  layout: PropTypes.string,
  isSticker: PropTypes.bool,
};

Badges.defaultProps = {
  badgeItems: [],
  layout: '',
  isSticker: false,
};

export default Badges;
