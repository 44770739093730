import css from 'styled-jsx/css';
import { svgUrl } from '../../utils/staticUrls';

export const puntospesos = css`
  i.puntospesos-icon {
    display: inline-block;
    height: 12px;
    width: 25px;
    vertical-align: bottom;
    background-image: url(${svgUrl('puntospesos.svg')});
    margin: 0 2px;
  }
`;

const styles = css`
  .mtp-pod-badges {
    padding-top: 4px;
    @mixin mobileToTablet {
      height: 24px;
    }

    &-LIST {
      padding: 7px 0 6px 0;
      height: 32px;
      @mixin mobileToTablet {
        height: 38px;
        padding: 16px 0 3px 0;
      }
    }
    .pod-badges-item {
      padding: 2px 4px;
      border-radius: 3px;
      line-height: 14px;
      margin-left: 4px;

      &.inPdp {
        @mixin mobileToTablet {
          line-height: 17px;
          font-size: 14px;
        }
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
`;

export default styles;
