import css from 'styled-jsx/css';
import { typography } from '../../config/styles/index';

const stickersStyles = css`
  .sticker-group {
    padding-left: 3px;
    padding-right: 3px;
    &.pod-badges-PDP {
      padding-left: 30px;
      &.SOFTLINE {
        padding-left: 0;
        margin-bottom: 3px;
        margin-top: 12px;
      }
    }
    &.pod-badges-LIST {
      padding-left: 0;
      padding-right: 0;
    }
    @mixin mobileToTablet {
      padding-left: 4px;
      padding-right: 4px;

      &.pod-badges-PDP {
        padding-left: 4px;
        &.SOFTLINE {
          padding-left: 0;
          margin-bottom: 0;
          margin-top: 0;
        }
      }
    }
    .pod-badges-item {
      text-transform: none;
      border-radius: 3px;
      letter-spacing: 0;
      padding: 1px 6px 3px 6px;
      margin-right: 4px;
      line-height: 1.7rem;
      &-4_GRID,
      &-LIST {
        font-size: ${typography.base};
      }
      &-PDP {
        font-size: ${typography.base};
        font-weight: 700;
      }
      @mixin mobileToTablet {
        font-size: 10px;
        line-height: 1.3rem;
        &-LIST {
          line-height: 1.4rem;
          font-size: ${typography.sm};
          margin-bottom: 4px;
          width: auto;
          margin-right: 0;
        }
        &-PDP {
          font-size: ${typography.base};
          font-weight: 700;
          line-height: 1.7rem;
          margin-right: 0;
          margin-bottom: 4px;
          width: auto;
        }
      }
      @mixin mobileToTablet {
        &-2_GRID,
        &-LIST {
          font-weight: 700;
        }
      }
      @mixin tabletOnly {
        white-space: nowrap;
        width: auto;
        font-size: 1.3rem;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export default stickersStyles;
