import css from 'styled-jsx/css';
import { svgToDataUri } from '../../../../utils/styles/svgMixins';

const envioGratisIcon = `<svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.0555 2.06933L23.2525 8.09333L22.4445 13.9293H19.521C19.2631 15.112 18.2106 16 16.9491 16C15.6863 16 14.6351 15.112 14.3759 13.9293H13.9356H12.7958L7.09144 13.9293C6.83359 15.112 5.78105 16 4.51959 16C3.25812 16 2.2069 15.112 1.94641 13.9293H0L1.88294 0L15.8199 0L15.5395 2.06933L19.0555 2.06933ZM19.5355 12.8629H21.5242L22.147 8.36291L21.8931 8.00024L16.5722 8.00024L16.5722 4.51758H19.4667L18.5054 3.13624L15.443 3.13624L14.799 7.54158L14.0797 12.8629H14.3627C14.598 11.6482 15.6651 10.7282 16.9491 10.7282C18.233 10.7282 19.3001 11.6482 19.5355 12.8629ZM16.9491 14.933C16.0777 14.933 15.3702 14.2277 15.3702 13.3637C15.3702 12.4997 16.0777 11.7944 16.9491 11.7944C17.8191 11.7944 18.5279 12.4997 18.5279 13.3637C18.5279 14.2277 17.8191 14.933 16.9491 14.933ZM4.51964 10.728C5.80358 10.728 6.87067 11.648 7.10604 12.8627L12.9519 12.8627L13.7519 7.39067L14.6074 1.06534L2.80595 1.06534L1.21127 12.8627H1.93456C2.16861 11.648 3.2357 10.728 4.51964 10.728ZM4.51919 14.933C3.6478 14.933 2.94038 14.2277 2.94038 13.3637C2.94038 12.4997 3.6478 11.7944 4.51919 11.7944C5.38926 11.7944 6.098 12.4997 6.098 13.3637C6.098 14.2277 5.38926 14.933 4.51919 14.933ZM17.6299 6.93335L20.7691 6.93335L19.7985 5.58401H17.6299V6.93335Z" fill="#4A4A4A"/>
</svg>`;

const styles = css`
  .facet-with-switch {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 48px;
    background: #f8f8f8;
    border: 1px solid #ededed;
    border-radius: 8px;
    padding: 12px 12px 12px 16px;

    @mixin mobileToTablet {
      margin-top: 0;
      padding: 11px 16px 8px;
    }
    /* stylelint-disable declaration-no-important, length-zero-no-unit */
    &.event-mobile-container {
      border-radius: 0px !important;
      background: #fff;
    }
  }

  .heading-section {
    display: flex;
    align-items: baseline;
  }

  .icon-container {
    width: 29.09px;
    height: 16px;
  }
  .icon {
    margin-top: 2px;
    width: 29.09px;
    height: 16px;
    background-image: url('${svgToDataUri(envioGratisIcon)}');
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
  }

  .heading {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    color: #333;
    flex: 1;

    &.shipping {
      color: #30871f;
    }
    &.event-mobile {
      font-size: 14px !important;
      flex: 0 0 33% !important;
    }
  }

  .heading > p {
    font-weight: 700;
  }

  .switch-container {
    width: 44px;
    position: relative;
    margin-top: 2px;
  }

  .label-section {
    width: 195px;
    margin-top: 6px;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #4a4a4a;
    word-wrap: break-word;

    &.event-mobile {
      flex: 0 0 58%;
    }
  }

  .bold {
    font-weight: bold;
  }
`;

export default styles;
