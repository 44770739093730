import React from 'react';
import PropTypes from 'prop-types';
import { IncrementStyles } from './Increment.style';
import { useProductCounterContext } from '../ProductCounterContext';

const Increment = ({ className, maximum, onClick, style, type, id }) => {
  const { count } = useProductCounterContext();
  return (
    <>
      <button
        id={id}
        className={`increment btn-${type} ${className}`}
        disabled={count >= maximum}
        onClick={() => onClick()}
        style={style}
        type="button"
      >
        <span>&#43;</span>
      </button>

      <style jsx>{IncrementStyles}</style>
    </>
  );
};

Increment.defaultProps = {
  className: '',
  id: 'increment-button',
  style: {},
  type: 'primary',
};

Increment.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  maximum: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  type: PropTypes.oneOf(['primary', 'secondary']),
};

export { Increment };
