import css from 'styled-jsx/css';
import { colors, typography } from '../../config/styles/index';

const autoCorrectStyles = css`
  .auto-correct-container {
    padding: 20px 20px 30px 20px;
    background-color: ${colors.white.shade1};
    line-height: 2.7rem;
    flex: 1 0 auto;
    border-bottom: 1px solid ${colors.gray.shade2};

    @mixin tabletToDesktop {
      line-height: 3.6rem;
      padding: 40px 60px 33px;
    }
  }
`;

const linkButtonStyles = css`
  .link-button {
    font-weight: 400;
    font-family: inherit;
  }

  .link-button-size1 {
    font-size: ${typography.xl};
  }

  .link-button-size2 {
    font-size: ${typography.xl3};
  }
  .link-button-size3 {
    font-size: ${typography.xl3};
    letter-spacing: 0.00756rem;
    font-weight: 400;
    line-height: 3.5rem;
  }

  .link-button-primary {
    color: ${colors.green.shade1};
  }
  .link-button-mkp-primary {
    color: var(--secondary-color);
  }
`;

export { autoCorrectStyles, linkButtonStyles };
