import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';
import { svgToDataUri } from '../../../utils/styles/svgMixins';
import { alertIconSvg } from '../../Cart/SelectVariant/SelectVariant.style';

const ProductVariationsStyles = css`
  .popup {
    height: calc(100vh - 120px);
    cursor: initial;
    background-color: ${colors.white.shade1};
    display: flex;
    flex-direction: column;
    @mixin tabletToDesktop {
      height: auto;
      width: auto;
    }
  }

  .header {
    padding: 11px 20px 9.75px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${colors.gray.shade14};

    @mixin tabletToDesktop {
      height: 50px;
      padding: 20px;
    }

    .csicon-icon-success-circle {
      color: ${colors.green.shade1};
      font-size: ${typography.xl3a};
    }

    .label {
      flex-grow: 1;
      margin: 0 14px;
      line-height: 22px;
    }

    .csicon-danger {
      color: ${colors.gray.shade29};
      font-size: ${typography.sm};
    }

    .warning-icon {
      width: 55px;
      height: 27px;
      background-image: url('${svgToDataUri(alertIconSvg)}');
      background-size: contain;
      background-repeat: no-repeat;
      @mixin tabletToDesktop {
        width: 27px;
      }
    }
  }

  .body {
    overflow-x: hidden;
    flex-grow: 1;

    @mixin tabletToDesktop {
      height: auto;
    }

    .collections,
    .bundles {
      border-top: 1px solid ${colors.gray.shade14};

      span {
        margin: 22px 0;
        display: block;
      }
    }

    .collections {
      padding-top: 22px;
    }
  }

  .product-details {
    display: flex;
    border-bottom: 1px solid ${colors.gray.shade14};
    padding: 18px 27px;

    @mixin mobileToTablet {
      padding: 24px 18px 0 27px;
    }
    .product-image {
      box-sizing: border-box;
      height: 89px;
      width: 89px;
      border: 1px solid ${colors.gray.shade43};
      @mixin tabletToDesktop {
        height: 46px;
        width: 46px;
      }
    }
    .item-info {
      margin: 0 18px;
    }
    .info-wrapper {
      display: flex;
      flex-direction: column;
      @mixin tabletToDesktop {
        flex-direction: row;
      }
    }
  }

  .price {
    margin: 21px 18px 0;
    @mixin tabletToDesktop {
      margin: 0;
    }
    &.mt-8px {
      margin-top: 8px;
    }
  }

  .variant-details {
    display: flex;
    padding: 14px 27px;
    flex-direction: column;
    @mixin tabletToDesktop {
      flex-direction: row;
      padding: 27px;
    }

    .color-variants {
      width: 100%;
      margin-right: 28px;
      @mixin tabletToDesktop {
        width: 200px;
      }
    }
    .size-variants {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0;
      @mixin tabletToDesktop {
        width: 500px;
        margin: 0 10px 0 28px;
      }
      .sizes-group {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
      }
      .size-title {
        font-size: 16px;
        font-weight: bold;
        @mixin tabletToDesktop {
          font-size: 14px;
        }
      }
    }
  }
  .addToCart-container {
    background-color: ${colors.white.shade1};

    @mixin tabletToDesktop {
      background: #fbfbfb;
      width: 100%;
      display: flex;
      height: 72px;
      justify-content: flex-end;
      padding-right: 25px;
      bottom: 0;
      padding-top: 20px;
    }

    @mixin mobileToTablet {
      position: fixed;
      padding: 25px;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;
    }

    .cerrar-button {
      margin-right: 20px;
      font-size: ${typography.base};
      .cerrar-button-text {
        letter-spacing: 0.19px;
        line-height: 16px;
        padding-bottom: 1px;
        border-bottom: 0.5px solid ${colors.black.shade9};
      }
      @mixin mobileToTablet {
        margin-top: 13px;
      }
    }
  }

  .addToCart-container-mkp {
    background-color: ${colors.gray.shade41};
    border: 1px solid #f0f0f0;

    @mixin tabletToDesktop {
      background: #fbfbfb;
      width: 100%;
      display: flex;
      height: 72px;
      justify-content: flex-end;
      padding-right: 21px;
      bottom: 0;
      padding-top: 16px;
    }

    @mixin mobileToTablet {
      position: fixed;
      padding: 20px 24px 20px 23px;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column-reverse;
    }

    .cerrar-button {
      margin-right: 20px;
      font-size: ${typography.base};
      .cerrar-button-text {
        letter-spacing: 0.19px;
        line-height: 16px;
        padding-bottom: 1px;
        border-bottom: 0.5px solid ${colors.black.shade9};
      }
      @mixin tabletToDesktop {
        padding-bottom: 16px;
      }
      @mixin mobileToTablet {
        margin-top: 4px;
        margin-right: none;
      }
    }
  }

  .add-to-cart-error {
    button {
      background-color: ${colors.black.shade9};
    }
  }
  .action-buttons {
    @mixin tabletToDesktop {
      margin-bottom: 10px;
      display: flex;
      right: 30px;
      width: 36.5%;
    }
    @mixin mobileToTablet {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .action-buttons-mkp {
    @mixin tabletToDesktop {
      margin-bottom: 10px;
      display: flex;
      right: 30px;
      width: auto;
    }
    @mixin mobileToTablet {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column-reverse;
    }
  }
`;
export { ProductVariationsStyles };
