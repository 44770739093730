import PropTypes from 'prop-types';
import React from 'react';
import ActionBarStyles from './ActionBar.style';

const ActionBar = ({ left, right }) => {
  return (
    <div className="action-bar">
      <div className="action-bar--item">{left}</div>
      <div className="action-bar--item">{right}</div>
      <style jsx>{ActionBarStyles}</style>
    </div>
  );
};

ActionBar.defaultProps = {
  left: null,
  right: null,
};

ActionBar.propTypes = {
  left: PropTypes.element,
  right: PropTypes.element,
};

export default ActionBar;
