import css from 'styled-jsx/css';

export const style = css`
  .fallback {
    display: none;
    @mixin fallback {
      display: inherit;
    }
  }
  .mobile-only {
    @mixin mobileOnly {
      display: inherit;
    }
  }
  .phablet-only {
    display: none;
    @mixin phabletOnly {
      display: inherit;
    }
  }
  .tablet-only {
    display: none;
    @mixin tabletOnly {
      display: inherit;
    }
  }
  .desktop-only {
    display: none;
    @mixin desktop {
      display: inherit;
    }
  }

  .mobile-phablet {
    @mixin mobileToPhablet {
      display: inherit;
    }
  }
  .mobile-tablet {
    @mixin mobileToTablet {
      display: inherit;
    }
  }
  .mobile-desktop {
    @mixin mobileToDesktop {
      display: inherit;
    }
  }

  .phablet-tablet {
    display: none;
    @mixin phabletToTablet {
      display: inherit;
    }
  }
  .phablet-desktop {
    display: none;
    @mixin phabletToDesktop {
      display: inherit;
    }
  }

  .tablet-desktop {
    display: none;
    @mixin tabletToDesktop {
      display: inherit;
    }
  }
`;
