import PropTypes from 'prop-types';
import React from 'react';
import DynamicBanner from '../containers/NoResults/DynamicBanner/DynamicBanner';
import InformationBox from '../containers/NoResults/InformationBox/InformationBox';
import NoResultsStyles from '../containers/NoResults/NoResults.style';
import { withApplicationContext } from '../utils/ApplicationContext';
import { withLabels } from '../utils/LabelsContext';
import { redirectToUrl } from '../utils/RouteHandler';
import WithUrl from '../utils/WithUrl';

const isInternalServerError = (statusCode) => {
  return statusCode.toString().indexOf('5') === 0;
};

const shouldRedirectError = (url, statusCode) => {
  return url && !isInternalServerError(statusCode);
};

class Error extends React.Component {
  static getInitialProps({ appCtx, labels }, { res, err, query }) {
    const site = (query && query.site) || '-';
    const regionCode = site.split('-')[1];
    const statusCode = (res && res.statusCode) || (err && err.statusCode) || 500;
    const url = WithUrl(statusCode, regionCode);
    if (shouldRedirectError(url, statusCode)) {
      return redirectToUrl(url, res);
    }
    return {
      appCtx,
      labels,
    };
  }

  componentDidMount() {
    const { appCtx } = this.props;
    if (appCtx) {
      appCtx.setBusy(false);
    }
  }

  render() {
    const { labels, appCtx } = this.props;
    return (
      <div className="no-result error-page">
        <div className="container">
          <InformationBox title={labels.PAGE_ERROR_TITLE} />
          <DynamicBanner url={WithUrl('BANNER_MC', appCtx.regionCode)} />
          <DynamicBanner url={WithUrl('CATEGORY_BANNER', appCtx.regionCode)} />
        </div>
        <style jsx>{NoResultsStyles}</style>
      </div>
    );
  }
}
Error.defaultProps = {
  labels: {},
};

Error.propTypes = {
  appCtx: PropTypes.object.isRequired,
  labels: PropTypes.object,
};

export { Error, isInternalServerError, shouldRedirectError };
export default withApplicationContext(withLabels(Error));
