import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';

const surveyBoxStyles = css`
  .survey-box {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 63px;
    background-color: ${colors.white.shade1};

    .icon-small {
      font-size: 6px;
      padding-left: 10px;
    }

    .icon-medium {
      font-size: ${typography.lg1};
      padding-right: 8px;
      &::before {
        color: ${colors.green.shade18};
      }
      @mixin mobileToTablet {
        font-size: ${typography.xl2c};
      }
    }

    &:nth-child(2) {
      &::after {
        content: '';
        display: block;
        width: 1px;
        height: 42px;
        background: ${colors.gray.shade36};
        position: absolute;
        left: 0;
        @mixin mobileToTablet {
          display: none;
        }
      }
      @mixin mobileToTablet {
        margin-top: 27px;
      }
    }

    @mixin mobileToTablet {
      width: 100%;
    }
  }

  a.survey-box {
    text-decoration: none;
    color: ${colors.black.shade1};
  }
`;
export default surveyBoxStyles;
