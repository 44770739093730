import PropTypes from 'prop-types';
import React from 'react';
import { withLabels } from '../../../utils/LabelsContext';
import noop from '../../../utils/noop';
import SelectedFilters from '../../SelectedFiltersMobile/SelectedFiltersMobile';
import Accordion from '../Accordion/Accordion';
import { mobileMenuStyles } from './MobileMenu.style';

const MobileMenu = ({
  data,
  pane,
  appCtx,
  handleAccordianClick,
  baseFacetsURL,
  labels,
  appliedFacets,
  makeFacetCall,
  facets,
}) => {
  const selectedFiltersLabel = labels.SELECTED_FILTERS_MOBILE;
  const visibleFilters = appliedFacets.filter((f) => f.visible);
  const renderMultiplePaneView = () => {
    return (
      <>
        {visibleFilters.length > 0 && (
          <SelectedFilters
            filters={visibleFilters}
            appCtx={appCtx}
            baseFacetsURL={baseFacetsURL}
            selectedFiltersLabel={selectedFiltersLabel}
            makeFacetCall={makeFacetCall}
            facets={facets}
          />
        )}
        <Accordion items={data} handleAccordianClick={handleAccordianClick} isMobileAccordian />
        <style jsx>{mobileMenuStyles}</style>
      </>
    );
  };
  const renderSinglePaneView = () => {
    return (
      <>
        <ul className="singlePaneMobileMenu noBorder">
          {data.map(({ title, content }) => {
            if (title) {
              return (
                <li key={title}>
                  <section>{content()}</section>
                </li>
              );
            }
            return null;
          })}
        </ul>
        <style jsx>{mobileMenuStyles}</style>
      </>
    );
  };

  return (
    <>
      <div className="mobileMenuContainer">
        {pane === 'multiple' && renderMultiplePaneView()}
        {pane === 'single' && renderSinglePaneView()}
      </div>
      <style jsx>{mobileMenuStyles}</style>
    </>
  );
};

MobileMenu.defaultProps = {
  handleAccordianClick: noop,
  makeFacetCall: noop,
  pane: 'multiple',
  appCtx: '',
  baseFacetsURL: '',
  labels: {},
  appliedFacets: [],
};

MobileMenu.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  pane: PropTypes.string,
  appCtx: PropTypes.object,
  baseFacetsURL: PropTypes.string,
  labels: PropTypes.object,
  appliedFacets: PropTypes.array,
  handleAccordianClick: PropTypes.func,
  makeFacetCall: PropTypes.func,
  facets: PropTypes.array.isRequired,
};

export default withLabels(MobileMenu);
