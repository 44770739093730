import React from 'react';
import { nameHOC } from './hocutils';

const SkipSSR = (WrappedComponent) => {
  const ComponentWithoutSSR = (props) => {
    const isClient = typeof window !== 'undefined';
    return isClient ? <WrappedComponent {...props} /> : null;
  };
  ComponentWithoutSSR.originalName = nameHOC(WrappedComponent);
  ComponentWithoutSSR.displayName = nameHOC(WrappedComponent, 'WithoutSSR');
  return ComponentWithoutSSR;
};

export default SkipSSR;
