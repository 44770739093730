import css from 'styled-jsx/css';
import { colors } from '../../../../config/styles';

const shippingListStyle = css`
  .divider-line {
    border: 1px solid ${colors.gray.shade14};
    margin-top: 10px;
  }
`;

export { shippingListStyle };
