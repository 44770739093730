import apiConfig from '../config/api/apiConfig';

const RACKCDN_SUFFIXES = {
  THUMB: '-thumb', // 50px width and height
  SMALL: '-small', // 150px width and height
  MEDIUM: '-medium', // 400px width and height
  LARGE: '-large', // 700px width and height
  ZOOM: '-zoom', // 1400px width and height
};

export const getImages = ({
  variantId,
  regionCode,
  useBasePath = false,
  attr = {},
  url,
  isPictureTagEnabled,
  isImageDeliveryOverrideEnabled,
  store,
}) => {
  const { width, height, quality, crop = {} } = attr;
  let baseUrl = '';
  if (useBasePath) {
    baseUrl = apiConfig.imagesBaseURL;
  } else {
    baseUrl =
      variantId === 'NoImage'
        ? apiConfig.getCFImagesURL(regionCode, store, isImageDeliveryOverrideEnabled)
        : apiConfig.getImagesURL(regionCode);
  }

  let imageUrl = url || `${baseUrl}/${variantId}`;

  const isCFImage = imageUrl.includes('imagedelivery');
  if (isCFImage) {
    if (/\/public|\/carousel|\/thumbnail|\/preview *$/.test(imageUrl)) {
      imageUrl = imageUrl.replace(/\/public$|\/carousel$|\/thumbnail$|\/preview$/, '');
    }
    imageUrl += '/';
    if (width && height) {
      imageUrl += `width=${width},height=${height}`;
    }
    if (quality) {
      imageUrl += `,quality=${quality}`;
    }
    if (isPictureTagEnabled) {
      imageUrl += `,format=webp`;
    }
    imageUrl += ',fit=pad';
    return imageUrl;
  }

  if (!imageUrl.includes('scene7.com')) {
    // temporary fix to resize tottus images until they are migrated to scene7
    if (imageUrl.includes('rackcdn.com')) {
      const suffix = (width || 400) <= 150 ? RACKCDN_SUFFIXES.SMALL : RACKCDN_SUFFIXES.MEDIUM;
      const fileExtension = imageUrl.match(/.(jpg|jpeg|png|jfif)$/i);

      if (!fileExtension) {
        return imageUrl;
      }

      const regex = new RegExp(`${fileExtension[0]}$`, 'i');
      return imageUrl.replace(regex, `${suffix}${fileExtension[0]}`);
    }
    return imageUrl;
  }

  if (width && height) {
    imageUrl += `?wid=${width}&hei=${height}`;
  }

  if (
    Object.prototype.hasOwnProperty.call(crop, 'x') &&
    Object.prototype.hasOwnProperty.call(crop, 'y') &&
    width &&
    height
  ) {
    imageUrl += `&crop=${crop.x},${crop.y},${width},${height}`;
  }

  if (quality) {
    imageUrl += `${imageUrl.includes('?') ? '&' : '?'}qlt=${quality}`;
  }

  if (isPictureTagEnabled) {
    imageUrl += `&fmt=webp`;
  }

  return imageUrl;
};
