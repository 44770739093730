import React, { useEffect, useState } from 'react';
import { nameHOC } from './hocutils';

const SkipSSR = (WrappedComponent) => {
  const ComponentWithoutSSR = (props) => {
    const { forceSSR = false, ...rest } = props || {};
    const [render, setRender] = useState(forceSSR);

    useEffect(() => {
      setRender(true);
    }, []);

    return render ? <WrappedComponent {...rest} /> : null;
  };
  ComponentWithoutSSR.originalName = nameHOC(WrappedComponent);
  ComponentWithoutSSR.displayName = nameHOC(WrappedComponent, 'WithoutSSR');
  return ComponentWithoutSSR;
};

export default SkipSSR;
