import PropTypes from 'prop-types';
import React from 'react';
import { style } from './MediaComponent.style';

const mediaConstants = {
  mobile: 'mobile',
  phablet: 'phablet',
  tablet: 'tablet',
  desktop: 'desktop',
};

const MediaComponent = ({ from, to, children }) => {
  let className;
  if (!to || from === to || from === mediaConstants.desktop) {
    className = `${from}-only`;
  } else {
    className = `${from}-${to}`;
  }

  className = from === mediaConstants.mobile ? `fallback ${className}` : className;

  return (
    <>
      <div className={className}>{children}</div>
      <style jsx="true">{style}</style>
    </>
  );
};

MediaComponent.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string,
  children: PropTypes.node,
};

MediaComponent.defaultProps = {
  children: null,
  to: null,
};

export { mediaConstants };
export default MediaComponent;
