import css from 'styled-jsx/css';
import { typography } from '../../../config/styles';

const ratingsVisualGlobalStyles = css.global`
  .csicon-star_no_filled,
  .csicon-star_half_filled,
  .csicon-star_full_filled {
    margin: 0 3px 0 0;
    font-size: ${typography.lg};
  }
  .ratings--container:last-child {
    margin: 0;
  }

  .ratings--container {
    display: flex;
    &.normal {
      font-size: 15px;
    }

    &.small {
      .csicon-star_no_filled,
      .csicon-star_half_filled,
      .csicon-star_full_filled {
        font-size: 11px;
      }
    }

    &.large {
      .csicon-star_no_filled,
      .csicon-star_half_filled,
      .csicon-star_full_filled {
        font-size: 22px;
      }
    }

    &.xlarge {
      .csicon-star_no_filled,
      .csicon-star_half_filled,
      .csicon-star_full_filled {
        font-size: 25px;
      }
    }
  }
  .ratings--container .csicon-star_full_filled {
    margin: 0 4px 0 0;
  }
  .rating-description {
    color: #333;
    font-family: Lato;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.4px;
  }
`;

const halfStarRatingVisualStyles = css`
  .path1,
  .path2 {
    font-family: inherit;
  }
`;

export { ratingsVisualGlobalStyles, halfStarRatingVisualStyles };
