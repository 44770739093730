import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

const successIconSvgMkp = `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.80785 23.1922C9.88497 28.2693 18.1161 28.2693 23.1932 23.1922C28.2689 18.115 28.2689 9.88392 23.1932 4.8068C18.1161 -0.268932 9.88497 -0.268932 4.80785 4.8068C-0.269282 9.88392 -0.269282 18.115 4.80785 23.1922Z" stroke="#30871F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 14.2538L12.7343 18L19 10" stroke="#30871F" stroke-width="1.5"/>
</svg>`;

const CartDesktopStyles = css`
  .popup {
    cursor: initial;
    height: 100%;
    width: 100%;
    background-color: ${colors.white.shade1};
    display: flex;
    flex-direction: column;

    &.small {
      min-height: 237px;
    }
  }

  .header {
    margin: 0 27px;
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${colors.gray.shade14};

    .csicon-icon-success-circle {
      color: ${colors.green.shade1};
      font-size: ${typography.xl3a};
    }

    &.mkp {
      .csicon-icon-success-circle {
        color: ${colors.green.shade20};
      }
      .csicon-icon-success-circle::before {
        content: url('${svgToDataUri(successIconSvgMkp)}');
      }
    }

    .label {
      flex-grow: 1;
      margin: 0 14px;
    }

    .csicon-danger {
      color: ${colors.gray.shade29};
      font-size: ${typography.sm};
    }
  }

  .body {
    height: 426px;
    overflow-x: hidden;
    padding: 0 27px;
    flex-grow: 1;
    width: 100%;

    &.body-large {
      height: 601px;
    }

    &.body-medium {
      height: 473px;
    }

    .collections,
    .bundles {
      border-top: 1px solid ${colors.gray.shade14};

      span {
        margin: 22px 0;
        display: block;
      }
    }

    .collections {
      padding-top: 22px;
    }
    &.small {
      height: 185px;
    }
    &.small.with-warning {
      height: 155px;
    }
  }

  .footer {
    background-color: ${colors.gray.shade41};
    padding: 0 27px;

    .warning {
      height: 30px;
      border-bottom: 1px solid ${colors.gray.shade14};
      color: ${colors.black.shade1};

      .csicon-alert {
        color: ${colors.orange.shade1};
        font-size: ${typography.lg};
        margin-right: 10px;
      }

      span {
        font-family: inherit;
        font-size: ${typography.xs};
        line-height: 12px;
      }

      display: flex;
      align-items: center;
    }

    .sub-footer {
      padding: 12px 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .sub-total {
        font-size: 12px;
        line-height: 14px;

        span {
          margin-right: 5px;
          font-weight: 700;
          color: ${colors.black.shade1};
        }
      }

      & :global(button) {
        align-self: center;
        width: auto;
      }

      & :global(button:last-child) {
        align-self: center;
        width: auto;
        padding: 0 20px;
      }
    }

    .addToCart-btn {
      margin-left: 27px;
      width: 195px;

      &.mkp {
        width: auto;
      }

      a {
        text-align: center;
      }
    }
  }
`;

const CartBrandNameStyle = css`
  .brand-name {
    display: block;
    letter-spacing: 0.73px;
    line-height: 12px;
  }
`;
const CartDescriptionStyle = css`
  .description {
    font-size: ${typography.sm};
    line-height: 15px;
  }
`;

export { CartDesktopStyles, CartBrandNameStyle, CartDescriptionStyle };
