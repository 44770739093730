/* eslint no-console: 0, no-underscore-dangle: 0 */
import Router from 'next/router';
import WithUrl from '../../utils/WithUrl';
import { createScriptTag, appendScriptsToHead } from '../../utils/DOMUtil';
import { initDigitalData, updateDigitalData } from './digitalData';
import { getPage } from '../../utils/urlHelper';
import { getCountry } from '../util';

const trackMapping = {
  search: 'tag_new_slp',
  noResult: 'tag_new_slp_no_result',
  category: 'tag_new_plp',
  product: 'tag_new_pdp',
  error: 'error',
};

const track = (eventName, eventValue = null) => {
  if (window && window._satellite) {
    if (eventValue) {
      window._satellite.track(eventName, eventValue);
      return;
    }
    window._satellite.track(eventName);
  }
};

const pageBottom = () => {
  if (window && window._satellite && window._satellite.pageBottom) {
    console.log('satellite is present');
    window._satellite.pageBottom();
  } else {
    console.log('satellite is not present');
  }
};

const updateData = () => {
  updateDigitalData();
  const page = getPage(window.location.href);
  const event = trackMapping[page];
  track(event);
};

const initAnalitycs = () => {
  Router.events.on('routeChangeComplete', () => {
    updateData();
  });
  Router.events.on('routeChangeStart', () => {
    window.previousUrl = window.location.href;
  });
  pageBottom();
};
const loadAnalytics = async () => {
  await initDigitalData();
  const country = getCountry();
  const url = WithUrl('TAG_MANAGER', country);
  const script = createScriptTag({
    src: url,
    onLoad: initAnalitycs,
  });
  appendScriptsToHead(script);
};

export { loadAnalytics, initAnalitycs, track };
