const pumApplicableRegion = {
  co: true,
};

const cmrApplicableRegion = {
  cl: true,
  co: true,
  pe: true,
};

export { pumApplicableRegion, cmrApplicableRegion };
