import React, { useContext, useState } from 'react';
import createContainer from 'constate';
import { nameHOC } from './hocutils';
import { setPromotionalProductToAdd } from './CartHelpers';
import {
  addProductCommerce,
  removeProductCommerce,
  addCollectionCommerce,
  removeCollectionCommerce,
  setWarrantyAndServices,
} from './cart';

function useCartContext() {
  const [cart, setCart] = useState({
    bundleItems: [],
  });
  const [showCart, setShowCart] = useState(false);
  const [isSizeSelected, setSizeSelection] = useState(false);
  const [isVariantSelectionVisible, setVariantSelectionVisible] = useState(true);
  const [quantityWhileVariantSelectionVisible, setQuantityWhileVariantSelectionVisible] = useState(null);
  const [selectedOperatorId, setSelectedOperatorId] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [warrantyOptionCurrent, setWarrantyOptionCurrent] = useState(null);
  const [showLoginForm, setShowLoginForm] = useState(false);

  const setWarrantyOptionMethod = (payload) => {
    return setWarrantyAndServices({
      ...payload,
      channel: 'warranty',
    });
  };

  return {
    cart: {
      ...cart,
      isSizeSelected,
      setSizeSelection,
      isVariantSelectionVisible,
      setVariantSelectionVisible,
      quantityWhileVariantSelectionVisible,
      setQuantityWhileVariantSelectionVisible,
      selectedSize,
      setSelectedSize,
      selectedOperatorId,
      setSelectedOperatorId,
      addProduct: addProductCommerce({
        cart,
        setCart,
        setShowCart,
        setVariantSelectionVisible,
        setQuantityWhileVariantSelectionVisible,
      }),
      removeProduct: removeProductCommerce({
        cart,
        setCart,
        setShowCart,
      }),
      setWarrantyOption: setWarrantyOptionMethod({
        cart,
        setCart,
      }),
      addCollection: addCollectionCommerce({
        cart,
        setCart,
        setShowCart,
        setVariantSelectionVisible,
        setQuantityWhileVariantSelectionVisible,
      }),
      removeCollection: removeCollectionCommerce({
        cart,
        setCart,
        setShowCart,
      }),
      setPromotionalProductToAdd: setPromotionalProductToAdd({ cart, setCart }),

      setAssemblyOption: setWarrantyAndServices({
        cart,
        setCart,
        channel: 'service',
      }),
      showCart,
      setShowCart,
      warrantyOptionCurrent,
      setWarrantyOptionCurrent,
      showLoginForm,
      setShowLoginForm,
    },
  };
}

const CartContext = createContainer(useCartContext);

const withCart = (WrappedComponent) => {
  const { getInitialProps } = WrappedComponent;
  const ComponentWithCartCtx = (props) => {
    const { cart } = useContext(CartContext.Context);

    return <WrappedComponent {...props} cart={cart} />;
  };
  if (typeof getInitialProps === 'function') {
    ComponentWithCartCtx.getInitialProps = getInitialProps;
  }
  ComponentWithCartCtx.originalName = nameHOC(WrappedComponent);
  ComponentWithCartCtx.displayName = nameHOC(WrappedComponent, 'WithCartContext');

  return ComponentWithCartCtx;
};

export default CartContext;
export { withCart };
