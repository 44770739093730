const findIndex = (list, callback) => {
  if (!list) {
    return -1;
  }

  if (Array.prototype.findIndex) {
    return list.findIndex(callback);
  }

  let index = 0;
  list.some((element) => {
    if (callback(element)) {
      return true;
    }
    index += 1;
    return false;
  });
  return index === list.length ? -1 : index;
};

const toggleBodyClass = (condition, name) => {
  document.body.classList.toggle(name, condition);
};

const allowOnlyNumbers = (value) => {
  let onlyDigitVal = '';
  for (let i = 0; i < value.length; ) {
    if (value.charCodeAt(i) >= 48 && value.charCodeAt(i) <= 57) {
      onlyDigitVal = `${onlyDigitVal}${value.charAt(i)}`;
    }
    i += 1;
  }
  return onlyDigitVal;
};

const isValidHttpUrl = (str) => {
  let url;
  try {
    url = new URL(str);
  } catch (e) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export { allowOnlyNumbers, findIndex, isValidHttpUrl, toggleBodyClass };
