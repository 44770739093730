import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Multiselect from '../../ui/Multiselect/Multiselect';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import ShippingList from './ShippingList/ShippingList';

const ShippingFacet = (props) => {
  const {
    item = { values: [] },
    groupType,
    onFacetChange,
    searchInFacetsLabel,
    checkboxType,
    normalBorderClass,
    selectedBorderClass,
    isFacetCountHidden,
    appCtx,
  } = props;
  const { deviceType } = appCtx;
  const content = () => {
    return (
      <Multiselect
        variant="shipping-facet"
        groupType={groupType}
        item={item}
        onFacetChange={onFacetChange}
        searchInFacetsLabel={searchInFacetsLabel}
        checkboxType={checkboxType}
        selectedBorderClass={selectedBorderClass}
        normalBorderClass={normalBorderClass}
        isFacetCountHidden={isFacetCountHidden}
      />
    );
  };
  if (deviceType !== 'desktop') {
    return content();
  }
  return <ShippingList item={item} onFacetChange={onFacetChange} />;
};

ShippingFacet.defaultProps = {
  searchInFacetsLabel: '',
  onFacetChange: noop,
  groupType: '',
  checkboxType: 'succes',
  selectedBorderClass: '',
  normalBorderClass: '',
  isFacetCountHidden: false,
};

ShippingFacet.propTypes = {
  searchInFacetsLabel: PropTypes.string,
  item: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  onFacetChange: PropTypes.func,
  groupType: PropTypes.oneOf(['', 'availability']),
  checkboxType: PropTypes.oneOf('mkp-succes', 'succes', ''),
  selectedBorderClass: PropTypes.string,
  normalBorderClass: PropTypes.string,
  isFacetCountHidden: PropTypes.bool,
};

export default withApplicationContext(ShippingFacet);
export { ShippingFacet };
