import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';

const mobileLinkMenuStyle = css`
  .csicon-succes {
    fill: red;
  }
  .icon {
    display: flex;
    align-items: center;
    font-size: 22px;
  }

  .mobile-link-menu-title {
    background-color: ${colors.gray.shade3};
    color: ${colors.gray.shade29};
    font-size: 12px;
    padding: 12px 18px;
    margin-top: -10px;
  }

  .mobile-link-menu-item {
    padding: 15px 20px;
    border-bottom: 1px solid ${colors.gray.shade28};
    font-size: 14px;
    background-color: ${colors.white.shade1};
    display: flex;
    width: 100%;
    text-align: left;

    i {
      margin-right: -16px;
      color: ${colors.green.shade1};
      font-size: 16px;
    }

    .icon-success-mkp {
      color: var(--primary-color);
    }

    span {
      position: relative;
      padding-left: 31px;
    }
    span.active {
      font-weight: 700;
    }
  }
`;

export { mobileLinkMenuStyle };
