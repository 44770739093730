import css from 'styled-jsx/css';
import { colors } from '../../config/styles';

const ProductLandingContainerStyles = css`
  .pod-group {
    background-color: ${colors.gray.shade57};
    padding: 5px 0;
  }
  .pod-group--container {
    display: flex;
    flex-direction: column;
    @mixin tabletToDesktop {
      flex-direction: row;
    }
  }
  .pod-group--filters {
    width: 100%;
    background: ${colors.white.shade1};
    border-top: 1px ${colors.gray.shade17} solid;
    border-bottom: 1px ${colors.gray.shade17} solid;
    border-radius: var(--border-radius);

    @mixin tabletToDesktop {
      width: 26.2%;
      border: none;
    }
    @mixin desktop {
      min-width: 312px;
    }
  }
  .pod-group--products {
    width: 100%;
    margin-top: 10px;
    @mixin tabletToDesktop {
      width: 78%;
      display: flex;
      margin-top: 0;
      flex-direction: column;
    }
  }
  .filter-heading {
    padding: 20px 10px 10px 25px;
  }
  .pad-group--auto-correct {
    margin-bottom: 40px;
  }
  .pad-group--auto-correct-mkp {
    margin-bottom: 5px;
    @mixin mobileToTablet {
      margin-bottom: 23px;
    }
  }
  .pad-group--category-banner {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
  }
  .total-results {
    padding: 8px 25px 8px;
  }
  .survey-container {
    display: none;
    @mixin tabletToDesktop {
      display: flex;
    }
  }

  .survey-container-mobile {
    display: flex;
    margin-bottom: 5px;
    @mixin tabletToDesktop {
      display: none;
    }
  }

  .vertical-banner {
    margin-left: 28px;
    position: sticky;
  }
`;

export default ProductLandingContainerStyles;
