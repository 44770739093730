import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';

const DrawerTabStyles = css`
  iframe.sizechart-template {
    width: 100%;
    height: inherit;
    @mixin desktop {
      display: block;
      min-width: 400px;
      max-width: 760px;
    }
  }

  .sizechart-container {
    width: 100%;
    height: 80vh;
  }

  .drawer-tab-content {
    width: 100%;
    height: 100vh;
  }

  .drawer-tab {
    width: 100%;
    ul {
      margin: auto;
      padding: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    ul li {
      flex: 1;
      width: 100%;
    }
    .tab-button {
      background-color: ${colors.gray.shade6};
      height: 42px;
      width: 100%;
      font: 14px 'Lato', sans-serif;
      line-height: 15px;
      font-weight: bold;
      letter-spacing: 0.933333px;
      color: ${colors.black.shade1};
    }
    .tab-active {
      border-top: 2px solid ${colors.blue.shade3};
      background-color: ${colors.white.shade1};
      height: 42px;
      width: 100%;
      font: 14px 'Lato', sans-serif;
      line-height: 15px;
      font-weight: bold;
      letter-spacing: 0.933333px;
      color: ${colors.black.shade1};
    }
    .tab-active:disabled {
      color: ${colors.black.shade1};
      font-weight: bold;
    }
  }
`;

export { DrawerTabStyles };
