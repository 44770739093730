import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PriceRangeStyle } from './PriceRange.style';

const PriceRange = ({ value, placeholder, onChange, id, error, priceUnit }) => {
  const [focus, setFocus] = useState(false);

  const blockInvalidChars = (e) => {
    const evt = e || window.event;
    const charCode = evt.which ? evt.which : e.keyCode;
    if (charCode === 37 || charCode === 39) return;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = () => {
    setFocus(false);
  };

  const active = focus || value ? 'active' : '';
  return (
    <div className={`input-wrapper ${error ? 'error' : active}`}>
      <label htmlFor={id}>{priceUnit}</label>
      <input
        onKeyDown={blockInvalidChars}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
        value={value}
        id={id}
        type="number"
      />
      <style jsx> {PriceRangeStyle}</style>
    </div>
  );
};

PriceRange.defaultProps = {
  value: '',
  placeholder: '',
  priceUnit: '$',
};

PriceRange.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  priceUnit: PropTypes.any,
};

export default PriceRange;
