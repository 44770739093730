import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import WithOutsideClick from '../../../utils/WithOutsideClick';
import WithTogggle from '../../../utils/WithToggle';
import noop from '../../../utils/noop';
import MediaComponent from '../../Media/MediaComponent';
import BodyCopy from '../BodyCopy/BodyCopy';
import { DropdownStyles } from './Dropdown.style';

const Dropdown = ({
  label,
  options,
  toggleState,
  onOptionSelected,
  selectedOption,
  variant,
  showDesktopViewInMobile,
  isPrimaryTheme,
}) => {
  const { visible, setVisible } = toggleState;
  const toggleView = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    setVisible(false);
  }, [options]);

  if (!selectedOption)
    // eslint-disable-next-line no-param-reassign
    selectedOption =
      options.filter((option) => option.selected).length > 0
        ? options.filter((option) => option.selected)[0]
        : options[0];

  const listItemActiveClass = `dropdown-list-item-mkp-active${isPrimaryTheme ? ' primary-theme' : ''}`;

  const renderDropdown = () => {
    return (
      <div className="dropdown-select">
        <button type="button" id="testId-Dropdown-desktop-button" data-order="catalyst-orderBy" onClick={toggleView}>
          <BodyCopy size={variant === 'CART' ? 'copy5' : 'copy3'} isHighlighted={variant !== 'CART'}>
            {selectedOption.label}
          </BodyCopy>
          <i className={`icon ${visible ? 'csicon-arrow_up' : 'csicon-arrow_down'}`} />
        </button>
        <style jsx>{DropdownStyles}</style>
      </div>
    );
  };

  return (
    <WithOutsideClick
      onOutsideClick={() => {
        setVisible(false);
      }}
    >
      <div className={`dropdown-wrapper ${variant}`}>
        <div className="dropdown-header">
          {showDesktopViewInMobile ? (
            renderDropdown()
          ) : (
            <>
              <MediaComponent from="mobile" to="phablet">
                <button
                  className="btn-with-icon"
                  id="testId-Dropdown-mobile-button"
                  data-order="catalyst-orderBy"
                  onClick={toggleView}
                  type="button"
                >
                  <i className="csicon-icon-order" />
                  <span>{label}</span>
                </button>
              </MediaComponent>
              <MediaComponent from="tablet" to="desktop">
                {label && (
                  <div>
                    <BodyCopy size="copy4" type="tertiary">
                      {label}:
                    </BodyCopy>
                  </div>
                )}
              </MediaComponent>
              <MediaComponent from="tablet" to="desktop">
                {renderDropdown()}
              </MediaComponent>
            </>
          )}
        </div>

        {visible && (
          <ul className="dropdown-list">
            {options.map((option, index) => (
              <li
                key={option.label}
                className={option.label === selectedOption.label ? listItemActiveClass : 'dropdown-list-item'}
              >
                <button
                  id={`testId-Dropdown-${option.label}`}
                  type="button"
                  className="dropdown-link"
                  onClick={() => onOptionSelected(option, index)}
                >
                  {option.label}
                </button>
              </li>
            ))}
          </ul>
        )}

        <style jsx>{DropdownStyles}</style>
      </div>
    </WithOutsideClick>
  );
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
  toggleState: PropTypes.object,
  onOptionSelected: PropTypes.func,
  selectedOption: PropTypes.any,
  variant: PropTypes.string,
  showDesktopViewInMobile: PropTypes.bool,
  isPrimaryTheme: PropTypes.bool,
};

Dropdown.defaultProps = {
  toggleState: null,
  onOptionSelected: noop,
  selectedOption: null,
  variant: '',
  showDesktopViewInMobile: false,
  isPrimaryTheme: false,
};

export default WithTogggle(Dropdown);
