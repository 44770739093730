import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { DrawerTabStyles } from './DrawerTab.style';
import apiConfig from '../../../config/api/apiConfig';

const DrawerTab = ({
  onLoad,
  sizeChartName,
  sizeChartUrl,
  regionCode,
  isActiveTabAssistentePersonal,
  onOpenSizeChart,
  tabHeadings,
}) => {
  const [activeTab, setActiveTab] = useState(1);

  const toggleTab = (id) => {
    setActiveTab(id);
    if (id === 1) {
      onOpenSizeChart();
    }
  };

  useEffect(() => {
    if (isActiveTabAssistentePersonal) {
      toggleTab(0);
    }
  }, []);

  const tabContent = (id) => {
    return id === 1 ? (
      <div className="sizechart-container">
        <iframe
          className="sizechart-template"
          id="testId-sizeChart-iframe"
          title={sizeChartName}
          onLoad={onLoad}
          src={apiConfig.getSizeCharturl(sizeChartUrl, regionCode)}
        />
        <style jsx>{DrawerTabStyles}</style>
      </div>
    ) : (
      <div id="personal-assistant-container" className="personal-assistant-tab-container" />
    );
  };

  return (
    <div className="drawer-tab">
      <ul>
        {isActiveTabAssistentePersonal && (
          <li>
            <button
              className={classNames('tab-button', {
                'tab-active': activeTab === 0,
                'personal-assistant': true,
              })}
              type="button"
              onClick={() => toggleTab(0)}
              disabled={activeTab === 0}
            >
              {tabHeadings[0]}
            </button>
          </li>
        )}
        <li>
          <button
            className={classNames('tab-button', {
              'tab-active': activeTab === 1,
              'guide-sizes': true,
            })}
            type="button"
            onClick={() => toggleTab(1)}
            disabled={activeTab === 1}
          >
            {tabHeadings[1]}
          </button>
        </li>
      </ul>
      <div className="drawer-tab-content">{tabContent(activeTab)}</div>
      <style jsx>{DrawerTabStyles}</style>
    </div>
  );
};

DrawerTab.defaultProps = {
  isActiveTabAssistentePersonal: false,
};

DrawerTab.propTypes = {
  onLoad: PropTypes.func.isRequired,
  sizeChartName: PropTypes.string.isRequired,
  sizeChartUrl: PropTypes.string.isRequired,
  regionCode: PropTypes.string.isRequired,
  isActiveTabAssistentePersonal: PropTypes.bool,
  onOpenSizeChart: PropTypes.func.isRequired,
  tabHeadings: PropTypes.object.isRequired,
};

export default DrawerTab;
