import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../../../../config/constants';
import noop from '../../../../../utils/noop';
import { ShippingBoxStyles } from './ShippingBox.style';
import { ShippingBoxButtonWithApp } from './ShippingBoxButton/ShippingBoxButton';

const ShippingBox = (props) => {
  const { label, labelDetails, labelAmount, timeline, shippingMap, onFacetChange, type, showLabel } = props;
  const handleFacetChange = (obj) => () => {
    onFacetChange({ ...obj, selected: !obj.selected });
  };
  const renderFacets = (facetData) => {
    return facetData.map((option) => {
      const { title, count, selected } = option;
      const hasTimeConstraint = constants.SAME_DAY_HD_CC_REGEX.test(title);

      return (
        <ShippingBoxButtonWithApp
          isChecked={selected}
          onChange={handleFacetChange(option)}
          id={`${title.replace(/\s/g, '-').toUpperCase()}-${count}`}
          title={title}
          hasTimeConstraint={hasTimeConstraint}
        />
      );
    });
  };
  return (
    <div className="shipping-container">
      <div className="heading-section">
        <div className="icon-container">
          <i className={`icon ${type}`} />
        </div>
        <div className="heading shipping">
          <p>{label}</p>
        </div>
      </div>
      {showLabel && (
        <div className="label-section">
          {labelDetails}&nbsp;<strong className="bold">{labelAmount}</strong>
          &nbsp;{timeline}
        </div>
      )}
      <div className="shipping-buttons">{renderFacets(shippingMap)}</div>
      <style jsx>{ShippingBoxStyles}</style>
    </div>
  );
};
ShippingBox.defaultProps = {
  label: '',
  labelDetails: '',
  labelAmount: '',
  timeline: '',
  onFacetChange: noop,
  type: '',
  shippingMap: [],
  showLabel: false,
};

ShippingBox.propTypes = {
  label: PropTypes.string,
  labelDetails: PropTypes.string,
  labelAmount: PropTypes.string,
  timeline: PropTypes.string,
  onFacetChange: PropTypes.func,
  type: PropTypes.string,
  shippingMap: PropTypes.array,
  showLabel: PropTypes.bool,
};

export { ShippingBox };
