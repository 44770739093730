import css from 'styled-jsx/css';
import { colors, typography } from '../../../../config/styles';

const CountTextStyles = css`
  .product-count {
    &-value {
      margin: auto;
      width: 100%;
      display: flex;
      justify-content: center;
      padding-bottom: 7px;

      &.value-primary {
        color: ${colors.gray.shade5};
        font-size: ${typography.base};
        line-height: 17px;
      }
      &.value-secondary {
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: ${colors.black.shade10};
        padding-bottom: 0;
      }
    }
  }
`;

export { CountTextStyles };
