import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { VariantAvailabilityOptionsStyles } from './VariantAvailabilityOptions.style';
import { withLabels } from '../../utils/LabelsContext';
import constants from '../../config/constants';

const VariantAvailabilityOptions = ({ deviceType, labels, isHomeDeliveryAvailable, isPickFromStoreAvailable }) => {
  const deliveryAvailability = isHomeDeliveryAvailable
    ? {
        availability: 'deliveryAvailable',
        label: labels.TYPE_C_HOME_DELI_TITLE_MKP,
      }
    : {
        availability: 'deliveryNotAvailable',
        label: labels.TYPE_C_HOME_DELI_OUTOFSTOCK_MKP,
      };

  const pickupInStoreAvailability = isPickFromStoreAvailable
    ? {
        availability: 'pickupInStoreAvailable',
        label: labels.TYPE_C_PICKUP_INSTORE_TITLE_MKP,
      }
    : {
        availability: 'pickupInStoreNotAvailable',
        label: labels.TYPE_C_PICKUP_INSTORE_OUTOFSTOCK_MKP,
      };

  const alignItem = isHomeDeliveryAvailable && isPickFromStoreAvailable ? 'baseline' : '';
  const noDeliverybutAvailable = !isHomeDeliveryAvailable && isPickFromStoreAvailable ? 'noDeliverybutAvailable' : '';
  const iconClass = 'icon-mkp';
  return (
    <ul className={`availability-options  mkp ${alignItem} ${noDeliverybutAvailable}`}>
      <li
        className={classNames('availability-options-item', {
          disabled: !isHomeDeliveryAvailable,
        })}
      >
        <span className={`${iconClass} ${deliveryAvailability.availability}`} />
        {deviceType === 'desktop' && deliveryAvailability.label}
      </li>
      <li
        className={classNames('availability-options-item', {
          disabled: !isPickFromStoreAvailable,
        })}
      >
        <span className={`${iconClass} ${pickupInStoreAvailability.availability}`} />
        {deviceType === 'desktop' && pickupInStoreAvailability.label}
      </li>
      <style jsx>{VariantAvailabilityOptionsStyles}</style>
    </ul>
  );
};

VariantAvailabilityOptions.defaultProps = {
  deviceType: constants.DEVICE_DESKTOP,
};

VariantAvailabilityOptions.propTypes = {
  labels: PropTypes.object.isRequired,
  deviceType: PropTypes.string,
  isHomeDeliveryAvailable: PropTypes.bool.isRequired,
  isPickFromStoreAvailable: PropTypes.bool.isRequired,
};

export { VariantAvailabilityOptions };

export default withLabels(VariantAvailabilityOptions);
