import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';

const PopoverStyles = css`
  .alert-container {
    display: flex;
    padding: 1rem;
    align-items: center;
    i {
      margin-right: 9px;
      width: 17px;
      height: 17px;
    }
    &.success {
      background-color: ${colors.green.shade23};
      color: ${colors.green.shade22};
    }
  }
  .warning {
    background-color: ${colors.yellow.shade5};
    i {
      color: #f7b500;
    }
  }
`;

export default PopoverStyles;
