import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import constants from '../../../config/constants';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withLabels } from '../../../utils/LabelsContext';
import _ from '../../../utils/LodashImports';
import noop from '../../../utils/noop';
import { truncateText, ucFirst } from '../../../utils/textHelper';
import { digitalData } from '../../Facets/Common/digitalData';
import BodyCopy from '../BodyCopy/BodyCopy';
import Checkbox from '../Checkbox/Checkbox';
import InputBox from '../InputBox/InputBox';
import RatingsVisual from '../RatingsVisual/RatingsVisual';
import { multiSelectStyle } from './Multiselect.style';

const Multiselect = (props) => {
  const {
    item,
    type,
    groupType,
    onFacetChange,
    searchInFacetsLabel,
    checkboxType,
    normalBorderClass,
    selectedBorderClass,
    isFacetCountHidden,
    variant,
    checkBoxIcon,
    appCtx,
    labels,
  } = props;
  const { name } = item;
  const [data, setData] = useState(item.values);
  const { deviceType } = appCtx;
  const isShippingFacet = variant === 'shipping-facet';
  const { SAME_DAY_DELIVERY_LABEL, SAME_DAY_DELIVERY_LABEL_AMOUNT, HOME_SHIPPING, WITHDRAWAL_AT_STORE } = _.get(
    appCtx,
    'siteConfig.textDictionary',
    {
      SAME_DAY_DELIVERY_LABEL: 'Si compras hasta las',
      SAME_DAY_DELIVERY_LABEL_AMOUNT: '14:00 horas',
      HOME_SHIPPING: 'Envio Domiclio',
      WITHDRAWAL_AT_STORE: 'Retiro en un punto',
    }
  );
  const showRatingDescription = _.get(appCtx, 'siteConfig.toggles.showRatingDescription', false);
  const shippingMap = {
    hd: [],
    cc: [],
  };
  const [searchTerm, setSearchTerm] = useState(null);
  if (isShippingFacet) {
    item.values.forEach((v) => {
      if (/.hd_/.test(v.facetType)) {
        shippingMap.hd.push(v);
      } else {
        shippingMap.cc.push(v);
      }
    });
  }

  useEffect(() => {
    setData(item.values);
  }, [item.values]);

  const handleChange = (val) => {
    const filteredData = item.values.filter((d) => {
      return _.deburr(d.facetValue).toLowerCase().includes(_.deburr(val).toLowerCase());
    });
    setData(filteredData);
    setSearchTerm(val);
  };
  const handleFacetChange = (obj) => () => {
    onFacetChange({ ...obj, selected: !obj.selected }, !searchTerm);
    if (searchTerm) {
      digitalData(`${name}|${searchTerm}|${obj.title}`);
    }
  };
  const renderFacets = (facetData) => {
    return facetData.map((option = {}) => {
      const { title = '', count, selected, isBoomApplicable, facetValue, facetType } = option;
      const hasTimeConstraint = constants.SAME_DAY_HD_CC_REGEX.test(title);
      const showGreenText = constants.NEXT_DAY_DELIVERY_REGEX.test(title) && facetData.length === 1;
      const isCCFacet = constants.SHIPPING_CC_REGEX.test(title);
      const isBrandFacet = constants.BRAND_FACET.test(facetType);
      const facetCase = () => {
        if (isBrandFacet) {
          return 'capitalize';
        }
        return 'ignorecase';
      };
      const markedStars = (value) => {
        const stars = Math.round(value * 2) / 2;
        const fiveStars = labels.RATINGS_STAR_ONLY_TEXT ? `${stars} ${labels.RATINGS_STAR_ONLY_TEXT}` : '';
        const lessThanFiveStars = labels.RATINGS_STAR_MORE_TEXT ? `${stars} ${labels.RATINGS_STAR_MORE_TEXT}` : '';
        return stars === 5 ? `${fiveStars}` : ` ${lessThanFiveStars}`;
      };
      return (
        <li
          className={`${variant} ${isCCFacet ? 'shipping-facet1' : ''}`}
          data-facet-type={isShippingFacet ? facetType : null}
          key={title}
        >
          <Checkbox
            variant={groupType === 'availability' && !isShippingFacet ? 'primary' : 'secondary'}
            isChecked={selected}
            onChange={handleFacetChange(option)}
            id={`${title.replace(/\s/g, '-').toUpperCase()}-${count}`}
            type={checkboxType}
            normalBorderClass={normalBorderClass}
            selectedBorderClass={selectedBorderClass}
            hasTimeConstraint={hasTimeConstraint}
            isShippingFacet={isShippingFacet}
            isPrimaryTheme
            checkBoxIcon={checkBoxIcon}
          >
            {type === 'star' ? (
              <BodyCopy size="title24">
                <div className="mkp-ratings">
                  <RatingsVisual size="large" value={facetValue} component="facet" />
                  {showRatingDescription && <span className="rating-description">{markedStars(facetValue)}</span>}
                </div>
                {!isFacetCountHidden ? <span className="label-count">{`(${count})`}</span> : null}
              </BodyCopy>
            ) : (
              <BodyCopy size={isShippingFacet ? 'title23' : 'title24'}>
                {isBoomApplicable ? (
                  (cls) => (
                    <div className={`${cls} boom-div`}>
                      <i className="boomIcon label-text">
                        <span className="sr-only">{title}</span>
                      </i>
                      {!isFacetCountHidden ? <span className="label-count">{`(${count})`}</span> : null}
                    </div>
                  )
                ) : (
                  <>
                    <span
                      className={`label-text ${hasTimeConstraint || showGreenText ? 'green-text' : ''} ${
                        isShippingFacet ? 'shipping-facet-text' : ''
                      } ${facetCase()}`}
                    >
                      {ucFirst(truncateText(title, constants.FACETS.VALUE_TEXT_CHARCOUNT, true))}
                    </span>
                    {!isFacetCountHidden ? <span className="label-count">&nbsp;&nbsp;{`(${count})`}</span> : null}
                    {hasTimeConstraint ? (
                      <p className={`facet-sub-text ${isFacetCountHidden && 'no-count'}`}>
                        {SAME_DAY_DELIVERY_LABEL} <span className="bold">{SAME_DAY_DELIVERY_LABEL_AMOUNT}</span>
                      </p>
                    ) : null}
                  </>
                )}
              </BodyCopy>
            )}
          </Checkbox>
          <style jsx>{multiSelectStyle}</style>
        </li>
      );
    });
  };
  const renderShippingFacets = () => {
    return (
      <div>
        {!!shippingMap.hd.length && (
          <div className="facet-title">
            <div className="home-delivery-icon" />
            <p>{HOME_SHIPPING}</p>
          </div>
        )}
        {renderFacets(shippingMap.hd)}
        {!!(shippingMap.hd.length && shippingMap.cc.length) && deviceType !== 'desktop' && (
          <div className="divider-line mobile" />
        )}
        {!!shippingMap.cc.length && (
          <div className="facet-title">
            <div className="click-collect-icon" />
            <p className="green-text">{WITHDRAWAL_AT_STORE}</p>
          </div>
        )}
        {renderFacets(shippingMap.cc)}
        {!!(shippingMap.hd.length || shippingMap.cc.length) && deviceType === 'desktop' && (
          <div className="divider-line" />
        )}
        <style jsx>{multiSelectStyle}</style>
      </div>
    );
  };

  return (
    <div className={`multiselect-container ${isShippingFacet ? 'shipping' : ''}`}>
      {item.isSearchable && (
        <div className="searchBox">
          <InputBox
            hideLabel
            label={searchInFacetsLabel}
            onChange={handleChange}
            id={`testId-Multiselect-${item.name}`}
          />
        </div>
      )}
      <ul
        data-facet-type={!isShippingFacet ? _.get(data, '[0].facetType', null) : null}
        className={`multiselect ${isShippingFacet && 'shipping-multiselect'}`}
      >
        {!isShippingFacet ? renderFacets(data) : renderShippingFacets()}
      </ul>
      <style jsx>{multiSelectStyle}</style>
    </div>
  );
};

Multiselect.defaultProps = {
  type: 'text',
  item: { values: [] },
  searchInFacetsLabel: '',
  onFacetChange: noop,
  groupType: '',
  checkboxType: 'succes',
  selectedBorderClass: '',
  normalBorderClass: '',
  isFacetCountHidden: false,
  variant: '',
  checkBoxIcon: '',
  appCtx: {},
  labels: {},
};

Multiselect.propTypes = {
  searchInFacetsLabel: PropTypes.string,
  item: PropTypes.object,
  onFacetChange: PropTypes.func,
  type: PropTypes.oneOf(['star', 'text']),
  groupType: PropTypes.oneOf(['', 'availability']),
  checkboxType: PropTypes.oneOf('mkp-succes', 'succes', ''),
  selectedBorderClass: PropTypes.string,
  normalBorderClass: PropTypes.string,
  isFacetCountHidden: PropTypes.bool,
  variant: PropTypes.string,
  checkBoxIcon: PropTypes.string,
  appCtx: PropTypes.object,
  labels: PropTypes.object,
};

export default withApplicationContext(withLabels(Multiselect));
export { Multiselect };
