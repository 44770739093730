import WebStorage from './WebStorage';
import constants from '../config/constants';

const handleScroll = (appCtx) => {
  const scrollPos = WebStorage.getItem({
    name: constants.SESSION_STORAGE_SCROLL,
  });
  const isNewPage = WebStorage.getItem({
    name: constants.SESSION_STORAGE_NEW_PAGE,
  });
  WebStorage.removeItem({
    name: constants.SESSION_STORAGE_NEW_PAGE,
  });

  if (!isNewPage && scrollPos) {
    const [scrollX, scrollY] = scrollPos.split(',');
    window.scrollTo(parseInt(scrollX, 10), parseInt(scrollY, 10));
  } else {
    window.scrollTo(0, 0);
  }
  appCtx.setBusy(false);
};

export { handleScroll };
