import css from 'styled-jsx/css';
import { typography, colors } from '../../../../config/styles';

const VerMasFacetContentStyle = css`
  .facet-title {
    font-size: ${typography.xl};
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
    padding: 10px 0 0 20px;
    color: ${colors.black.shade10};
  }
  .divider-line {
    width: 638.01px;
    margin: 10px auto;
    border: 1px solid #f0f0f0;
  }
  .scrollTo-container {
    color: ${colors.black.shade10};
    width: 623px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    :global(.scrollTo-section) {
      cursor: pointer;
      height: 18px;
      font-weight: 700;
      font-size: 14px;
    }

    :global(.disabled-ScrollTo) {
      color: #a9b5c0;
    }
  }

  .facet-sections-container {
    color: ${colors.black.shade10};
    height: 450px;
    overflow: auto;
    width: 630px;
    margin: 15px 0 0 10px;
  }

  .facet-section {
    margin-bottom: 10px;
  }

  .facet-section-title {
    height: 18px;
    font-weight: 700;
    font-size: 14px;
  }

  .facet-section-list-container {
    display: flex;
    flex-wrap: wrap;
  }
  .facet-section-list-item {
    height: 24px;
    width: 207.6px;
    margin-bottom: 5px;
  }

  .footer-container {
    display: flex;
    width: 314px;
    height: 42px;
    margin: 35px auto 5px;
    justify-content: space-between;
    align-items: center;
  }
  .clear-button-container {
    width: 95px;
    height: 26px;
    padding: 5px 0;
  }
  .viewResults-button-container {
    width: 203px;
    height: 42px;
  }
`;

export default VerMasFacetContentStyle;
