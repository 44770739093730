import { LoginModal } from '@digital-retail/falabella-ui-cross-components';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withCart } from '../../utils/CartContext';
import _ from '../../utils/LodashImports';
import { withProduct } from '../../utils/ProductContext';
import { withUser } from '../../utils/UserContext';
import noop from '../../utils/noop';
import MatchMedia from '../ui/MatchMedia/MatchMedia';
import { Modal } from '../ui/Modal/Modal';
import CartDesktop from './CartDesktop/CartDesktop';
import CartMobile from './CartMobile/CartMobile';
import SelectVariant from './SelectVariant/SelectVariant';

const CartSection = ({ setBusy, cart, hasCrossSelling, setCrossSelling }) => {
  const desktop = () => (
    <CartDesktop cart={cart} setBusy={setBusy} hasCrossSelling={hasCrossSelling} setCrossSelling={setCrossSelling} />
  );
  const noDesktop = () => <CartMobile cart={cart} setBusy={setBusy} />;

  return (
    <MatchMedia
      content={{
        desktop,
        mobile: noDesktop,
        tablet: noDesktop,
      }}
    />
  );
};

CartSection.propTypes = {
  setBusy: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired,
  hasCrossSelling: PropTypes.bool.isRequired,
  setCrossSelling: PropTypes.func.isRequired,
};

const Cart = ({ cart, appCtx, product, refreshUserData }) => {
  const { deviceType } = appCtx;
  const { totalProductsCount, isVariantSelectionVisible } = cart;

  const { linkedProducts, attributes, bundles } = product;
  const isTypeCAvailableInLightbox = _.get(appCtx, 'siteConfig.toggles.isTypeCAvailableInLightbox', false);

  const [hasCrossSelling, setCrossSelling] = useState(
    (bundles && bundles.length > 0) ||
      (isTypeCAvailableInLightbox && !_.isEmpty(linkedProducts)) ||
      (attributes && attributes.viewTemplate === 'TYPE_A')
  );
  const catalystBaseUrl = _.get(
    appCtx,
    'header.entry.header_configs[0].links.catalystbaseurl',
    'https://www.falabella.com'
  );

  const toggleLoginForm = () => {
    cart.setShowLoginForm(!cart.showLoginForm);
  };
  const onClose = () => {
    toggleLoginForm();
  };
  const onUserLogin = () => {
    refreshUserData();
  };

  const isNewWarrantyUIEnabled = _.get(appCtx, 'siteConfig.toggles.isNewWarrantyUIEnabled', false);

  const height = product.warrantyOptions && isNewWarrantyUIEnabled ? '100%' : 575;
  const cartModalHeight = hasCrossSelling ? height : 300;

  const modalContent = (actions) => {
    const { setBusy } = actions;
    return isVariantSelectionVisible ? (
      <SelectVariant cart={cart} />
    ) : (
      <CartSection setBusy={setBusy} cart={cart} hasCrossSelling={hasCrossSelling} setCrossSelling={setCrossSelling} />
    );
  };

  useEffect(() => {
    if (typeof totalProductsCount !== 'undefined') {
      appCtx.changeCartCount(totalProductsCount);
    }
  }, [totalProductsCount]);

  const modalHeight = isVariantSelectionVisible ? 490 : cartModalHeight;

  return (
    <>
      <Modal
        showCloseButton={false}
        toggleState={{ setVisible: cart.setShowCart, visible: cart.showCart }}
        content={modalContent}
        withPadding={false}
        fullScreen={deviceType !== 'desktop'}
        options={{
          disableOutsideClick: true,
          width: deviceType === 'desktop' ? 720 : '100vw',
          height: deviceType === 'desktop' ? modalHeight : '100vh',
          overflow: 'hidden',
        }}
        deviceType={deviceType}
      />
      <LoginModal
        id="testId-atc-cart"
        catalystBaseUrl={catalystBaseUrl}
        onClose={onClose}
        onUserLogin={onUserLogin}
        tenant={appCtx.regionCode}
        showLoginForm={cart.showLoginForm}
      />
    </>
  );
};

Cart.defaultProps = {
  refreshUserData: noop,
};
Cart.propTypes = {
  cart: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  refreshUserData: PropTypes.func,
};

export default withApplicationContext(withProduct(withCart(withUser(Cart))));
export { Cart, CartSection };
