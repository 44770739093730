import React from 'react';
import PropTypes from 'prop-types';
import ProdFeaturesStyles from './ProdFeatures.style';

const ProdFeatures = ({ features }) => {
  return (
    <ul className="section__pod-bottom-description">
      {features.map((feature) => (
        <li key={feature}>{feature}</li>
      ))}
      <style jsx>{ProdFeaturesStyles}</style>
    </ul>
  );
};

ProdFeatures.defaultProps = {
  features: [],
};

ProdFeatures.propTypes = {
  features: PropTypes.array,
};

export default ProdFeatures;
