import constants from '../../config/constants';
import {
  sponsoredClickService,
  sponsoredImpressionService,
  sponsoredVisitService,
} from '../../services/sponsoredService';
import _ from '../../utils/LodashImports';
import sponsoredEvents from '../../utils/SponsoredHelper';
import { isWindow } from '../../utils/deviceChecker';

const getFuntionAnalyticsSponsored = (isSponsoredService) => {
  if (isSponsoredService)
    return {
      sponsoredClickService,
      sponsoredImpressionService,
      sponsoredVisitService,
    };

  return {
    sponsoredClickService: sponsoredEvents.click,
    sponsoredImpressionService: sponsoredEvents.impression,
    sponsoredVisitService: sponsoredEvents.visit,
  };
};

const getPageId = (pageName) => {
  const pagesIds = {
    category: 'categoryId',
    search: 'Ntt',
  };

  return pagesIds[pageName] || '';
};

const getIsSponsoredDisplayEnabled = (appCtx) => {
  const { siteConfig, store = 'falabella' } = appCtx;

  const featureEnableSponsoredDisplay = _.get(siteConfig, 'toggles.featureEnableSponsoredDisplay', false);

  const disabledStoresSponsoredProductsJson = _.get(siteConfig, 'configurations.disabledStoresSponsoredProducts', {});

  const disabledStoresSponsoredProducts = _.get(disabledStoresSponsoredProductsJson, 'stores', []);

  const isSponsoredDisplayEnabled = featureEnableSponsoredDisplay && !disabledStoresSponsoredProducts.includes(store);

  return isSponsoredDisplayEnabled;
};

const getUrl = (url, store) => {
  const storesAvaliable = ['tottus', 'linio', 'sodimac', 'homecenter'];

  if (storesAvaliable.includes(store)) return `${url}?exp=${store}`;

  return url;
};
const getHandleBannerActionForSponsorDisplay = ({
  appCtx,
  config,
  categoryName,
  mPos,
  hasImpressionBeenSent,
  setHasImpressionBeenSent,
}) => {
  const { tenant, deviceType, regionCode, store } = appCtx;

  const { MABAYA_TOKEN, MABAYA_EVENTS_ENABLED, FEATURE_TOGGLE_SPONSORED_VAS_SERVICE } = config;

  const handleBannerAction = (product, action) => {
    /* istanbul ignore next */
    if (product != null) {
      const sku = product.id;
      const { adInfo } = product;
      const params = {
        mabayaToken: MABAYA_TOKEN,
        enabled: MABAYA_EVENTS_ENABLED,
        platform: deviceType,
        referrerUrl: window.location.href,
        sku,
        regionCode,
        adInfo,
        mPos,
        categoryName,
        tenant,
      };
      const isSponsoredService = FEATURE_TOGGLE_SPONSORED_VAS_SERVICE || false;
      const {
        sponsoredClickService: clickService,
        sponsoredImpressionService: impressionService,
        sponsoredVisitService: visitService,
      } = getFuntionAnalyticsSponsored(isSponsoredService);

      if (action === 'click') {
        clickService(params);
        const currentUrl = getUrl(product.url, store);
        window.location.href = currentUrl;
      }
      if (action === 'impression' && !hasImpressionBeenSent) {
        impressionService(params);
        setHasImpressionBeenSent(true);
      }

      if (action === 'visit') {
        visitService(params);
      }
    }
  };

  return handleBannerAction;
};

const getHandleBrandActionForSponsoredBrand = ({
  appCtx,
  config,
  categoryName,
  hasImpressionBeenSent,
  setHasImpressionBeenSent,
}) => {
  const { tenant, deviceType, regionCode, store } = appCtx;
  const { MABAYA_TOKEN, MABAYA_EVENTS_ENABLED, FEATURE_TOGGLE_SPONSORED_VAS_SERVICE } = config;
  const { BRANDS } = constants.AD_TYPES_FOR_VAS;

  const handleBrandAction = (product, eventType, mabayaAdInfo) => {
    const mabayaAdInfoString = mabayaAdInfo.join(',');
    const params = {
      mabayaToken: MABAYA_TOKEN,
      adType: BRANDS,
      enabled: MABAYA_EVENTS_ENABLED,
      platform: deviceType,
      referrerUrl: window.location.href,
      regionCode,
      adInfo: mabayaAdInfoString,
      categoryName,
      tenant,
    };

    const isSponsoredService = FEATURE_TOGGLE_SPONSORED_VAS_SERVICE || false;

    const { sponsoredClickService: clickService, sponsoredImpressionService: impressionService } =
      getFuntionAnalyticsSponsored(isSponsoredService);

    if (eventType === 'click') {
      clickService(params);
      const currentUrl = getUrl(product.url, store);
      window.location.href = currentUrl;
    }

    if (eventType === 'impression' && !hasImpressionBeenSent) {
      impressionService(params);
      setHasImpressionBeenSent(true);
    }
  };

  return handleBrandAction;
};

const getBaseDomain = (url) => {
  if (typeof url !== 'string') {
    return null;
  }
  const urlObj = new URL(url);
  return urlObj.origin;
};

const getUrlBase = (config) => {
  const urlBase = isWindow() ? config.API_HOST : config.CLUSTER_API_HOST;

  return getBaseDomain(urlBase);
};

let cachedFacetParams = {};
let cachedSearch = '';

const getFacetParams = () => {
  if (typeof window !== 'undefined') {
    const currentSearch = window.location.search;
    if (currentSearch === cachedSearch) {
      return cachedFacetParams;
    }
    cachedSearch = currentSearch;
    const urlParams = new URLSearchParams(window.location.search);
    cachedFacetParams = {};
    urlParams.forEach((value, key) => {
      cachedFacetParams[key] = value;
    });
    return cachedFacetParams;
  }
  return {};
};

function getShouldShowSponsoredBrand({ appCtx, pageName, name, deviceType, index }) {
  const isSponsorBrandEnabled = _.get(appCtx, 'siteConfig.toggles.isSponsorBrandEnabled', false);
  if (!isSponsorBrandEnabled) return false;

  const sponsorBrandPosition = _.get(appCtx, 'siteConfig.configurations.vasConfig.sponsorBrandPosition', {});
  const desktopItemsPerRow = 4;
  const mobileItemsPerRow = 2;
  const defaultSponsorBrandPosition = {
    desktop: 3 * desktopItemsPerRow,
    mobile: 6 * mobileItemsPerRow,
  };

  const isSearchPage = pageName === 'search';
  const positionPerDeviceType = isSearchPage
    ? sponsorBrandPosition.search || defaultSponsorBrandPosition
    : sponsorBrandPosition[name] || defaultSponsorBrandPosition;
  const indexForSB = positionPerDeviceType[deviceType];
  const shouldShowSponsoredBrand = index === indexForSB;

  return shouldShowSponsoredBrand;
}

export {
  getFacetParams,
  getFuntionAnalyticsSponsored,
  getHandleBannerActionForSponsorDisplay,
  getHandleBrandActionForSponsoredBrand,
  getIsSponsoredDisplayEnabled,
  getPageId,
  getShouldShowSponsoredBrand,
  getUrl,
  getUrlBase,
};
