import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../../../config/constants';
import { withApplicationContext } from '../../../../utils/ApplicationContext';
import { truncateText, ucFirst } from '../../../../utils/textHelper';
import BodyCopy from '../../../ui/BodyCopy/BodyCopy';
import Checkbox from '../../../ui/Checkbox/Checkbox';
import VerMasFacetCheckboxStyle from './VerMasFacetCheckbox.style';

const VerMasFacetCheckbox = ({ option, onChange, isModalView, isFacetCountHidden }) => {
  const { title, selected, count, facetType } = option;
  const isBrandFacet = constants.BRAND_FACET.test(facetType);
  const facetCase = () => {
    if (isBrandFacet) {
      return 'capitalize';
    }
    return 'ignoreCase';
  };
  return (
    <>
      <Checkbox
        isChecked={selected}
        onChange={onChange}
        id={`${isModalView ? 'verMas-' : ''}checkbox-${title.replace(/\s/g, '-').toUpperCase()}}`}
        type="mkp-succes"
        normalBorderClass=" mkp-normal-border"
        selectedBorderClass=" mkp-border"
        isPrimaryTheme
      >
        <BodyCopy size="title24" highlightedClass="normal">
          <span className={facetCase()}>
            {ucFirst(truncateText(title, constants.FACETS.VALUE_TEXT_CHARCOUNT, true))}
          </span>

          {!isFacetCountHidden ? <span className="label-count">&nbsp;&nbsp;{`(${count})`}</span> : null}
        </BodyCopy>
      </Checkbox>
      <style jsx>{VerMasFacetCheckboxStyle}</style>
    </>
  );
};

VerMasFacetCheckbox.propTypes = {
  option: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isModalView: PropTypes.bool,
  isFacetCountHidden: PropTypes.bool,
};

VerMasFacetCheckbox.defaultProps = {
  isModalView: false,
  isFacetCountHidden: true,
};

export { VerMasFacetCheckbox };
export default withApplicationContext(VerMasFacetCheckbox);
