const getTopScroll = () => {
  if (document.body.style.top) {
    return document.body.style.top;
  }

  const scrollY = window.scrollY || document.documentElement.scrollTop;
  return `-${scrollY}px`;
};

const disableScroll = () => {
  document.body.style.top = getTopScroll();
  document.body.style.position = 'fixed';
  document.body.style.width = '100%';
};

const enableScroll = () => {
  const scrollY = document.body.style.top;
  document.body.style.position = '';
  document.body.style.width = '';
  document.body.style.top = '';
  window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
};

export { disableScroll, enableScroll };
