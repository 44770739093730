import React, { useState } from 'react';
import { nameHOC } from './hocutils';

const WithTogggle = (WrappedComponent) => {
  const ComponentWithToggle = (props) => {
    const [toggle, setToggle] = useState(false);
    const toggleState = {
      visible: toggle,
      setVisible: (bool) => setToggle(bool),
    };
    return <WrappedComponent {...props} toggleState={toggleState} />;
  };
  ComponentWithToggle.originalName = nameHOC(WrappedComponent);
  ComponentWithToggle.displayName = nameHOC(WrappedComponent, 'WithToggle');
  return ComponentWithToggle;
};

export default WithTogggle;
