import css from 'styled-jsx/css';
import { colors } from '../../config/styles';

const SearchResultsStyles = css`
  .search-results {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .search-results--actionbar {
    display: none;
    @mixin tabletToDesktop {
      display: block;
      padding-left: 10px;
    }
  }
  .search-results--actionbar_bottom {
    margin-top: 10px;
    padding: 16px 0;
    background-color: ${colors.white.shade1};
    position: relative;
    border-radius: var(--border-radius);

    @mixin tabletToDesktop {
      padding: 16px;
      margin-left: 10px;
      margin-bottom: 10px;
    }

    & .paginationDetail {
      position: absolute;
      top: 15px;
      bottom: 15px;
      left: 15px;
      line-height: 35px;
    }
  }
  .search-results--cell {
    width: 50%;
    @mixin tabletToDesktop {
      width: 25%;
    }
  }

  .search-results--linearCell {
    width: 100%;
  }

  .search-results--products {
    display: flex;
    flex: 1 0 auto;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      flex: inherit;
    }
  }
  .container-my-lists-alert {
    margin-left: 10px;
  }

  .bannner-styles {
    margin-top: 13px;
    display: grid;
    place-items: center;
  }
  .sponsored-brand-styles {
    width: 100%;
    flex-direction: row;
    display: flex;
    padding-top: 1px;
    padding-bottom: 18px;
    margin-left: 12px;
    @mixin mobileToTablet {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`;

export default SearchResultsStyles;
