import PropTypes from 'prop-types';
import React, { useState } from 'react';
import noop from '../../../utils/noop';
import { InputBoxStyles } from './InputBox.style';

const InputBox = (props) => {
  const { label, hideLabel, type, id, onChange, placeholder, onFocus } = props;

  const [searchQuery, setSearchQuery] = useState('');
  const handleChange = (e) => {
    const { value } = e.target;

    onChange(value);
    setSearchQuery(value);
  };
  const clearSearch = () => {
    setSearchQuery('');
    onChange('');
  };
  const renderCrossIcon = () => {
    return searchQuery.length ? (
      <>
        <button id={`${id}-clear-button`} type="button" onClick={clearSearch}>
          <i className="cancelButton" id={`${id}-clear-button-icon`} />
        </button>
        <style jsx>{InputBoxStyles}</style>
      </>
    ) : null;
  };
  return (
    <>
      {!hideLabel && <label htmlFor={id}>{label}:</label>}
      <input
        className="inputBox"
        type={type}
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={onFocus}
        id={id}
        value={searchQuery}
      />
      {renderCrossIcon()}
      <style jsx>{InputBoxStyles}</style>
    </>
  );
};

InputBox.defaultProps = {
  placeholder: 'Buscar...',
  type: 'text',
  hideLabel: false,
  onFocus: noop,
};

InputBox.propTypes = {
  label: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
};

export default InputBox;
