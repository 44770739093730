const CONFIGS = {
  desktop: {
    brandName: {
      size: 'title17',
      type: 'primary',
    },
    description: {
      size: 'title19',
      type: 'primary',
    },
    price: 'small',
  },
  mobile: {
    brandName: {
      size: 'title18',
      type: 'primary',
    },
    description: {
      size: 'title20',
      type: 'primary',
    },
    price: 'medium_super',
  },
};

export { CONFIGS };
