import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';

const checkBoxStyles = css`
  .checkbox {
    cursor: pointer;
    position: relative;
    display: flex;
    width: 100%;
    padding: 5px 10px;
    @mixin tabletToDesktop {
      padding: 0;
    }
    .title {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: ${typography.sm};
      color: ${colors.black.shade1};
      text-transform: uppercase;
      font-weight: 700;
      padding: 1px 0;
      position: relative;

      &::before {
        content: '';
        display: inline-flex;
        height: 20px;
        width: 20px;
        margin: 6px;
        margin-right: 17px;
        border-radius: 50%;
        border: 1px solid ${colors.gray.shade35};
        @mixin tabletToDesktop {
          margin-right: 22px;
          height: 17px;
          width: 17px;
        }
      }
    }
    .count {
      font-weight: normal;
      margin-left: 5px;
    }

    &.selected {
      .title {
        &::before {
          height: 20px;
          width: 20px;
          margin: ;
          padding: 0;
          border: 1px solid ${colors.gray.shade33};
          @mixin tabletToDesktop {
            width: 17px;
            height: 17px;
          }
        }
        &::after {
          border: 1px solid ${colors.gray.shade5};
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 1px;
          content: '';
          display: inline-flex;
          height: 32px;
          width: 32px;
          @mixin tabletToDesktop {
            left: 1px;
            top: 2px;
            width: 27px;
            height: 27px;
          }
        }
      }
    }
  }

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
  }
`;

const multiselectForColorStyle = css`
  .multiselectForColor {
    overflow-y: auto;
    margin: 15px 0;
    @mixin tabletToDesktop {
      margin: 12px 0 16px;
      max-height: 245px;
      border-bottom: 0;
    }
    li {
      padding: 0 10px;
      @mixin tabletToDesktop {
        padding: 0;
        margin-bottom: 6px;
        border-bottom: 0;
      }
    }
  }
`;

export { multiselectForColorStyle, checkBoxStyles };
