import css from 'styled-jsx/css';
import colors from '../../config/styles/colors';
import zIndex from '../../config/styles/zIndex';

const PriceRangeStyle = css`
  input {
    border: 0;
    outline: 0;
    width: 100%;
  }

  .input-wrapper {
    background: #fff;
    height: 45px;
    position: relative;

    @mixin phabletToDesktop {
      height: 40px;
    }

    border: 1px solid ${colors.gray.shade5};
    border-radius: 3px;
    font-size: 13px;
    color: ${colors.black.shade1};
    display: flex;
    align-items: center;
    flex-grow: 1;

    label {
      position: absolute;
      font-size: 14px;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      z-index: ${zIndex.input.prependIcon};

      @mixin phabletToDesktop {
        left: 5px;
      }
    }

    input[type='number'] {
      font-size: 16px;
      transform: scale(0.82);
      transform-origin: left top;
      width: 115%;
      display: block;
      margin-bottom: -3px;
      margin-right: -33%;
      padding: 0 10px 0 30px;

      @mixin phabletToDesktop {
        font-size: 14px;
        padding: 0 5px 0 25px;
        transform: scale(1);
        padding-left: 13px;
        width: 100%;
        margin-bottom: 0;
        margin-right: 0;
        border: 5px solid transparent;
      }

      &::-ms-clear {
        display: none;
      }

      -moz-appearance: textfield;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  .input-wrapper.active {
    border: 1px solid ${colors.gray.shade5};
    color: ${colors.black.shade1};
  }
  .input-wrapper.error {
    border: 1px solid ${colors.red.shade1};
    color: ${colors.black.shade1};
  }
`;

export { PriceRangeStyle };
