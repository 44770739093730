import css from 'styled-jsx/css';
import colors from '../../config/styles/colors';
// import { svgToDataUri } from '../../utils/styles/svgMixins';
// import { icons } from '../../config/styles';

const AdditionalMessageStyles = css`
  .additional-label-in-pdp {
    padding-bottom: 30px;
    padding-top: 5px;
    display: flex;
    line-height: 1.5;
    background-color: ${colors.white.shade1};
    &.warranty {
      padding-top: 25px;
    }
    &.softline {
      padding-top: 15px;
      padding-bottom: 18px;
      border-top: 1px solid ${colors.gray.shade3};
      border-bottom: 1px solid ${colors.gray.shade3};
      margin-bottom: 18px;
    }
    .icon {
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 63%;
      width: 40px;
      height: 40px;
      margin-top: -6px;
      i {
        font-size: 2rem;
        color: ${colors.green.shade1};
      }
    }
    .label-content {
      padding-left: 4px;
      color: ${colors.black.shade10};
    }
    .label-header {
      font-weight: 600;
      padding-bottom: 4px;
      font-size: 1.4rem;
    }
    .label-description {
      padding-bottom: 2px;
      font-size: 1.3rem;
    }
    .underline {
      color: ${colors.gray.shade40};
      text-decoration: none;
      font-size: 1.2rem;
      border-bottom: 0.5px solid ${colors.gray.shade40};
    }
    .caret {
      font-size: 0.8rem;
      padding-left: 6px;
      display: inline-block;
    }
  }
  @media screen and (max-width: 1023px) {
    .additional-label-in-pdp {
      padding-bottom: 25px;
      padding-top: 0;
      margin-top: 20px;
      padding-left: 0;
      .icon {
        width: 50px;
        height: 50px;
        margin-top: -11px;
      }
      .label-header {
        padding-bottom: 0;
        font-size: 1.6rem;
      }
      .label-description {
        font-size: 1.4rem;
      }
      .underline {
        font-size: 1.4rem;
      }
      &.warranty {
        padding: 15px 25px 25px 30px;
        margin-top: 0;
      }
      &.softline {
        margin-top: 15px;
        border-bottom: none;
        padding: 15px 12px 25px 12px;
        margin-bottom: 0;
      }
    }
  }
`;

export { AdditionalMessageStyles };
