import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';

const reviewStyle = css`
  .reviewCount {
    margin: 0 0 0 5px;
    color: ${colors.gray.shade5};
    font-size: ${typography.xs1};
    line-height: 11px;

    @mixin tabletToDesktop {
      font-size: ${typography.sm};
      line-height: 15px;
    }
  }

  .reviewCount-LIST {
    font-size: ${typography.xs2};
  }
  .reviewCount-1_GRID {
    font-size: ${typography.lg};
  }
  .reviewCount-PDP {
    color: ${colors.black.shade1};
    font-size: ${typography.base};
  }
`;
export default reviewStyle;
