import constants from '../config/constants';
import _ from './LodashImports';

const cartAlertsAndErrorSeggregator = (alerts, siteConfig) => {
  const errors = [];
  const limitAlerts = {};
  alerts.forEach((alert) => {
    const alertCode = alert.alertCode.toUpperCase() || '';
    const { cartLineId = 'basket' } = alert;
    const error = constants.COMMERCE_API_ALERTS[alertCode];
    const limitAlert = constants.COMMERCE_LIMIT_API_ALERTS[alertCode];
    if (error) {
      errors.push({
        message: _.get(
          siteConfig,
          alertCode === 'AL013' ? undefined : `textDictionary.A2C_ERROR_${alertCode}`,
          error.message
        ),
      });
    }
    if (limitAlert) {
      limitAlerts[cartLineId] = {
        message: limitAlert.message,
      };
    }
  });
  return {
    mappedErrors: errors.length > 0 ? errors : undefined,
    limitAlerts: Object.keys(limitAlerts).length > 0 ? limitAlerts : undefined,
  };
};

const getErrorMessage = (errors) => {
  const errorResponse = errors[0];
  const alertCode = _.get(errorResponse, 'code', '');
  const error = constants.COMMERCE_LIMIT_API_ERRORS[alertCode];
  return _.get(error, 'message', _.get(errorResponse, 'message', ''));
};

export { cartAlertsAndErrorSeggregator, getErrorMessage };
