const setDigitalData = (clickedType, clickedValue) => {
  let payload = 'All';
  if (!!clickedType && !!clickedValue) {
    payload = `${clickedType} facet|${clickedValue}`;
  }
  const eventToDigitalData = new CustomEvent('DDXLPUnselectionFilters', {
    bubbles: true,
    detail: { payload },
  });
  window.dispatchEvent(eventToDigitalData);
};

export { setDigitalData };
