import css from 'styled-jsx/css';
import { colors, typography } from '../../config/styles';

const SelectedFiltersStyles = css`
  .selected-filters {
    border-top: 1px solid ${colors.gray.shade14};
    border-bottom: 1px solid ${colors.gray.shade14};
    background-color: #eee;
    height: 70px;
    margin-top: -10px;
    position: relative;
  }
  .filter-container {
    max-height: 100px;
    display: flex;
    position: relative;
    flex-direction: column;
    padding-left: 12px;
  }
  /* stylelint-disable font-family-no-missing-generic-family-keyword */
  .filter-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .chips {
    display: flex;
    overflow-x: auto;
    padding-right: 8px;
    margin-left: -2px;
    position: relative;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.black.shade1};
    font-size: ${typography.sm};
    cursor: pointer;
    outline: none;
  }
  i {
    font-size: 14px;
  }
`;

export default SelectedFiltersStyles;
