import React from 'react';
import PropTypes from 'prop-types';
import styles from './FacetsWithSwitch.style';

const FacetsWithSwitch = ({ items }) => {
  if (items.length <= 0) {
    return null;
  }

  return (
    <div className="facets-with-switch-container">
      {items.map((item) => item.content())}
      <style jsx>{styles}</style>
    </div>
  );
};

FacetsWithSwitch.propTypes = {
  items: PropTypes.array.isRequired,
};

export default FacetsWithSwitch;
