import css from 'styled-jsx/css';
import { typography, colors } from '../../../config/styles';

const headingStyles = css`
  .title1 {
    font-size: ${typography.lg1};
    font-weight: 400;
    color: ${colors.gray.shade15};
  }
  .title2 {
    font-size: ${typography.base};
    font-weight: 400;
    text-transform: uppercase;
    color: ${colors.gray.shade5};
  }
  .title3 {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.075em;
    font-size: ${typography.sm};
    color: ${colors.gray.shade5};
  }
  .title4 {
    font-size: ${typography.base};
    font-weight: 400;
    text-transform: uppercase;
    color: ${colors.gray.shade24};
  }
  .title5 {
    font-size: ${typography.xl};
    font-weight: 400;
  }
  .title6 {
    font-size: ${typography.xl3};
    font-weight: 400;
  }
  .title7 {
    font-size: ${typography.base};
    font-weight: 400;
    letter-spacing: 0.14px;
    line-height: 17px;
    color: ${colors.gray.shade15};
  }
  .title8 {
    color: ${colors.black.shade1};
    font-weight: bold;
    margin-bottom: 10px;
    font-size: ${typography.lg1};

    @mixin tabletToDesktop {
      font-size: ${typography.base};
    }
  }
  .title9 {
    color: ${colors.black.shade7};
    font-weight: bold;
    letter-spacing: 0.95px;
    line-height: 16px;
    font-size: ${typography.sm1};

    @mixin desktop {
      font-size: ${typography.base};
    }
  }
  .title10 {
    font-size: ${typography.xl};
    font-weight: 400;
    color: ${colors.black.shade1};

    @mixin smallMobileOnly {
      font-size: ${typography.xl2b};
    }
  }
  .title11 {
    font-size: ${typography.xl3};
    font-weight: 300;
  }
  .title12 {
    font-size: ${typography.xl3};
    letter-spacing: 0.00756rem;
    font-weight: 300;
    line-height: 3.5rem;
  }
  .title13 {
    font-size: ${typography.lg1};
    font-weight: 400;
    color: ${colors.gray.shade15};
    line-height: 19.2px;
    letter-spacing: 0.19px;
  }
  .title14 {
    font-size: ${typography.xl2};
    font-weight: 400;
    line-height: 3rem;
    color: ${colors.black.shade1};
    @mixin mobileToTablet {
      line-height: 2.5rem;
    }
  }
  .primary {
    color: ${colors.green.shade1};
  }
  .secondary {
    color: ${colors.gray.shade15};
  }
  .mkp-primary {
    color: var(--secondary-color);
    font-weight: 400;
  }

  .mkp-secondary {
    color: ${colors.gray.shade40};
  }

  .highlighted {
    font-weight: 700;
  }
  .light {
    font-weight: 300;
  }
  .inline {
    display: inline-block;
  }
  .center {
    text-align: center;
  }
  .left {
    text-align: left;
  }
  .right {
    text-align: right;
  }
  .justify {
    text-align: justify;
  }
`;

export default headingStyles;
