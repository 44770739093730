import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withLabels } from '../../utils/LabelsContext';
import _ from '../../utils/LodashImports';
import noop from '../../utils/noop';
import MediaComponent from '../Media/MediaComponent';
import PriceRange from '../PriceRange/PriceRange';
import { FacetsPriceRangeStyle } from './FacetsPriceRange.style';

const FacetsPriceRange = ({
  appCtx,
  labels,
  items,
  onFacetChange,
  onFacetClicked,
  priceRangeErrorLabel,
  priceRangeNoResultsLabel,
  priceUnit,
}) => {
  const showCustomPlaceHolderForPriceRange = _.get(
    appCtx,
    'siteConfig.toggles.showCustomPlaceHolderForPriceRange',
    false
  );
  const priceRangeFromLabel = labels.PRICE_RANGE_FROM;
  const priceRangeToLabel = labels.PRICE_RANGE_TO;
  const { groupFacet, facetType } = items[0];

  const initialMax = items[1].facetValue;
  const initialMin = items[0].facetValue;

  const [rangeFrom, setRangeFrom] = useState('');
  const [isUserEnteredPriceRange, setUserEnteredPriceRange] = useState(false);
  const [rangeTo, setRangeTo] = useState('');

  useEffect(() => {
    setRangeFrom(items[0].title);
    setRangeTo(items[1].title);
  }, [items]);

  const [error, setError] = useState(false);

  const handleRangeChange = (type) => (val) => {
    setUserEnteredPriceRange(true);
    if (type === 'rangeTo') {
      setRangeTo(val);
    }
    if (type === 'rangeFrom') {
      setRangeFrom(val);
    }
  };

  const publishNewUrl = (facetValue) => {
    onFacetChange({
      groupFacet,
      facetType,
      facetValue,
      dontMakeFacetCall: true,
    });
  };

  const getMaxAndMin = () => {
    const max = parseInt(rangeTo || initialMax, 10);
    const min = parseInt(rangeFrom || initialMin, 10);

    return { max, min };
  };

  const validatePrice = () => {
    const userMax = Number(rangeTo);
    const userMin = Number(rangeFrom);

    const isUserInputValid =
      (!userMin && !userMax) ||
      (userMin && userMax && userMax >= userMin) ||
      (userMax && !userMin) ||
      (userMin && !userMax);

    if (!isUserInputValid) {
      return { isValid: false, message: priceRangeErrorLabel };
    }

    const { max, min } = getMaxAndMin();

    if (max < initialMin || min > initialMax) {
      return { isValid: false, message: priceRangeNoResultsLabel };
    }

    return { isValid: true, message: null };
  };

  const handleRangeSelect = () => {
    const priceValidation = validatePrice();
    if (priceValidation.isValid) {
      setError(false);
      const { min, max } = getMaxAndMin();
      if (appCtx.deviceType === 'desktop') {
        onFacetClicked({
          groupFacet,
          url: items[0].url.replace('MAX_VALUE', max).replace('MIN_VALUE', min),
        });
      } else {
        onFacetChange({
          url: items[0].url.replace('MAX_VALUE', max).replace('MIN_VALUE', min),
          groupFacet,
          facetType,
          facetValue: [`${max}::${min}`],
        });
      }
    } else {
      setError(priceValidation.message);
    }
  };

  const handleInputChange = async () => {
    let inputError = false;

    const priceValidation = validatePrice();
    if (!priceValidation.isValid) {
      inputError = priceValidation.message;
    }
    setError(inputError);

    if (appCtx.deviceType === 'desktop') {
      return;
    }

    if (inputError) {
      publishNewUrl([]);
      return;
    }

    if ((!rangeTo && !rangeFrom) || (rangeFrom === items[0].title && rangeTo === items[1].title)) {
      publishNewUrl([`${rangeTo}::${rangeFrom}`]);
      return;
    }

    const { max, min } = getMaxAndMin();
    publishNewUrl([`${max}::${min}`]);
  };

  useEffect(() => {
    if (isUserEnteredPriceRange) {
      handleInputChange();
    }
  }, [rangeTo, rangeFrom]);

  return (
    <div data-facet-type={_.get(items, '[0].facetType', '')} className="price-range-wrapper">
      <div className="price-range-container">
        <PriceRange
          onChange={handleRangeChange('rangeFrom')}
          id="testId-range-from"
          value={rangeFrom}
          priceUnit={priceUnit}
          placeholder={showCustomPlaceHolderForPriceRange ? priceRangeFromLabel : items[0] && items[0].facetValue}
          error={error}
        />
        <span className={`range ${rangeFrom && rangeTo ? 'active' : ''}`} />
        <PriceRange
          onChange={handleRangeChange('rangeTo')}
          id="testId-range-to"
          value={rangeTo}
          priceUnit={priceUnit}
          placeholder={showCustomPlaceHolderForPriceRange ? priceRangeToLabel : items[1] && items[1].facetValue}
          error={error}
        />
        <div className="priceRangeButton">
          <MediaComponent from={appCtx.deviceType}>
            <button onClick={handleRangeSelect} type="button" disabled={!(rangeFrom || rangeTo) || error}>
              {labels.PRICE_RANGE_FILTER_BUTTON}
            </button>
          </MediaComponent>
        </div>
      </div>
      {error && <p className="error-text">{error}</p>}
      {appCtx.deviceType === 'desktop' && <div className="divider-line" />}
      <style jsx> {FacetsPriceRangeStyle}</style>
    </div>
  );
};

export default withApplicationContext(withLabels(FacetsPriceRange));
export { FacetsPriceRange };

FacetsPriceRange.defaultProps = {
  onFacetChange: noop,
  onFacetClicked: noop,
  priceRangeErrorLabel: '',
  priceRangeNoResultsLabel: '',
  priceUnit: '$',
  labels: {},
};

FacetsPriceRange.propTypes = {
  appCtx: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  onFacetChange: PropTypes.func,
  onFacetClicked: PropTypes.func,
  priceRangeErrorLabel: PropTypes.string,
  priceRangeNoResultsLabel: PropTypes.string,
  priceUnit: PropTypes.string,
  labels: PropTypes.object,
};
