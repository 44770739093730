import css from 'styled-jsx/css';
import { colors } from '../../../../config/styles';

const cssTriangleStyles = css`
  .pointer {
    position: relative;
    &.rebranded {
      &::before {
        border: 1px solid var(--secondary-color);
        border-right: transparent;
        border-bottom: transparent;
      }
    }
    &::before {
      border: 1px solid ${colors.green.shade12};
      content: '';
      height: 15px;
      left: 16px;
      top: -4px;
      background-color: ${colors.green.shade13};
      width: 15px;
      z-index: 10;
      border-right: transparent;
      border-bottom: transparent;
      position: absolute;
      transform: rotate(45deg);
    }
  }
`;

export { cssTriangleStyles };
