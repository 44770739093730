const addToCart = (cartLine, quantity) => {
  window.dispatchEvent(
    new CustomEvent('DDXLPA2CAddToCart', {
      bubbles: true,
      detail: {
        SKU: cartLine.cartLineNumber,
        defaultParentCategory: cartLine.defaultParentCategory,
        merchantCategory: cartLine.merchantCategory,
        price: cartLine.prices[0].price.centAmount,
        quantity,
      },
    })
  );
};
const removeFromCart = (cartLine, quantity) => {
  window.dispatchEvent(
    new CustomEvent('DDXLPA2CRemoveFromCart', {
      bubbles: true,
      detail: {
        SKU: cartLine.cartLineNumber,
        defaultParentCategory: cartLine.defaultParentCategory,
        merchantCategory: cartLine.merchantCategory,
        price: cartLine.prices[0].price.centAmount,
        quantity,
      },
    })
  );
};

export { addToCart, removeFromCart };
