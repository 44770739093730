import PropTypes from 'prop-types';
import React from 'react';
import apiConfig from '../../../config/api/apiConfig';
import constants from '../../../config/constants';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withLabels } from '../../../utils/LabelsContext';
import _ from '../../../utils/LodashImports';
import { withProduct } from '../../../utils/ProductContext';
import noop from '../../../utils/noop';
import BodyCopy from '../../ui/BodyCopy/BodyCopy';
import Button from '../../ui/Button/Button';
import LinkButton from '../../ui/LinkButton/LinkButton';
import { renderBundleItem, renderCollections, showWarningMessage } from '../Cart.Utils';
import CartItem from '../CartItem/CartItem';
import { CartAddedBundleStyles, CartBrandNameStyle, CartDescriptionStyle, CartMobileStyles } from './CartMobile.style';

const CartMobile = ({ cart, setBusy, labels, product, appCtx }) => {
  const { bundles, attributes, disableQuantitySelection } = product;
  const { viewTemplate } = attributes;

  const { regionCode } = appCtx;

  const {
    cartItems,
    bundleItems,
    textDictionary,
    addProduct,
    removeProduct,
    setWarrantyOption,
    setAssemblyOption,
    bundledProductOrigin,
    typeACollections = [],
    typeCCollections = [],
    addCollection,
    endPoints,
    removeCollection,
    setWarrantyOptionCurrent,
    limitAlerts,
  } = cart;
  if (!cartItems) {
    return null;
  }
  const { A2C_CONTINUE_SHOPPING = '', A2C_VIEW_BASKET_MKP = 'Ir al Carro' } = _.get(
    appCtx,
    'siteConfig.textDictionary',
    {}
  );

  const isTypeCAvailableInLightbox = _.get(appCtx, 'siteConfig.toggles.isTypeCAvailableInLightbox', false);

  const { variants, currentVariant, id: productId, brandName, linkedProducts = {}, stockUnits } = product;

  const guideSymbol = _.get(product, 'guideSymbol', false);
  const isSoftlineProduct = product.layoutType === constants.PRODUCT_CONSTANTS.SOFTLINE;

  const {
    colorCode,
    size = '',
    colorName = '',
  } = _.get(
    variants.find(({ id }) => id === currentVariant),
    'attributes',
    {}
  );

  const getVariantDetails = () => {
    if (colorName && size) {
      return `${colorName},${size}`;
    }
    return `${colorName} ${size}`;
  };

  const variantDetails = isSoftlineProduct || guideSymbol ? getVariantDetails() : '';

  const imageBaseURL = apiConfig.getImagesURL(regionCode);

  const renderBrandName = (brand) => (
    <BodyCopy size="copy4" type="secondary" isHighlighted>
      <span className="brand-name">{`${brand}`.toUpperCase()}</span>
      <style jsx>{CartBrandNameStyle}</style>
    </BodyCopy>
  );

  const renderDescription = (displayName) => (
    <BodyCopy size="copy14">
      <span className="description">{displayName}</span>
      <style jsx>{CartDescriptionStyle}</style>
    </BodyCopy>
  );

  const availableLinkedProducts = Object.keys(linkedProducts).map((id) => linkedProducts[id]);
  const isTypeC = isTypeCAvailableInLightbox && availableLinkedProducts.length !== 0;
  const isTypeA = viewTemplate === constants.VIEW_TEMPLATE.TYPE_A;

  const isBundlesAvailable = bundles && bundles.length !== 0;

  const closeModal = () => {
    setBusy(false);
    cart.setShowCart(false);
  };

  return (
    <div className="cart-mobile">
      <div className="header  mkp}">
        <i className="csicon-icon-success-circle">
          <span className="sr-only">{labels.ITEM_ADDED_A11Y_LABEL}</span>
        </i>

        <span className="label">{labels.ITEM_ADDED_IN_CART}</span>

        <button type="button" onClick={closeModal}>
          <i className="csicon-danger">
            <span className="sr-only">{labels.CLOSE_A11Y_LABEL}</span>
          </i>
        </button>
      </div>

      <div className="body">
        <div className="cart-items">
          {cartItems &&
            cartItems.map((item) => (
              <CartItem
                key={item.skuId}
                setBusy={setBusy}
                item={item}
                endPoints={endPoints}
                textDictionary={textDictionary}
                onRemoveFromCart={removeProduct}
                setWarrantyOption={setWarrantyOption}
                setAssemblyOption={setAssemblyOption}
                onAddToCart={addProduct}
                showButton={false}
                variantDetails={variantDetails}
                disableQuantitySelection={disableQuantitySelection}
                setWarrantyOptionCurrent={setWarrantyOptionCurrent}
                limitAlerts={limitAlerts}
              />
            ))}
        </div>

        {isBundlesAvailable && (
          <div className="bundles">
            {renderBundleItem({
              bundledProductOrigin,
              bundles,
              product,
              labels,
              imageBaseURL,
              CartAddedBundleStyles,
              bundleItems,
              setBusy,
              textDictionary,
              removeProduct,
              setWarrantyOption,
              setWarrantyOptionCurrent,
              addProduct,
              renderDescription,
              renderBrandName,
              isTypeA,
              limitAlerts,
            })}
          </div>
        )}
        {(isTypeA || isTypeC) && (
          <div className="collections">
            {renderCollections({
              labels,
              variants: isTypeC ? availableLinkedProducts : variants,
              colorCode,
              size,
              brandName,
              imageBaseURL,
              productId,
              setBusy,
              textDictionary,
              isBundlesAvailable,
              removeProduct: async (props) => {
                removeCollection({ ...props, isTypeC });
              },
              addProduct: async (props) => {
                addCollection({ ...props, isTypeC });
              },
              cartItems,
              collection: isTypeC ? typeCCollections : typeACollections,
              isTypeC,
              limitAlerts,
            })}
          </div>
        )}
      </div>

      <div className="footer  mkp}">
        {showWarningMessage(stockUnits, cart) && (
          <div className="warning">
            <i className="csicon-alert">
              <span className="sr-only">{labels.WARNING_A11Y_LABEL}</span>
            </i>
            <span>{labels.LOW_STOCK_WARNING}</span>
          </div>
        )}
        <div className="sub-footer">
          <div className="addToCart-btn">
            <LinkButton href={_.get(endPoints, 'baskePagetUri.path', '/')} type="mkp-secondary">
              {A2C_VIEW_BASKET_MKP}
            </LinkButton>
          </div>
          <Button type="tertiary-link" onClick={closeModal}>
            {A2C_CONTINUE_SHOPPING}
          </Button>
        </div>
      </div>
      <style jsx>{CartMobileStyles}</style>
    </div>
  );
};

CartMobile.defaultProps = {
  setBusy: noop,
};

CartMobile.propTypes = {
  cart: PropTypes.object.isRequired,
  setBusy: PropTypes.func,
  labels: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
};

export default withApplicationContext(withLabels(withProduct(CartMobile)));
export { CartMobile };
