import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import _ from '../../../utils/LodashImports';
import noop from '../../../utils/noop';
import BodyCopy from '../BodyCopy/BodyCopy';
import { checkBoxStyles, multiselectForColorStyle } from './MultiselectForColor.style';

const RoundedCheckBox = ({ item, onChange, isFacetCountHidden }) => {
  const { title, count, selected: itemSelected, value } = item;
  const [selected, setSelected] = useState(itemSelected);

  useEffect(() => {
    setSelected(item.selected);
  }, [item]);

  const handleChange = () => {
    onChange({
      ...item,
      selected: !selected,
    });
    setSelected(!selected);
  };

  return (
    <label htmlFor={`chk-${title}`} className={`${selected ? 'checkbox selected' : 'checkbox'}`}>
      <input id={`chk-${title}`} type="checkbox" onChange={handleChange} className={`${selected && 'checked'}`} />
      <div className="title">
        <BodyCopy size="title1" isHighlighted>
          {title}
        </BodyCopy>
        {!isFacetCountHidden ? <BodyCopy size="title1">&nbsp;&nbsp;({count})</BodyCopy> : null}
      </div>
      <style jsx>{checkBoxStyles}</style>
      <style jsx>
        {`
          .title::before {
            background-color: ${value || '#fff'};
          }
        `}
      </style>
    </label>
  );
};

RoundedCheckBox.defaultProps = {
  onChange: noop,
  isFacetCountHidden: false,
};

RoundedCheckBox.propTypes = {
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  isFacetCountHidden: PropTypes.bool,
};

const MultiselectForColor = (props) => {
  const { item, onFacetChange, isFacetCountHidden } = props;

  return (
    <ul data-facet-type={_.get(item, 'values[0].facetType', '')} className="multiselectForColor">
      {item.values.map((data) => {
        return (
          <li key={data.title} id={`testId-multiSelectForColor-${data.title}`}>
            <RoundedCheckBox onChange={onFacetChange} item={data} isFacetCountHidden={isFacetCountHidden} />
          </li>
        );
      })}
      <style jsx>{multiselectForColorStyle}</style>
    </ul>
  );
};

MultiselectForColor.defaultProps = {
  onFacetChange: noop,
  isFacetCountHidden: false,
};

MultiselectForColor.propTypes = {
  item: PropTypes.object.isRequired,
  onFacetChange: PropTypes.func,
  isFacetCountHidden: PropTypes.bool,
};

export { MultiselectForColor, RoundedCheckBox };
