import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { snackbarStyle } from './Snackbar.style';
import WithTimeout from '../../../utils/WithTimeout';
import WithOutsideClick from '../../../utils/WithOutsideClick';
import noop from '../../../utils/noop';

const TIMEOUT_MS = 5000;

const Snackbar = ({ content, type, toggleState, timer, dismissOnOutsideClick, page }) => {
  const { visible, setVisible } = toggleState;

  const dismissHandler = () => {
    setVisible(false);
    timer.setToggle(false);
  };

  useEffect(() => {
    if (visible) {
      timer.setToggle(true);
    }
  }, [visible]);

  return (
    <WithOutsideClick
      onOutsideClick={() => {
        if (dismissOnOutsideClick) {
          dismissHandler();
        }
      }}
    >
      {visible && timer.toggle && (
        <div className={`snackbar ${type} ${page}`}>
          <i className="csicon-icon-success-circle" />
          {content()}
        </div>
      )}
      <style jsx>{snackbarStyle}</style>
    </WithOutsideClick>
  );
};

Snackbar.propTypes = {
  type: PropTypes.oneOf(['success']),
  content: PropTypes.func.isRequired,
  toggleState: PropTypes.object,
  timer: PropTypes.object,
  dismissOnOutsideClick: PropTypes.bool,
  page: PropTypes.string,
};

Snackbar.defaultProps = {
  type: '',
  timer: { setToggle: noop, toggle: true },
  toggleState: null,
  dismissOnOutsideClick: true,
  page: '',
};
const SnackbarWithTimeout = WithTimeout(Snackbar, TIMEOUT_MS);

export { SnackbarWithTimeout };
export default Snackbar;
