/* stylelint-disable declaration-no-important */
import css from 'styled-jsx/css';
import { colors, icons, zIndex } from '../../../config/styles';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

const checkboxStyles = css`
  .checkbox {
    cursor: pointer;
    position: relative;
    display: flex;
    width: 100%;
    .csicon-succes {
      color: ${colors.green.shade1};
      position: absolute;
      top: 5px;
      left: 2px;
      z-index: ${zIndex.facet.icon};
      font-size: 16px;
      @mixin tabletToDesktop {
        top: 4px;
        font-size: 18px;
      }
    }
    &.disabled {
      cursor: no-drop;
      *,
      span {
        cursor: no-drop;
      }
    }
    .csicon-mkp-succes {
      content: var(--checkbox-rebranded);
      color: var(--checkbox-color);
      position: absolute;
      text-align: center;
      top: 5px;
      left: 2.5px;
      bottom: 3.5px;
      right: 2.5px;
      width: 16px;
      height: 14px;
      z-index: ${zIndex.facet.icon};
      font-size: 16px;
      @mixin mobileToTablet {
        transform: scale(1, 1);
      }
      @mixin tabletToDesktop {
        font-size: 18px;
      }
      &.in-floor-calc {
        content: url('${svgToDataUri(icons.floorCalcCheckbox)}');
        height: 9px;
        width: 11px;
      }
      &.shipping-checkbox {
        top: 33%;
      }
      &.shipping {
        @mixin mobileToTablet {
          position: relative;
          left: 19px;
        }
      }
      &.round-checkbox {
        content: var(--checkbox-round-rebranded);
        color: var(--checkbox-color);
        top: 8.5px;
        width: 17px;
        height: 12px;
      }
    }
    .csicon-mkp-bundle-succes {
      content: url('${svgToDataUri(icons.checkboxBundleRebranded)}');
      color: var(--checkbox-color);
      position: absolute;
      text-align: center;
      top: 5px;
      left: 3px;
      bottom: 4px;
      right: 3px;
      width: 14px;
      height: 12px;
      z-index: ${zIndex.facet.icon};
      font-size: 16px;
      @mixin mobileToTablet {
        transform: scale(1, 1);
      }
      @mixin tabletToDesktop {
        font-size: 18px;
      }
    }
    .title {
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      width: 100%;
      @mixin tabletToDesktop {
        &::before {
          background: #fff;
          content: '';
          height: 22px;
          margin-top: 1px;
          margin-right: 8px;
          padding: 10px;
          width: 22px;
          display: inline-flex;
        }
      }
      @mixin mobileToTablet {
        &::before {
          background: #fff;
          content: '';
          height: 22px;
          margin-top: 1px;
          margin-right: 6px;
          padding: 10px;
          width: 22px;
          display: inline-flex;
        }
        &.shipping {
          &::before {
            background: #fff;
            content: '';
            height: 22px;
            margin-top: 1px;
            width: 22px;
            display: inline-flex;
          }
        }
      }
      &.in-floor-calc {
        &::before {
          width: 15px;
          height: 15px;
          display: inline-flex;
          content: '';
          background: #fff;
          margin-top: 2px;
          margin-right: 8px;
          padding: 5px;
        }
        @mixin mobileToTablet {
          &::before {
            width: 16px;
            height: 15px;
            display: inline-flex;
            content: '';
            background: #fff;
            margin-top: 2px;
            margin-right: 8px;
            padding: 5px;
          }
        }
      }
    }
    .title.primary,
    .connect-title.primary {
      &::before {
        border: 1px solid ${colors.green.shade1};
      }
    }
    .hasTimeConstraint {
      align-items: center;
    }
    .shipping-label {
      display: flex;
      flex-direction: row-reverse;
    }
    .title.secondary {
      &::before {
        border: 1px solid ${colors.gray.shade5};
      }
    }
    .title.services {
      padding: 0;
      &::before {
        border: 1px solid ${colors.gray.shade54};
        margin-right: 8px;
        margin-top: 0;
      }
      .csicon-mkp-succes {
        top: 4px;
      }
    }
    .title.mkp-normal-border {
      &::before {
        border: 1px solid ${colors.gray.shade5};
        border-radius: 4px;
      }
    }
    .title.mkp-round-border {
      &::before {
        border: 1px solid ${colors.gray.shade5};
        border-radius: 50%;
      }
    }
    .title.tertiary {
      &::before {
        padding: 9.5px;
        margin-right: 0;
        border: 0.8px solid ${colors.gray.shade2};
      }
    }
    .connect-title.tertiary {
      &::before {
        padding: 6.5px;
        margin-right: 0;
        border: 0.8px solid ${colors.gray.shade2};
      }
    }
    .connect-title.secondary {
      &::before {
        border: 1px solid ${colors.gray.shade5};
      }
    }
    .count {
      font-weight: normal;
      margin-left: 5px;
    }
    .connect-title {
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      padding: 2px 0;
      &::before {
        background: #fff;
        content: '';
        height: 16px;
        margin-top: 0;
        margin-right: 0;
        width: 16px;
        display: inline-flex;
      }
      @mixin tabletToDesktop {
        &::before {
          background: #fff;
          content: '';
          height: 16px;
          margin-top: 0;
          margin-right: 0;
          width: 16px;
          display: inline-flex;
        }
      }
    }
    input[type='checkbox'] {
      opacity: 0;
      position: absolute;
    }
    &.shipping {
      @mixin mobileToTablet {
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  label.checkbox-rebranding {
    i.rebrand-icon {
      color: var(--checkbox-color);
      background: url('/a/fa/listing/static/styles/svg/check-box-tick.svg') no-repeat center;
      width: 15px;
      height: 15px;
      background-size: 15px;
      left: 2px;
      top: 4px;
      position: absolute;
    }
    .hide-icon {
      visibility: hidden;
    }
    .connect-title.tertiary {
      &::before {
        padding: 6.5px;
        margin-right: 0;
        border: 0.8px solid ${colors.gray.shade2};
      }
    }
    input[type='checkbox'] {
      opacity: 0;
      width: 20px;
      height: 20px;
      top: 2px;
      position: absolute;
    }
    .connect-title {
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      padding: 2px 0;
      &::before {
        background: #fff;
        content: '';
        height: 20px;
        margin-top: 0;
        margin-right: 0;
        width: 20px;
        display: inline-flex;
      }
    }
  }
  label.orange-border {
    .connect-title.tertiary {
      &::before {
        border: 1px solid ${colors.orange.shade4};
      }
    }
    .connect-title.secondary {
      &::before {
        border: 1px solid ${colors.orange.shade4};
      }
    }
  }

  input[type='checkbox']:checked + .title.secondary::before {
    border: 1px solid transparent;
  }
  input[type='checkbox']:checked + .title.primary::before {
    border: 1px solid transparent;
  }
  input[type='checkbox']:checked + .title.secondary.mkp-border::before {
    border: 1px solid var(--checkbox-color);
    background-color: var(--primary-color);
    border-radius: 4px;
  }
  input[type='checkbox']:checked + .title.secondary.mkp-round-selected-border::before {
    border: 1px solid var(--checkbox-color);
    border-radius: 50%;
  }
  input[type='checkbox']:checked + .title.services.mkp-border::before {
    border: 1px solid var(--checkbox-color);
    border-radius: 4px;
  }
  input[type='checkbox']:checked + .title.tertiary.mkp-border::before {
    border: 1px solid var(--checkbox-color);
    border-radius: 4px;
    padding: 9px;
    @mixin mobileToTablet {
      border: 1px solid ${colors.gray.shade2};
    }
  }
  input[type='checkbox'] + .title.tertiary.mkp-bundle-border::before {
    border: 1px solid var(--checkbox-color);
    border-radius: 4px;
    padding: 9px;
    @mixin mobileToTablet {
      border: 1px solid ${colors.gray.shade2};
    }
  }
  input[type='checkbox']:checked + .title.secondary.mkp-border.in-floor-calc::before {
    border: 1px solid var(--checkbox-color);
    border-radius: 4px;
  }

  .tottus {
    & > i {
      content: url('${svgToDataUri(icons.checkboxTickWhite)}') !important;
    }
  }
  input[type='checkbox']:checked + .tottus::before {
    border: none;
    background-color: ${colors.teal.shade1} !important;
  }
`;

export { checkboxStyles };
