import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

const TruncateText = ({ children, lines }) => {
  const elRef = useRef();

  useEffect(() => {
    const getLineHeight = (element) => {
      const lineHeight = window.getComputedStyle(element)['line-height'];

      if (lineHeight === 'normal') {
        return 1.16 * parseFloat(window.getComputedStyle(element)['font-size']);
      }

      return parseFloat(lineHeight);
    };

    const truncateElement = elRef.current.children[0];
    const truncateText = truncateElement.textContent;

    truncateElement.innerHTML = truncateText;
    const truncateTextParts = truncateText.split(' ');
    const lineHeight = getLineHeight(truncateElement);

    while (lines * lineHeight < truncateElement.clientHeight) {
      truncateTextParts.pop();
      truncateElement.innerHTML = `${truncateTextParts.join(' ')}...`;
    }
  }, [lines]);

  return (
    <span ref={elRef} style={{ overflow: 'hidden' }}>
      {children}
    </span>
  );
};

TruncateText.propTypes = {
  children: PropTypes.node.isRequired,
  lines: PropTypes.number,
};

TruncateText.defaultProps = {
  lines: 2,
};

export default TruncateText;
