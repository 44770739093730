/* eslint-disable consistent-return */
import CookieStorage from './CookieStorage';
import { isWindow } from './deviceChecker';

const setPromotionalProductToAdd =
  ({ cart, setCart }) =>
  ({ promotionalProductsToAdd }) => {
    setCart({
      ...cart,
      promotionalProductsToAdd,
    });
  };
const getLatAndLong = () => {
  let latLong = {};
  try {
    if (isWindow()) {
      const addressLinesCookie = CookieStorage.getCookie('addressLines').split('*');
      if (addressLinesCookie.length > 3) {
        const longitude = addressLinesCookie.pop();
        const latitude = addressLinesCookie.pop();
        latLong = {
          longitude,
          latitude,
        };
      }
    }
  } catch (err) {
    latLong = {};
  }
  return latLong;
};

export { getLatAndLong, setPromotionalProductToAdd };
