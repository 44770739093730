import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';

const CartAddedBundleStyles = css`
  .header {
    height: 60px;
    display: flex;
    align-items: center;

    .csicon-icon-success-circle {
      color: ${colors.green.shade1};
      font-size: 25px;
    }

    .label {
      flex-grow: 1;
      margin: 0 14px;
    }
    &.mkp {
      .csicon-icon-success-circle {
        color: ${colors.green.shade20};
      }
      span {
        font-size: 18px;
        color: ${colors.black.shade1};
      }
    }
  }

  .bundle-items {
    display: flex;
    padding-bottom: 13px;

    .bundle-item {
      margin-right: 33px;
      display: flex;
    }

    img {
      margin-right: 18px;
      height: 46px;
      width: 46px;
      border: 1px solid ${colors.gray.shade43};
      flex-shrink: 0;
    }

    .item-info {
      width: 96px;
      margin-right: 18px;
    }
  }
`;

export { CartAddedBundleStyles };
