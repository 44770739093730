import React from 'react';
import PropTypes from 'prop-types';
import ImageGroupStyles from './ImageGroup.style';
import ImageListing from '../ImageListing/ImageListing';

const ImageGroup = ({
  ids,
  mouseOver,
  width,
  height,
  lazyLoad,
  className,
  alt,
  hideLazyLoadPlaceHolder,
  hasMediaUrls,
  isFirstPod,
}) => {
  const mouseOverCls = mouseOver ? 'image--active' : '';
  const currentImageIndex = mouseOver && ids.length > 1 ? 1 : 0;
  const imageUrls = hasMediaUrls ? ids : ['', ''];
  return (
    <div>
      <section className={`${mouseOverCls} ${className}`}>
        <ImageListing
          id={ids[currentImageIndex]}
          width={width}
          height={height}
          alt={alt}
          lazyLoad={lazyLoad}
          imageUrl={imageUrls[currentImageIndex]}
          priorityFetch={!!isFirstPod}
        />
        {ids.length > 1 ? (
          <ImageListing
            id={ids[1]}
            width={width}
            height={height}
            alt={alt}
            lazyLoad
            hideLazyLoadPlaceHolder={hideLazyLoadPlaceHolder}
            imageUrl={imageUrls[1]}
          />
        ) : (
          ''
        )}
      </section>
      <style jsx>{ImageGroupStyles}</style>
    </div>
  );
};

ImageGroup.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  mouseOver: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  lazyLoad: PropTypes.bool,
  className: PropTypes.oneOf(['grid-view', 'list-view', 'layout_1_GRID', 'layout_2_GRID']),
  alt: PropTypes.string,
  hideLazyLoadPlaceHolder: PropTypes.bool,
  hasMediaUrls: PropTypes.bool,
  isFirstPod: PropTypes.bool.isRequired,
};

ImageGroup.defaultProps = {
  mouseOver: false,
  width: 249,
  height: 249,
  lazyLoad: true,
  className: 'grid-view',
  alt: 'preview',
  hideLazyLoadPlaceHolder: false,
  hasMediaUrls: false,
};

export default ImageGroup;
