import React from 'react';
import PropTypes from 'prop-types';
import { CountInput } from './CountInput/CountInput';
import { CountText } from './CountText/CountText';
import { Decrement } from './Decrement/Decrement';
import { Increment } from './Increment/Increment';
import { ProductCounterStyles } from './ProductCounter.style';
import { ProductCounterProvider } from './ProductCounterContext';

const ProductCounter = ({ children, count, className, style, type }) => {
  return (
    <ProductCounterProvider value={{ count }}>
      <div className={`product-count ${type} ${className}`} style={style}>
        {children}

        <style jsx>{ProductCounterStyles}</style>
      </div>
    </ProductCounterProvider>
  );
};

ProductCounter.defaultProps = {
  className: '',
  style: {},
  type: 'primary',
};

ProductCounter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  count: PropTypes.number.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.oneOf(['primary', 'secondary']),
};

ProductCounter.CountInput = CountInput;
ProductCounter.CountText = CountText;
ProductCounter.Decrement = Decrement;
ProductCounter.Increment = Increment;

export { ProductCounter };
