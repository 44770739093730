/* stylelint-disable no-descending-specificity */
import css from 'styled-jsx/css';
import { colors, zIndex, typography } from '../../../config/styles';

const dialogStyles = css`
  header.mkp-header {
    border-top: none;
    .h1 {
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.9375px;
      text-transform: uppercase;
      color: ${colors.white.shade1};
    }
    h1 {
      text-transform: none;
      font-size: 18px;
    }
  }
  div.dialog {
    display: block;
    z-index: 9999;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    outline: none;
    &.rightToLeft {
      transition: transform 0.3s ease;
      transform: translate3d(100vw, 0, 0);
    }
    &.rightToLeft-active {
      transform: translateZ(0);
    }
  }

  header {
    text-transform: uppercase;
    color: ${colors.gray.shade18};
    position: relative;
    background: ${colors.black.shade1};
    width: 100%;
    height: 67px;
    h1 {
      font-size: ${typography.lg};
      font-weight: 700;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 0;
      transform: translate(-50%, -50%);
      color: ${colors.white.shade1};
      letter-spacing: 0.94px;
      line-height: 18px;
      width: 240px;
      height: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      @mixin phabletToDesktop {
        width: 100%;
        height: auto;
        text-overflow: inherit;
        white-space: inherit;
      }
    }
    .actions {
      display: flex;
      padding: 10px;
      height: 67px;
      width: 100%;
      z-index: 1;
      align-items: center;
      position: absolute;
      .clear {
        margin-left: auto;
        font-size: 15px;
        color: ${colors.white.shade1};
        font-weight: lighter;
      }
      .back {
        font-size: 15px;
        color: ${colors.white.shade1};
        font-weight: lighter;
      }
      button {
        font-size: 9px;
        font-weight: 700;
        text-transform: uppercase;
        color: ${colors.gray.shade18};
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 40px;
        height: 40px;
        justify-content: center;
        &.clean {
          height: 35px;
        }
      }
    }
  }

  .body {
    overflow-y: auto;
    height: calc(100% - 100px);
    padding: 10px 0;
  }

  .footer {
    position: fixed;
    width: 100%;
    z-index: ${zIndex.dialog.footer};
    bottom: 1px;
  }

  .dialog-background {
    margin-left: 16%;
    background-color: ${colors.white.shade1};
  }

  .no-footer .body {
    height: calc(100% - 67px);
  }
`;

export { dialogStyles };
