/* eslint-disable react/no-danger */
import { isSodimacStandalone, isTottusStandalone } from '@digital-retail/store-manager';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { colors } from '../../config/styles';
import httpService from '../../utils/httpService';
import { isValidHttpUrl } from '../../utils/jsUtils';
import { sodimacSisToSoComUrl, tottusSisToSoComUrl } from '../../utils/urlHelper';
import CategoryBannerStyles from './CategoryBanner.default.style';

const CategoryBanner = ({ categoryBannerHtml, store }) => {
  const isValidURL = isValidHttpUrl(categoryBannerHtml);
  const [bannerContent, setBannerContent] = useState(isValidURL ? '<div></div>' : categoryBannerHtml);
  useEffect(() => {
    const fetchHtmlContent = async () => {
      const { data, error } = await httpService().get(categoryBannerHtml, {
        includeTracing: false,
        asText: true,
      });
      let htmlData = data;
      if (!error) {
        if (isSodimacStandalone(store)) {
          htmlData = sodimacSisToSoComUrl(htmlData);
        } else if (isTottusStandalone(store)) {
          htmlData = tottusSisToSoComUrl(htmlData);
        }
        setBannerContent(`${htmlData}`.concat(`<style>${CategoryBannerStyles}</style>`));
      }
    };
    if (isValidURL) fetchHtmlContent();
  }, [categoryBannerHtml]);
  return (
    <div className="header-static-content" id="testId-categoryBanner">
      {/* eslint-disable react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: `${bannerContent}` }} />
      <style jsx global>{`
        html body {
          position: relative;
          background: ${colors.white.shade1} !important;
        }
        .header-static-content {
          display: flex;
          flex-direction: column;
          min-height: 50px;
        }
      `}</style>
    </div>
  );
};

CategoryBanner.defaultProps = {
  categoryBannerHtml: '',
  store: null,
};

CategoryBanner.propTypes = {
  categoryBannerHtml: PropTypes.string,
  store: PropTypes.string,
};

export default CategoryBanner;
