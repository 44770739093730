import css from 'styled-jsx/css';

const styles = css`
  .facets-with-switch-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 18px;

    @mixin mobileToTablet {
      width: 100%;
      padding: 0 19px;
      margin-top: 2px;
      margin-bottom: 7px;
    }
  }
`;

export default styles;
