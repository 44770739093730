import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withLabels } from '../../../../utils/LabelsContext';
import noop from '../../../../utils/noop';
import Button from '../../../ui/Button/Button';
import VerMasFacetCheckbox from '../VerMasFacetCheckbox/VerMasFacetCheckbox';
import { getAppliedFacetParams } from '../VerMasFacetHelper';
import VerMasFacetContentStyle from './VerMasFacetContent.style';

const isAlphabet = (value) => /^[a-z]$/i.test(value.toLowerCase());

const VerMasFacetContent = ({
  facet,
  handleViewResults,
  setVisible,
  labels,
  loading,
  actions,
  setDigitalData,
  digitalData,
}) => {
  const [data, setData] = useState([]);
  const { values } = facet;

  const getAlphabeticalSections = () => {
    if (!values.length) return [];
    const facetValues = JSON.parse(JSON.stringify(values));
    return Object.values(
      facetValues.reduce((sectionsObj, value) => {
        const firstLetter = value.title[0].toLocaleUpperCase();
        const section = isAlphabet(firstLetter) ? firstLetter : '1';
        if (!sectionsObj[section]) {
          sectionsObj[section] = { title: section, values: [value] }; // eslint-disable-line no-param-reassign
        } else {
          sectionsObj[section].values.push(value);
        }
        return sectionsObj;
      }, {})
    ).sort((a, b) => (a.title > b.title ? 1 : -1));
  };

  useEffect(() => {
    setData(getAlphabeticalSections());
  }, [values]);

  const handleScrollToSection = (section) => {
    const sectionToScroll = document.getElementById(`scrollToSection${section}`);
    if (!sectionToScroll) return;
    sectionToScroll.scrollIntoView({ behavior: 'smooth' });
    setDigitalData(section, 1);
  };

  const renderScrollToSectionUi = () => {
    const ScrollToSectionValues = ['1', ...Array.from(Array(26)).map((e, i) => String.fromCharCode(i + 65))];
    return ScrollToSectionValues.map((sectionName) => {
      const disabledScrollTo = !data.map((section) => section.title).includes(sectionName);
      return (
        <div
          key={`scrollTo-section-${sectionName}`}
          id={`scrollTo-section-${sectionName}`}
          className={`scrollTo-section${disabledScrollTo ? ' disabled-ScrollTo' : ''}`}
          role="button"
          onClick={() => handleScrollToSection(sectionName)}
          onKeyDown={noop}
          disabled={disabledScrollTo}
        >
          {sectionName}
        </div>
      );
    });
  };

  const handleFacetChange = (selected, sectionId, optionId) => {
    const updatedData = [...data];
    updatedData[sectionId].values[optionId].selected = selected;
    setData(updatedData);
    setDigitalData(updatedData[sectionId].values[optionId].facetValue, 3);
  };

  const finalUrl = (appliedParams) => {
    return Object.keys(appliedParams)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(appliedParams[key])}`)
      .join('&');
  };

  const handleViewResultsAction = () => {
    const appliedParams = getAppliedFacetParams(values);
    const selectedValue = data
      .map((section) => section.values.filter((option) => option.selected).map((option) => option.facetValue))
      .flat(1)
      .join('::');
    if (selectedValue) {
      appliedParams[values[0].facetType] = selectedValue;
    } else {
      delete appliedParams[values[0].facetType];
    }
    handleViewResults(finalUrl(appliedParams));
    setDigitalData(labels.VIEW_RESULTS_FACET_MODAL, 4);
    digitalData();
  };

  const handleClearSelected = () => {
    const appliedParams = getAppliedFacetParams(values);
    if (appliedParams[values[0].facetType]) {
      delete appliedParams[values[0].facetType];
      handleViewResults(finalUrl(appliedParams));
    } else {
      setVisible(false);
    }

    setDigitalData(labels.CLEAR_FACET_VALUES_MODAL, 5);
    digitalData();
  };

  useEffect(() => {
    actions.setBusy(loading);
  }, [loading]);

  return (
    <>
      <div className="facet-title">{facet.name}</div>
      <div className="divider-line" />
      <div className="scrollTo-container">{renderScrollToSectionUi()}</div>
      <div className="facet-sections-container">
        {data.map((section, sectionId) => {
          return (
            <div
              className="facet-section"
              id={`scrollToSection${section.title}`}
              key={`facet-section-${section.title}`}
            >
              <div className="facet-section-title">{section.title}</div>
              <ul className="facet-section-list-container">
                {section.values.map((option, optionId) => {
                  return (
                    <li
                      className="facet-section-list-item"
                      key={`${option.title.replace(/\s/g, '-').toUpperCase()}-${section.title}`}
                    >
                      <VerMasFacetCheckbox
                        option={option}
                        onChange={({ isSelected }) => handleFacetChange(isSelected, sectionId, optionId)}
                        isModalView
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
      <div className="footer-container">
        <div className="clear-button-container">
          <Button type="secondary-link-mkp" isHighlighted onClick={handleClearSelected}>
            {labels.CLEAR_FACET_VALUES_MODAL}
          </Button>
        </div>
        <div className="viewResults-button-container">
          <Button type="mkp-secondary" size="small primary-theme" isHighlighted onClick={handleViewResultsAction}>
            {labels.VIEW_RESULTS_FACET_MODAL}
          </Button>
        </div>
      </div>
      <style jsx>{VerMasFacetContentStyle}</style>
    </>
  );
};

VerMasFacetContent.propTypes = {
  facet: PropTypes.object.isRequired,
  handleViewResults: PropTypes.func.isRequired,
  setVisible: PropTypes.func.isRequired,
  labels: PropTypes.object,
  actions: PropTypes.object,
  loading: PropTypes.bool,
  setDigitalData: PropTypes.func.isRequired,
  digitalData: PropTypes.func.isRequired,
};

VerMasFacetContent.defaultProps = {
  labels: {},
  actions: { setBusy: noop },
  loading: false,
};

export { VerMasFacetContent };
export default withLabels(VerMasFacetContent);
