import PropTypes from 'prop-types';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import noop from '../../../utils/noop';

const MatchMedia = ({ content, appCtx }) => {
  return content?.[appCtx?.deviceType]?.() || null;
};

MatchMedia.defaultProps = {
  content: noop,
};

MatchMedia.propTypes = {
  appCtx: PropTypes.object.isRequired,
  content: PropTypes.object,
};

export default withApplicationContext(MatchMedia);
export { MatchMedia };
