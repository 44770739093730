import PropTypes from 'prop-types';
import React from 'react';
import MediaComponent from '../Media/MediaComponent';
import RichTextEditorStyles from './RichTextEditor.style';
import SEOLeftContentStyles from './SEOLeftContent.style';

const SEOLeftContent = ({ html }) => {
  return (
    /* eslint-disable react/no-danger */
    <MediaComponent from="tablet" to="desktop">
      <style dangerouslySetInnerHTML={{ __html: RichTextEditorStyles }} />
      <style dangerouslySetInnerHTML={{ __html: SEOLeftContentStyles }} />
      <div dangerouslySetInnerHTML={{ __html: html }} className="rich-text-editor description-content-text" />
    </MediaComponent>
    /* eslint-enable react/no-danger */
  );
};

SEOLeftContent.propTypes = {
  html: PropTypes.string.isRequired,
};

export default SEOLeftContent;
