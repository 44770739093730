import PropTypes from 'prop-types';
import React from 'react';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import noop from '../../../utils/noop';
import BodyCopy from '../BodyCopy/BodyCopy';
import { tabStyles } from './Tab.style';

const Tab = (props) => {
  const { tabData, changeCallToAction, skuId, productType, isTabApplicable, handleCheckedState, checkedState } = props;

  // const [checkedState, setCheckedState] = useState('Prepago');

  const handleTabChange = (tab, index) => {
    handleCheckedState(tab);
    changeCallToAction(tab, index);
  };

  if (!isTabApplicable) {
    return null;
  }

  return (
    <div className="tab" onClick={(e) => e.stopPropagation()} onKeyDown={noop} role="presentation">
      {productType === 'CONNECT' &&
        tabData.map((tab, index) => {
          return (
            <div className="tab-container" key={`pod-tab-${skuId}-${tab.id}`}>
              <input
                type="radio"
                value={tab.id}
                id={`testId-pod-tab-${skuId}-${tab.id}`}
                className="tab-input"
                name={skuId}
                checked={tab.id === checkedState}
                onClick={() => {
                  handleTabChange(tab, index);
                }}
                onChange={noop}
              />
              <label className="tab-label-rebranding" htmlFor={`testId-pod-tab-${skuId}-${tab.id}`}>
                <BodyCopy size="title" isHighlighted type={checkedState === tab.id ? 'primary' : 'tertiary'}>
                  {tab.value}
                </BodyCopy>
              </label>
            </div>
          );
        })}
      <style jsx>{tabStyles}</style>
    </div>
  );
};

Tab.defaultProps = {
  tabData: [],
  productType: '',
  isTabApplicable: true,
  handleCheckedState: noop,
};

Tab.propTypes = {
  tabData: PropTypes.array,
  checkedState: PropTypes.string.isRequired,
  handleCheckedState: PropTypes.func,
  skuId: PropTypes.string.isRequired,
  changeCallToAction: PropTypes.func.isRequired,
  productType: PropTypes.string,
  isTabApplicable: PropTypes.bool,
};

export { Tab };
export default withApplicationContext(Tab);
